import { combineReducers } from "redux";

import InitDataReducer from "./InitDataReducer";
import AuthReducer from "./AuthReducer";
import PeopleReducer from "./PeopleReducer";
import PlanDetailsReducer from "./PlanDetailsReducer";
import PlanRatesReducer from "./PlanRatesReducer";
import ProviderSearchReducer from "./ProviderSearchReducer";
import ViewsBenfitsReducer from "./ViewsBenfitsReducer";
import OnExchangeReducer from "./OnExchangeReducer";
import MwsQhpReducer from "./MwsQhpReducer";
import MotReducer from "./MotReducer";
import CartReducer from "./CartReducer";
import AccReducer from "./AccReducer";
import AqtReducer from "./AqtReducer";
import PlansRecommendedReducer from "./PlansRecommendedReducer";
import PlansCompareReducer from "./PlansCompareReducer";
import SubsidyReducer from "./subsidy";
import UserDataReducer from "./UserDataReducer";

export default combineReducers({
  InitDataReducer,
  AuthReducer,
  PeopleReducer,
  PlanDetailsReducer,
  PlanRatesReducer,
  PlansCompareReducer,
  ProviderSearchReducer,
  ViewsBenfitsReducer,
  OnExchangeReducer,
  MwsQhpReducer,
  MotReducer,
  CartReducer,
  AccReducer,
  AqtReducer,
  SubsidyReducer,
  PlansRecommendedReducer,
  UserDataReducer
});
