import { APPLICANT, SPOUSE, DOMESTIC_PARTNER, DEPENDENT_CHILD } from "../constants/ApplicantDemographicConstants"


/**
 * This method is used to calculate "AGE" of consumers.
 * 
 * @param {string} dob_str
 * @returns {?number}
 */
export const calculateAge = (dob_str = '') => {

    if (dob_str.length === 0)
        return null;

    var today = new Date();
    var birthDate = new Date(dob_str);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }
    return age;
}

/**
 * This method is used to determine relationship constant for relationships in applicants array
 * 
 * @param {String} relationShip 
 * @returns {String}
 */
export const determineQuoteRelationShipConstants = (t,relationShip) => {
    switch (relationShip) {
        case APPLICANT:
            return t('getQuoteDemographic.applicantChoice');
        case SPOUSE:
            return t('getQuoteDemographic.relationshipSpouse');
        case DOMESTIC_PARTNER:
            return t('getQuoteDemographic.relationshipDomestic');
        case DEPENDENT_CHILD:
            return t('getQuoteDemographic.relationshipDependent');
        default:
            break;
    }
}