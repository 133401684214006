import * as Env from '../constants/Environment';
import { useAxiosHandler } from "hooks/useAxiosHandler";
import memberDataMock from '../../data/memberRecommenedData.json';
import { getDateStr, isEmpty } from 'utils/commonUtils';

const axios = useAxiosHandler();

export const SAVE_MEMBER_RECOMMENDED_PLAN_SET_DATA = 'SAVE_MEMBER_RECOMMENDED_PLAN_SET_DATA';

export const GET_MEMBER_RECOMMENDED_PLAN_DATA_INIT = 'GET_MEMBER_RECOMMENDED_PLAN_DATA_INIT';
export const GET_MEMBER_RECOMMENDED_PLAN_DATA_SUCCESS = 'GET_MEMBER_RECOMMENDED_PLAN_DATA_SUCCESS';
export const GET_MEMBER_RECOMMENDED_PLAN_DATA_FAILURE = 'GET_MEMBER_RECOMMENDED_PLAN_DATA_FAILURE';
export const SAVE_CURRENT_PLAN_NAME = 'SAVE_CURRENT_PLAN_NAME';

const getMemberRecommendedPlanDataFailure = (error) => {
  return {
      type: GET_MEMBER_RECOMMENDED_PLAN_DATA_FAILURE,
      error
  }
}

const getMemberRecommendedPlanDataSuccess = (data) => {
  return {
      type: GET_MEMBER_RECOMMENDED_PLAN_DATA_SUCCESS,
      data
  }
}

const saveCurrentPlanName = (data) => {
  return {
      type: SAVE_CURRENT_PLAN_NAME,
      data
  }
}

export const getMemberRecommendedPlanData = (planId, productPlanId, fipsCode, currentDate, productCode) => {
  return async dispatch => {
   const trimedProductPlanID = parseInt(productPlanId, 10)
    let params = {
   fipsCode : `12${fipsCode}`,
   productPlanId : trimedProductPlanID || '',
   planId: planId || ''
    }
    try {
      dispatch({ type: GET_MEMBER_RECOMMENDED_PLAN_DATA_INIT });

      let response = {};
      const serviceUrl = "/products/recommended-planId?";

      if (Env.ISPRODUCTION) {
        let apiResp = await axios.get(serviceUrl,{ params });
        response = apiResp?.data?.recommendedPlanId;
      } else {
        response = memberDataMock?.recommendedPlanId;
      }

      dispatch(getMemberRecommendedPlanDataSuccess(response));
      const currentPlanName = await getCurrentPlanName(currentDate, planId, productCode, fipsCode);
      dispatch(saveCurrentPlanName(currentPlanName));
    } catch (err) {
      console.log("Error in getRecommendedPlanMemberData:", err);
      dispatch(getMemberRecommendedPlanDataFailure(err));
    }
  }
}

const getCurrentPlanName = async (currentDate, planId, productCode, countyCode) => {
  let currentPlanName = "";
  let apiResp = {};
  let planIdSplit = planId.split("-");
  let csrLevel = planIdSplit[1];
  let isOnExchange  = (csrLevel !== '00') ? true : false;

  apiResp = await axios.get('/products/' + productCode + '/plans', {
    params: {
      countyCode,
      isOnExchange,  
      locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
      effectiveDate: getDateStr(currentDate, "mm/dd/yyyy"),
      csrLevel
    }
  });

  let plans = apiResp.data?.plans;
  let currentPlan = plans?.find((plan) => plan.planId == planId) || {};
  console.log("Current Plan:", currentPlan);  
  if (!isEmpty(currentPlan)) {
    let currPlanTitle = currentPlan?.planName;
    let currPlanTitleSplit = currPlanTitle?.split(" ");
    currentPlanName = currPlanTitleSplit[0] + ' ' + currPlanTitleSplit[1];
  }
  return currentPlanName;
}
