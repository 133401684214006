import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LandingLogoutAlert from './LandingLogoutAlert';
import LandingAlerts from './LandingAlerts';
import LandingHero from './LandingHero';
import LandingBody from './LandingBody';
import LandingHelp from './LandingHelp';
import {icmRecordEvent} from "../../utils/icmEventRecord";
import * as Actions from "../../actions/IcmRecordEventAction";
import * as InitDataAction from "actions/InitDataAction";


function Landing({marketPlaceReturn, logout}) {
    const dispatch = useDispatch();
    
    //Event Tracking Trigger
    const payload = icmRecordEvent('Lands on CWS Site');
    useEffect(()=>{
        dispatch(Actions.icmRecordEvent(payload));
        if(marketPlaceReturn){
            dispatch({type: InitDataAction.MARKET_PLACE_RETURN});
        }else{
            dispatch({type: InitDataAction.NON_MARKET_PLACE_RETURN});
        }
    },[]);
    
    return (
        <div>
            { logout && <LandingLogoutAlert /> }
            <LandingAlerts />
            <LandingHero />
            <LandingBody />
            <LandingHelp />
        </div>
    );
}

export default Landing;