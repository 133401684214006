import { useAxiosHandler } from "hooks/useAxiosHandler";
import { useSelector } from 'react-redux';
import * as Env from "../constants/Environment";
import mockAuth from '../../data/Auth.json';

const axios = useAxiosHandler();

export const GET_CWS_AUTH_DATA_INIT = 'GET_CWS_AUTH_DATA_INIT';
export const GET_CWS_AUTH_DATA_SUCCESS = "GET_CWS_AUTH_DATA_SUCCESS";
export const GET_CWS_AUTH_DATA_FAILURE = "GET_CWS_AUTH_DATA_FAILURE";

export const RESET_AUTH_DATA = "RESET_AUTH_DATA";
export const SAVE_MOT_DATA = "SAVE_MOT_DATA";
export const REDIRECT_TO_MEMBER_AUTH = "REDIRECT_TO_MEMBER_AUTH";
export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const RESET_MOT_FLAG = "RESET_MOT_FLAG";

export const saveMOTData = (data) => ({
    type: SAVE_MOT_DATA,
    payload: data
})

export const saveRedirectToMemberAuth = (data) => ({
    type: REDIRECT_TO_MEMBER_AUTH,
    payload: data
})

export const saveRedirectToLogin = (data) => ({
    type: REDIRECT_TO_LOGIN,
    payload: data
})

export const resetAuthData = () => ({
    type: RESET_AUTH_DATA
})

export const resetMOTFlag = () => ({
    type: RESET_MOT_FLAG
})

export const hasIsAuthenticated = (dataObj) => {
    return !(dataObj === null || dataObj === undefined) && typeof dataObj === "object" && dataObj.hasOwnProperty('isAuthenticated');
};

/**
 * Check user GWC-Authentication from ISSU65
 * @param {*} issAuthUrl 
 * @returns return object includes property "isAuthenticated" true or false
 */
export const checkUserAuthentication = (issAuthUrl) => {
    return (dispatch) => {
        try {

            dispatch({ type: GET_CWS_AUTH_DATA_INIT });

            if (Env.ISPRODUCTION) {
                
                axios
                    .get(issAuthUrl, { withCredentials: true })
                    .then((response) => {
                        // happy path
                        let data = hasIsAuthenticated(response.data) ? response.data : { "isAuthenticated": false };
                        // US673343, TODO: cleanup
                        data["isMember"] = data.isAuthenticated && (data.CONSUMER_ROLE || []).includes('MEMBER');
                        dispatch(getCwsUiAuthDataSuccess(data));
                    })
                    .catch((error) => {
                        console.log("Error occured while fetching isAuthenticated:", error);
                        if (error.message === 'Network Error') {
                            // probably anonymous, redirect/CORS failure
                            let data = { "isAuthenticated": false };
                            dispatch(getCwsUiAuthDataSuccess(data));
                        } else {
                            // probably server error, e.g. status 500
                            dispatch({ type: GET_CWS_AUTH_DATA_FAILURE });
                        } 
                    });
            
            } else {
                dispatch(getCwsUiAuthDataSuccess(mockAuth));
            }
        } catch (error) {
            console.log("checkUserAuthentication error:", error);
        }
    };
};

const getCwsUiAuthDataSuccess = (data) => {
    return {
        type: GET_CWS_AUTH_DATA_SUCCESS,
        data
    }
}

/**
 * fire and forget - urls.consumerApplyIssLogoutUrl - This will kill JSESSIONID and invalidate GWC-Authentication from ISSU65
 * @param {*} issLogoutUrl 
 * @returns 
 */
export const DELETElogoutUserFromIss = (issLogoutUrl) => {
    console.log("logoutUserFromIss ...");
    return async (dispatch) => {
        try {
            if (Env.ISPRODUCTION) {
                let response = {};
                response = await axios.get(issLogoutUrl, { withCredentials: true });
                console.log("response", response);
            } else {
            }
            dispatch(resetAuthData());
        } catch (error) {
            console.log("logoutUserFromIss error:", error);
            dispatch(resetAuthData());
        }
    };
};

/**
 * fire and forget - api/logout - create this endpoint in express to destroy the user session, this will kill the local SESSIONID
 * @returns 
 */
export const DELETElogoutUser = () => {
    console.log("logoutUser ...");
    try {
        if (Env.ISPRODUCTION) {
            axios.get('logout').then((res) => {
                window.location.href = `/`;
            });
        } else {
            console.log("logoutUser non prod ... ");
        }
    } catch (error) {
        console.log("logoutUser error:", error);
    }
    // };
};

export const getTokens = () => {
    console.log("getTokens ...");
    try {
        axios.get('getTokens');
    } catch (error) {
        console.log("getTokens error:", error);
    }
};

/**
 * keep alive the user authentication (getting called on a given interval)
 * @param {*} issAuthUrl 
 */
export const keepAlive = (issAuthUrl) => {
    console.log("@@@ keepAlive - user auth")
    checkUserAuthentication(issAuthUrl);
    getTokens();
};