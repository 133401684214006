import * as Constants from "constants/Constants";
import * as ActionConstants from "../actions/Subsidy";

function SubsidyReducer(state = {}, action) {
	switch (action.type) {

		case ActionConstants.FETCH_SUBSIDY_FLOW_SUCCESS:
			return {
				...state,
				[Constants.DATA]: action
			}

		default:
			return state;
	}

}

export default SubsidyReducer; 