/** PRODUCT CONSTANTS :-- Note : Must match the product name from salesconnect db  */
export const PRODUCT_QHP = "QHP";
export const PRODUCT_USABLE_LIFE_ACCIDENT = "Accident Policy";
export const PRODUCT_USABLE_LIFE_CRITICAL_ILLNESS = "Critical Illness";
export const PRODUCT_USABLE_LIFE_HOSPITAL = "Hospital Indemnity";
export const PRODUCT_QUALIFIED_DENTAL = "QDental";
export const PRODUCT_NON_QUALIFIED_DENTAL = "NQDental";
export const PRODUCT_DENTAL = "Dental";



export const COVERED_ACCIDENT = 'Covered Accident';
export const HOSPITAL_CONFINEMENT = 'Hospital Confinement';
export const EMERGENCY_TREATMENT = 'Emergency Treatment';
export const WELLNESS_BENEFITS = 'Wellness Benefit';

export const DAILY_HOSPITAL_CONFINEMENT = 'Daily Hospital Confinement';
export const ANNUAL_HOSPITAL_ADMISSION = 'Annual Hospital Admission';
export const HOSPITAL_INTENSIVE_CARE_CONFINEMENT = 'Hospital Intensive Care Confinement';
