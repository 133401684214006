import { createContext, useContext } from "react";

/**
 * Simple utility to generate a React Context and an access hook 
 * for the context that can be both exported from a context file.
 * 
 * @returns {Array}
 */
export const createReactContext = () => {
    const context = createContext();
    const useContextHook = () => useContext(context);

    return [ context, useContextHook ];
}