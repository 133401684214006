import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UIAlert, ALERTS } from "digital-shared-ui";

import * as AuthAction from "actions/AuthAction";
import { addGTMscript, changeLocationGTM } from "utils/analyticsUtils";
import * as InitDataAction from "actions/InitDataAction";
import * as UserDataAction from "actions/UserDataAction";
import { saveRawDemographicsData } from "actions/PeopleAction";
import * as Constants from "constants/Constants";
import { useAxiosHandler } from "hooks/useAxiosHandler";
import mockAuth from "data/Auth.json";
import * as Env from "constants/Environment";
import initMockData from '../../../data/initData.json';
import userDataMock from '../../../data/userDataMock.json';


function UIInitialization({ children }) {

    const dispatch = useDispatch();
    const axios = useAxiosHandler();
    const location = useLocation();
    
    const [initDataFetching, setInitDataFetching] = useState(false);
    const [initDataFetchError, setInitDataFetchError] = useState(false);
    const [authDataFetching, setAuthDataFetching] = useState(false);
    const [authDataFetchError, setAuthDataFetchError] = useState(false);
    const [userDataFetching, setUserDataFetching] = useState(false);
    const [userDataFetchError, setUserDataFetchError] = useState(false);
    const [outlet, setOutlet] = useState(false);

    const cwsUiInitData = useSelector((state) => state.InitDataReducer?.[Constants.CWS_UI_INIT_DATA]);
    const userData = useSelector((state) => state.UserDataReducer?.[Constants.USER_DATA]);
    const isAuthenticated = useSelector((state) => state.AuthReducer?.isAuthenticated);
    const userAccountNumber = useSelector((state) => state.AuthReducer?.USER_ACCT_NUMBER);
    const isAuthDataFetchCompleted = useSelector((state) => state.AuthReducer?.authDataFetchCompleted);
    const isUserDataFetchCompleted = useSelector((state) => state.UserDataReducer?.userDataFetchCompleted);

    useEffect(() => {
        AuthAction.getTokens();
    }, []);

    useEffect(() => {
        changeLocationGTM();
    }, [location]);

    useEffect(() => {
        dispatch({ type: InitDataAction.GET_CWS_INIT_DATA_INIT });
        setInitDataFetching(true);
        if (Env.ISPRODUCTION) {
            axios
                .get(Env.CWS_UI_INIT_DATA_ENDPOINT)
                .then((response) => {
                    dispatch({
                        type: InitDataAction.GET_CWS_INIT_DATA_SUCCESS,
                        data: response.data
                    });
                    setInitDataFetching(false);
                })
                .catch((error) => {
                    console.log("Error occured while fetching CWS-UI Init Data:", error);
                    dispatch({ type: InitDataAction.GET_CWS_INIT_DATA_FAILURE });
                    setInitDataFetching(false);
                    setInitDataFetchError(true);
                });
        } else {
            dispatch({
                type: InitDataAction.GET_CWS_INIT_DATA_SUCCESS,
                data: initMockData
            });
            setInitDataFetching(false);
        }
    }, []);

    useEffect(() => {
        if (cwsUiInitData && Object.keys(cwsUiInitData).length > 0 && 
            Object.getPrototypeOf(cwsUiInitData) === Object.prototype) {

            const {
                analytics: { gtmId, auth, preview } = {},
                urls: { consumerApplyIssAuthUrl } = {},
                authentication: { sso_warning_timer = 20 * 60 * 1000 } = {}
            } = cwsUiInitData;

            addGTMscript(gtmId, auth, preview);

            dispatch({ type: AuthAction.GET_CWS_AUTH_DATA_INIT });
            setAuthDataFetching(true);
            if (Env.ISPRODUCTION) {
                axios
                    .get(consumerApplyIssAuthUrl, { withCredentials: true })
                    .then((response) => {
                        // happy path
                        let data = AuthAction.hasIsAuthenticated(response.data) ? response.data : { "isAuthenticated": false };
                        // US673343, TODO: cleanup
                        data["isMember"] = data.isAuthenticated && (data.CONSUMER_ROLE || []).includes('MEMBER');
                        dispatch({ type: AuthAction.GET_CWS_AUTH_DATA_SUCCESS, data });
                        setAuthDataFetching(false);
                        setOutlet(true);
                    })
                    .catch((error) => {
                        console.log("Error occured while fetching isAuthenticated...");
                        // probably anonymous, redirect/CORS failure
                        let data = { "isAuthenticated": false };
                        // US673343, TODO: cleanup
                        dispatch({ type: AuthAction.GET_CWS_AUTH_DATA_SUCCESS, data });
                        setAuthDataFetching(false);
                        setOutlet(true);
                        // TODO: how to detect REAL server error?
                        // dispatch({ type: AuthAction.GET_CWS_AUTH_DATA_FAILURE });
                        // setAuthDataFetching(false);
                        // setAuthDataFetchError(true);
                    });
            } else {
                let data = mockAuth;
                // US673343, TODO: cleanup
                data["isMember"] = data.isAuthenticated && (data.CONSUMER_ROLE || []).includes('MEMBER');
                dispatch({
                    type: AuthAction.GET_CWS_AUTH_DATA_SUCCESS,
                    data
                });
                setAuthDataFetching(false);
                setOutlet(true);
            }

            const keepAliveTimer = Math.round((sso_warning_timer) / 2);
            const keepAliveInterval = setInterval(() => AuthAction.keepAlive(consumerApplyIssAuthUrl), keepAliveTimer);

            return () => clearInterval(keepAliveInterval);

        } else {
            console.log("WARNING: No config Data!!"); //should not happen
        }
    }, [cwsUiInitData]);

    useEffect(() => {
        if (cwsUiInitData && Object.keys(cwsUiInitData).length > 0 && 
            Object.getPrototypeOf(cwsUiInitData) === Object.prototype) {
            const {
                urls: { consumerApplyIssLoadUrl } = {}
            } = cwsUiInitData;
            dispatch({ type: UserDataAction.GET_USER_DATA_INIT });
            setUserDataFetching(true);
            if (Env.ISPRODUCTION) {
                if (isAuthDataFetchCompleted && isAuthenticated) {
                    const payload = {};
                    payload.userId = userAccountNumber;
                    payload.dataType = "Demographics";
                    payload.dataVersion = "1";
                    axios
                        .post(consumerApplyIssLoadUrl, payload, { withCredentials: true })
                        .then((response) => {
                            dispatch({
                                type: UserDataAction.GET_USER_DATA_SUCCESS,
                                data: response.data
                            });
                            setUserDataFetching(false);
                        })
                        .catch((error) => {
                            dispatch({ type: UserDataAction.GET_USER_DATA_FAILURE });
                            setUserDataFetching(false);
                            setUserDataFetchError(true);
                        });
                }
            } else {
                dispatch({
                    type: UserDataAction.GET_USER_DATA_SUCCESS,
                    data: userDataMock.data
                });
                setUserDataFetching(false);
            }
        }
    }, [cwsUiInitData, isAuthenticated, isAuthDataFetchCompleted]);

    useEffect(() => {
        try {
            if (Env.ISPRODUCTION) {
                if (isUserDataFetchCompleted) {
                    if (userData?.data?.dataType == 'Demographics') {
                        dispatch(saveRawDemographicsData(userData?.data.uiData));
                    }
                }
            }
        } catch (error) {
            // do nothing for now
        }
    }, [isUserDataFetchCompleted]);

    const isFetching = initDataFetching || authDataFetching;
    const isError = initDataFetchError || authDataFetchError;

    return (
        <>
            {isFetching &&
                <div className="row padding-3x">
                    <div className="columns large-12 medium-12 small-12">
                        <div className="cover" aria-live="polite">
                            <div className="loader"></div>
                        </div>
                    </div>
                    <div className="columns large-12 medium-12 small-12">
                        <h4 className="top-2x larger-3x text-center">Loading...</h4>
                    </div>
                </div>
            }
            {isError &&
                <div className="row">
                    <div className="columns large-12 medium-12 small-12 padding-3x">
                        <UIAlert
                            alertType={ALERTS.WARNING}
                            alertTagline="Warning: "
                            ariaLabel="service error alert"
                            icon={true}
                            className={"alert warning"}
                        >
                            {authDataFetchError && <span>Error with Authentication Service.</span>}
                            {initDataFetchError && <span>Error with Init Service.</span>}
                        </UIAlert>
                    </div>
                </div>
            }
            {outlet && children}
        </>
    )
}

export default UIInitialization;
