import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UIInputField, UICalendar, UICheckSelection, UITextareaField, UISelectField, LAYOUTS } from "digital-shared-ui";
import { UISelect, UIInputDate, UIInputText } from "ui-forms";
import ReCAPTCHA from "react-google-recaptcha";
import { Markup } from "interweave";
import { useAxiosHandler } from 'hooks/useAxiosHandler';
import { CALL_YOU_BACK_CONFIRM, CALL_YOU_BACK_ERROR, HELP } from "constants/Routes";
import { formatDate, formatPhoneNumber } from "../../utils/commonUtils";

function CallYouBack({ isPopup, setCallYouBackModal }) {
    const { t, i18n } = useTranslation('CallYouBack');
    const navigate = useNavigate();
    const axios = useAxiosHandler();

    const defaultFormData = {firstName: '',lastName: '', phone:'', contactDate: '', contactTime: '0', consentPhone: '', acceptPromo: '', email: '', address1: '', address2: '',city: '', zip: '', comment: '' };
    const [callYouBackInfo, setCallYouBackInfo] = useState(defaultFormData);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [contactDateError, setContactDateError] = useState("");
    const [contactTimeError, setContactTimeError] = useState("");
    const [zipError, setZipError] = useState("");
    const captchaRef = useRef(null);
    const [captchaError, setCaptchaError] = useState("");
    let keycode = useSelector((state) => state.PeopleReducer.keycode || "");
    const siteKey = useSelector((state) => state.InitDataReducer.initData.captcha?.siteKey || "");
    const callbackEnabled = useSelector((state) => state.InitDataReducer.initData.chat?.callbackEnabled === "true" ? true : false);

    useEffect(() => {
        if (!callbackEnabled) {
            navigate(HELP);
        }
    }, []);

    const handleInputChange = (key, value) => {
        setCallYouBackInfo({
            ...callYouBackInfo,
            [key]: value,
        });
        return value;
    }

    const handlePhoneInput = (key, value) => {
        const formattedPhoneNumber = formatPhoneNumber(value);
        setCallYouBackInfo({
            ...callYouBackInfo,
            [key]: formattedPhoneNumber,
        });
    }

    const submitCallYouBackForm = () => {        
        if (!isValidateCallYouBackData()) {
            let callDate = callYouBackInfo.contactDate;
            let callTime = callYouBackInfo.contactTime;
            let callDateTimeStart = "";
            let callDateTimeEnd = "";
            if (callTime == "1") {
                callDateTimeStart = callDate + "T08:00:00";
                callDateTimeEnd = callDate + "T11:00:00";
            } else if (callTime == "2") {
                callDateTimeStart = callDate + "T12:00:00";
                callDateTimeEnd = callDate + "T15:00:00";
            } else {
                callDateTimeStart = callDate + "T16:00:00";
                callDateTimeEnd = callDate + "T20:00:00";
            }
            
            let data = {
                "firstname": callYouBackInfo.firstName,
                "lastname": callYouBackInfo.lastName,
                "phone": callYouBackInfo.phone,
                "postalcode": callYouBackInfo.zip,
                "company": callYouBackInfo.firstName + " " + callYouBackInfo.lastName + " Household",
                "email": callYouBackInfo.email,
                "street": callYouBackInfo.address1,
                "Address_Line_2__c": callYouBackInfo.address2,
                "City": callYouBackInfo.city,
                "CallbackFromTime__c": callDateTimeStart,
                "CallbackToTime__c": callDateTimeEnd,
                "keycode__c": keycode,
                "Spanish_Indicator__c": (i18n.language === "es") ? "yes" : "no",
                "Lead_Type__c":"Lead",
                "status":"Open",
                "sub_channel__c":"BCBSFLU65",
                "channel_code__C":"TELESALES",
                "sub_sub_channel__c":"WEBTOLEAD",
                "leadsource":"WEB",
                "lead_sub_source__c":"CWS"
            };          
            
            axios.post(`services/sendCallYouBackLead`, data).then(res =>  {
                if (typeof res.data.callYouBackResponse.id !== 'undefined' && 
                           res.data.callYouBackResponse.id != '') {
                    navigate(CALL_YOU_BACK_CONFIRM);
                } else {
                    navigate(CALL_YOU_BACK_ERROR);
                }
                if (isPopup) {
                    setCallYouBackModal(false);
                }
            }).catch((error) => {
                navigate(CALL_YOU_BACK_ERROR);
                if (isPopup) {
                    setCallYouBackModal(false);
                }
            })
        }
    }

    const clearCallYouBackForm = () => {
        setCallYouBackInfo(defaultFormData);
    }

    const cancelCallYouBackForm = () => {
        setCallYouBackInfo(defaultFormData);
        setCallYouBackModal(false);
    }

    const isValidateCallYouBackData = () => {
        let errors = false;
        if (!callYouBackInfo.firstName) {
            setFirstNameError(`${t('labelFirstName')} ${t('errorRequired')}`);
            errors = true;
        } else {          
            setFirstNameError("");
        }

        if (!callYouBackInfo.lastName) {
            setLastNameError(`${t('labelLastName')} ${t('errorRequired')}`);
            errors = true;
        } else {           
            setLastNameError("");
        }

        if (!callYouBackInfo.phone) {
            setPhoneError(`${t('labelPhone')} ${t('errorRequired')}`);
            errors = true;
        } else {
            if (callYouBackInfo.phone.length != 12) {
                setPhoneError('Please enter a valid phone number with the format xxx-xxx-xxxx');
                errors = true;
            } else {
                setPhoneError("");
            }
        }

        if (!callYouBackInfo.contactTime || callYouBackInfo.contactTime == '0') {
            setContactTimeError(`${t('labelTime')} ${t('errorRequired')}`);
            errors = true;
        }  else {           
            setContactTimeError("");
        }

        if (!callYouBackInfo.contactDate) {
            setContactDateError(`${t('labelDate')} ${t('errorRequired')}`);
            errors = true;
        } else {
            let dateString = callYouBackInfo.contactDate;
            // First check for the pattern
            let regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

            if (!regex_date.test(dateString)) {
                setContactDateError('The date appears to be invalid.');
                errors = true;
            } else {
                let today = new Date();
                let todayDateStr = formatDate(today, 'YYYY-MM-DD');
                let todayInTime = new Date(todayDateStr).getTime();
                let callDateInTime = new Date(dateString).getTime();

                if (todayInTime == callDateInTime) {
                    setContactDateError("");
                    let currentHour = today.getHours();
                    let callEndTimes = {"1": "11", "2": "15", "3": "20"};
                    if (callYouBackInfo.contactTime && callYouBackInfo.contactTime != '0') {
                        if (parseInt(currentHour) > parseInt(callEndTimes[callYouBackInfo.contactTime])) {
                            setContactTimeError('The time selected is a past time.');
                            errors = true;
                        } else {
                            setContactTimeError("");
                        }
                    }
                } else if(callDateInTime < todayInTime) {
                    setContactDateError(`The date selected is a past date.`);
                    errors = true;
                } else {
                    setContactDateError("");
                }                
            }
        }        

        if (isPopup != "true" && !callYouBackInfo.zip) {
            setZipError(`${t('labelZip')} ${t('errorRequired')}`);
            errors = true;
        }  else {
            setZipError("");
            if (callYouBackInfo.zip && Number.isInteger(parseFloat(callYouBackInfo.zip)) === false) {
                setZipError('Please enter a numeric zip code.');
                errors = true;
            }            
        }

        // Captcha Validation
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if (!token) {
            setCaptchaError(t('captchaValidationError'));
            errors = true;
        } else {
            axios.post(`verifyCaptchaToken`, {token: token}).then(res =>  {
                if (res == 'success') {
                    setCaptchaError("");
                    errors = false;
                } else {
                    setCaptchaError(t('captchaValidationError'));
                    errors = true;
                }
            }).catch((error) => {
                console.log(error);
                setCaptchaError(t('captchaValidationError'));
                errors = true;
            })  
        }

        return errors;
    }

    const commentDesc = (
        <span className="large-inline optional">
            {t('txtSeeOur')} <a href={t('urlTermsOfUse')} target="_blank">{t('txtTermsOfUse')}</a>
            &nbsp; {t('txtAnd')} <a href={t('urlPrivacyPolicy')} target="_blank">{t('txtPrivacyPolicy')}</a> {t('txtMoreInfo')}.
        </span>
    );

    const cantactTimeOption = [
        { label: `- ${t('contactTime.select')} -`, value: "0" },
        { label: t('contactTime.morning'), value: "1" },
        { label: t('contactTime.afternoon'), value: "2" },
        { label: t('contactTime.evening'), value: "3" },
      ];

    const CallYouBackPopupForm = () => {        
        return (
            <>
                <style>
                    {
                        `
                        .callYouBackOverlay {
                        background-color: #27241D;    
                        z-index :100 !important;
                        width: 100%;
                        height: 100%;
                        position: fixed;
                        top: 0;
                        max-width: unset;
                        }
                        `
                    }
                </style>
                <section className="">
                    <div className="row head">
                        <div className="columns large-12 medium-12 small-12">
                            <h2>{t('popupTitle')}</h2>
                        </div>
                        <div className="columns large-12 medium-12 small-12">
                            <h3>{t('contactInfoHeading')}</h3>
                            <hr className='hr-border secondary' />
                            <div>
                                <p>{t('contactInfoPopupDesc')}</p>
                                <p>{t('contactInfoDescTwo')}</p>
                            </div>
                        </div>
                    </div>                   
                    <div className="row">
                        <div className="columns large-12 medium-12 small-12">
                        <div className="row">                                
                                <UIInputField
                                    name="firstName"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelFirstName')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.firstName}
                                    errorMessage={firstNameError} />
                            </div>
                            <div className="row">
                                <UIInputField
                                    name="lastName"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelLastName')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.lastName}
                                    errorMessage={lastNameError} />
                            </div>
                            <div className="row">
                                <UIInputField
                                    name="phone"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelPhone')}:`}
                                    onChangeOverride={true}
                                    onValidatedChange={handlePhoneInput}
                                    defaultValue={callYouBackInfo.phone}
                                    errorMessage={phoneError}                                    
                                    placeholder="xxx-xxx-xxxx"
                                    type="text"
                                    maxLength={"20"}
                                    minLength={"10"} />
                            </div>
                            <div className="row">
                                <UICheckSelection
                                    name="consentPhone"
                                    ariaLabel={t('labelConsent')}
                                    layout={LAYOUTS.COLUMN4}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.consentPhone}
                                    choices={[{ label: t('labelConsent'), value: "yes" }]}
                                    className="large-12 columns"
                                    inline
                                    />
                            </div>
                            <hr />
                            <div className="columns large-12 medium-12 small-12">
                                <h3>{t('timeHeading')}</h3>
                                <div>
                                    <p>{t('timeDesc')}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="columns large-4 medium-8 large-text-right small-12">
                                    <label className="inline-column">{`* ${t('labelDate')}:`}</label>
                                </div>
                                <div className="columns large-5 small-12 padding-right-0x padding-left-3x">
                                    <UIInputDate
                                        name="contactDate"
                                        label=""
                                        inputClassName="row"
                                        labelOutside={true}
                                        onValidatedChange={handleInputChange}
                                        value={callYouBackInfo.contactDate}
                                        errorMessage={contactDateError} />
                                </div>
                                <div className="columns large-3  medium-4 small-4"></div>
                            </div>
                            <div className="row">
                                <UISelectField
                                    name="contactTime"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelTime')}:`}
                                    onValidatedChange={handleInputChange}
                                    errorMessage={contactTimeError}
                                    defaultValue={callYouBackInfo.contactTime}
                                    choices={cantactTimeOption}
                                    required
                                  />
                            </div>
                            <div className="row">                                
                                <UITextareaField
                                    name="comment"
                                    label={`${t('labelComment')}:`}
                                    layout={LAYOUTS.COLUMN4}
                                    fieldDescription={commentDesc}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.comment}
                                    rows='5' />                                
                            </div>                            
                            <hr />
                            <div className="row">
                                <div className="columns large-4 medium-8 large-text-right small-12">
                                    <label className="inline-column">{`${t('labelRealPerson')}:`}</label>
                                </div>
                                <div className="columns large-5 small-12 padding-right-0x padding-left-3x">
                                    <ReCAPTCHA
                                        sitekey={siteKey}
                                        ref={captchaRef}
                                        hl={(localStorage['i18nextLng'] === "en") ? "en" : "es"}
                                    />
                                    {captchaError != "" &&
                                        <div className="error">
                                            <span role="alert" id="captcha-error" className="error">{captchaError}</span>
                                        </div>
                                    }    
                                </div>
                                <div className="columns large-3  medium-4 small-4"></div>
                            </div>
                                                        
                            <div className="row top-1x">
                                <div className="large-4 medium-4 small-12 columns">&nbsp;</div>
                                <div className="large-2 medium-2 small-12 columns">
                                    <button className="secondary small-expand" onClick={cancelCallYouBackForm}>{t('btnCancel')}</button>
                                </div>
                                <div className="large-4 medium-4 small-12 columns">                                    
                                    <button className="small-expand" id="btn-CallBackSubmit" onClick={submitCallYouBackForm}>{t('btnSubmitPopup')}</button>
                                </div>
                                <div className="large-2 medium-2 small-12 columns">&nbsp;</div>
                            </div>
                        </div>
                    </div>                        
                </section>           
            </>
        );
    }

    const CallYouBackPageForm = () => {
        
        return (
            <>
                <section className="">
                    <div className="row head">
                        <div className="columns large-12 medium-12 small-12">
                            <h2>{t('pageTitle')}</h2>
                        </div>
                        <div className="columns large-12 medium-12 small-12">
                            <h3>{t('contactInfoHeading')}</h3>
                            <hr className='hr-border secondary' />
                            <div>
                                <p>{t('contactInfoPageDesc')}</p>
                                <p>{t('contactInfoDescTwo')}</p>
                            </div>
                        </div>
                    </div>                   
                    <div className="row">
                        <div className="columns large-12 medium-12 small-12">
                            <div className="row">                                
                                <UIInputField
                                    name="firstName"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelFirstName')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.firstName}
                                    errorMessage={firstNameError} />
                            </div>
                            <div className="row">
                                <UIInputField
                                    name="lastName"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelLastName')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.lastName}
                                    errorMessage={lastNameError} />
                            </div>
                            <div className="row">
                                <UIInputField
                                    name="phone"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelPhone')}:`}
                                    onChangeOverride={true}
                                    onValidatedChange={handlePhoneInput}
                                    defaultValue={callYouBackInfo.phone}
                                    errorMessage={phoneError}
                                    placeholder="xxx-xxx-xxxx"
                                    type="text"
                                    maxLength={"20"}
                                    minLength={"10"} />
                            </div>
                            <hr />
                            <div className="columns large-12 medium-12 small-12">
                                <h3>{t('timeHeading')}</h3>
                                <div>
                                    <p>{t('timeDesc')}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="columns large-4 medium-8 large-text-right small-12">
                                    <label className="inline-column">{`* ${t('labelDate')}:`}</label>
                                </div>
                                <div className="columns large-5 small-12 padding-right-0x padding-left-3x">
                                    <UIInputDate
                                        name="contactDate"
                                        label=""
                                        inputClassName="row"
                                        labelOutside={true}
                                        onValidatedChange={handleInputChange}
                                        value={callYouBackInfo.contactDate}
                                        errorMessage={contactDateError} />
                                </div>
                                <div className="columns large-3  medium-4 small-4"></div>
                            </div>
                            <div className="row">
                                <UISelectField
                                    name="contactTime"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelTime')}:`}
                                    onValidatedChange={handleInputChange}
                                    errorMessage={contactTimeError}
                                    defaultValue={callYouBackInfo.contactTime}
                                    choices={cantactTimeOption}
                                    required
                                  />
                            </div>
                            <div className="row">
                                <UIInputField
                                    name="email"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`${t('labelEmail')}:`}
                                    onValidatedChange={handleInputChange}
                                    type="email"
                                    defaultValue={callYouBackInfo.email}
                                    fieldDescription={t('labelOptional')}
                                    />
                            </div>
                            <div className="row">
                                <UICheckSelection
                                    name="acceptPromo"
                                    ariaLabel={t('labelPromo')}
                                    layout={LAYOUTS.COLUMN4}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.acceptPromo}
                                    choices={[{ label: <Markup content={t('labelPromo')}/>, value: "yes" }]}
                                    className="large-12 columns"
                                    inline
                                    />
                            </div>
                            <div className="row">                                
                                <UIInputField
                                    name="address1"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`${t('labelAddress1')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.address1}
                                    fieldDescription={t('labelOptional')} />
                            </div>
                            <div className="row">                                
                                <UIInputField
                                    name="address2"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`${t('labelAddress2')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.address2}
                                    fieldDescription={t('labelOptional')} />
                            </div>
                            <div className="row">                                
                                <UIInputField
                                    name="city"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`${t('labelCity')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.city}
                                    fieldDescription={t('labelOptional')} />
                            </div>
                            <div className="row">                                
                                <UIInputField
                                    name="zip"
                                    layout={LAYOUTS.COLUMN4}
                                    label={`* ${t('labelZip')}:`}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.zip}
                                    errorMessage={zipError} />
                            </div>
                            <div className="row">                                
                                <UITextareaField
                                    name="comment"
                                    label={`${t('labelComment')}:`}
                                    layout={LAYOUTS.COLUMN4}
                                    fieldDescription={commentDesc}
                                    onValidatedChange={handleInputChange}
                                    defaultValue={callYouBackInfo.comment}
                                    rows='5' />                                
                            </div>
                            <hr />
                            <div className="row">
                                <div className="columns large-4 medium-8 large-text-right small-12">
                                    <label className="inline-column">{`${t('labelRealPerson')}:`}</label>
                                </div>
                                <div className="columns large-5 small-12 padding-right-0x padding-left-3x">
                                    <ReCAPTCHA
                                        sitekey={siteKey}
                                        ref={captchaRef}
                                        hl={(localStorage['i18nextLng'] === "en") ? "en" : "es"}
                                    />
                                    {captchaError != "" &&
                                        <div className="error">
                                            <span role="alert" id="captcha-error" className="error">{captchaError}</span>
                                        </div>
                                    }
                                </div>
                                <div className="columns large-3  medium-4 small-4"></div>
                            </div>                          
                                                        
                            <div className="row top-1x">
                                <div className="large-4 medium-4 small-12 columns">&nbsp;</div>
                                <div className="large-2 medium-2 small-12 columns">
                                    <button className="secondary small-expand" onClick={clearCallYouBackForm}>{t('btnCancel')}</button>
                                </div>
                                <div className="large-3 medium-3 small-12 columns large-text-right medium-text-right">                                    
                                    <button className="small-expand" id="btn-CallBackSubmit" onClick={submitCallYouBackForm}>{t('btnSubmitPage')}</button>
                                </div>
                                <div className="large-3 medium-3 small-12 columns">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div id="formnumber-section">
                    <div className="row">
                        <div className="columns small-12">
                            <p className="form-number">{t('formNumber')}</p>
                        </div>
                    </div>
                </div>                        
                </section>           
            </>
        );
    }

    return (
        <>
            {isPopup ?
                CallYouBackPopupForm()
            :
                CallYouBackPageForm()
            }           
        </>
    )
}

export default CallYouBack;