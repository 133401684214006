import * as Actions from 'actions/PlansCompareActions';
import * as Constants from "constants/Constants";

const initialState = {
    [Constants.PRODUCT_SEGMENT]: "",
    [Constants.PRODUCT_CODE]: "",
    [Constants.IS_ON_EXCHANGE]: false,
    [Constants.IS_SUBSIDY_FLOW]: false,
    [Constants.IS_MEG_APP]: false,
    [Constants.IS_NEXT_GRP_PRESENT]: false,
    [Constants.SELECTED_ENR_GRP_NUM]: "1",
    [Constants.PREBILL_DATA]: {},
    [Constants.SELECTED_PLANS_FOR_COMPARE]: []
}

const PlansCompareReducer = (state = initialState, action) => {

    switch (action.type) {
        case Actions.SAVE_PLANS_COMPARE_DATA:
            let data = action.data || [];
            return {
                ...state,
                ...data
            };

        case Actions.REMOVE_SELECTED_PLAN_FOR_COMPARE:
            let removePlanId = action.data || "";
            return {
                ...state,
                [Constants.SELECTED_PLANS_FOR_COMPARE]: state[Constants.SELECTED_PLANS_FOR_COMPARE].filter(p => p.planId !== removePlanId)
            }

        case Actions.RESET_PLANS_COMPARE_DATA:
            return initialState;

        default:
            return state;
    }
}

export default PlansCompareReducer;