import { SAVE_QHP_PLAN_DATA, SAVE_RIGHTSIZER_REDIRECT, SAVE_DENTAL_PLAN_DATA, SAVE_USABLE_PLAN_DATA, RESET_PLANS_RATES_REDUCER, SET_RELOAD_PLAN_DATA } from "../actions/PlanRatesAction"
import { isEmpty } from '../utils/commonUtils';

const initialState = {
    isPlanDataLoaded: false,
    QHPProduct: {},
    DentalProducts: [],
    USAbleProduct: {},
    initRightsizerFlow: "",
    reloadPlanData: false
}

const PlanRatesReducer = (state = initialState, action) => {

    switch (action.type) {
        case SAVE_QHP_PLAN_DATA:
            return {
                ...state,
                QHPProduct: action.payload,
                isPlanDataLoaded: true
            }
        case SAVE_DENTAL_PLAN_DATA:
            const stateDentalProductsArr = state.DentalProducts;
            const payload = action.payload;
            payload.forEach(element => {
                let productIdIndex = stateDentalProductsArr.findIndex(product => (product.productCode == element.productCode));
                if (!isEmpty(element.planYear)) {
                    productIdIndex = stateDentalProductsArr.findIndex(product => (product.productCode == element.productCode && product.planYear == element.planYear));
                }                
                if (productIdIndex == -1) {
                    state.DentalProducts.push(element);
                }
            });
            return {
                ...state,
                DentalProducts: [...state.DentalProducts],
                isPlanDataLoaded: true
            }
        case SAVE_USABLE_PLAN_DATA:
            return {
                ...state,
                USAbleProduct: action.payload,
                isPlanDataLoaded: true
            }
        case RESET_PLANS_RATES_REDUCER:
            return {
                ...state,
                isPlanDataLoaded: false,
                QHPProduct: {},
                DentalProducts: [],
                USAbleProduct: {}
            }
        
        case SAVE_RIGHTSIZER_REDIRECT:
            const rsFlow = action.payload;
            return {
                ...state,
                initRightsizerFlow: rsFlow
            }

        case SET_RELOAD_PLAN_DATA:
            return {
                ...state,
                reloadPlanData: action.payload
            }
        
        default:
            return state;
    }
}

export default PlanRatesReducer