import * as ActionConstants from "actions/ProviderSearchAction";

const initialState = {
    selectedHealthProviders: [],
    selectedDentalProviders: [],
    redirectToData: {}
};

const ProviderSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.ADD_PROVIDER:
            return {
                ...state,
                selectedHealthProviders: [...state.selectedHealthProviders, action.payload]
            }
        case ActionConstants.DELETE_PROVIDER:
            return {
                ...state,
                selectedHealthProviders: state.selectedHealthProviders.filter((item, index) => index !== action.payload)
            }
        case ActionConstants.ADD_DENTAL_PROVIDER:
            return {
                ...state,
                selectedDentalProviders: [...state.selectedDentalProviders, action.payload]
            }
        case ActionConstants.DELETE_DENTAL_PROVIDER:
            return {
                ...state,
                selectedDentalProviders: state.selectedDentalProviders.filter((item, index) => index !== action.payload)
            }
        case ActionConstants.SAVE_PREV_PAGE_DATA:
            let prevPageData = action.data || {};
            return {
                ...state,
                redirectToData: prevPageData
            }
        case ActionConstants.DELETE_PREV_PAGE_DATA:
            return {
                ...state,
                redirectToData: {}
            }
        case ActionConstants.RESET_PROVIDER_SEARCH_REDUCER:
            return initialState;
        case ActionConstants.SAVE_PROVIDER_SEARCH_REDUCER_DATA:
            return action.payload;          
        default:
            return state;
    }
}

export default ProviderSearchReducer;