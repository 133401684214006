import TagManager from "react-gtm-module";
import { getDateStr, consumerSegment } from "./commonUtils";

export const addGTMscript = (gtmId = "", auth = "", preview = "") => {
  const tagManagerArgs = {
    gtmId,
    auth,
    preview,
    dataLayer: {
      event: "dataLayer-loaded",
      langHit: "LANGHIT", // e.g. 'en', 'es'
      langSession: "LANGSESSION", // e.g. 'en', 'es'
      "usedChat(user-scope)": "",
      "usedChat(session-scope)": "",
      zipCode: "ZIPCODE",
      county: "COUNTY",
      age: "AGE",
      gender: "GENDER",
      userRole: "consumer", // e.g. 'agent', 'consumer'
    },
    dataLayerName: "dataLayer",
  };
  TagManager.initialize(tagManagerArgs);
};

// Trigger this event on location change
export const changeLocationGTM = () => {
  console.log("Fire changeLocationGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "virtual-page-view-router",
    },
  });
};

// Trigger this upon clicking the "View Detail" button from Plan List and Plan Compare
export const viewPlanGTM = (planObj, fromPrebill, isOnExchange, segment, productSegmentAgeCalucate, enrol_year, position) => {
  console.log("Fire viewPlanGTM...");
  const subCategory = planObj?.planCategory;
  const network = subCategory.split(" ").splice(-1)?.[0];
  const dateFormat = getDateStr(enrol_year, "mm/dd/yyyy");
  TagManager.dataLayer({
    dataLayer: {
      event: "productClick",
      ecommerce: {
        click: {
          actionField: { list: "CONSUMER" },
          products: [
            {
              id: planObj?.planId, // either "name" or "id" is required
              name: planObj?.planName,
              //brand: product.brand,  //could not find to reference to this Attribute
              category: planObj?.planCategory, // e.g. 'BlueSelect', 'Medicare Advantage'
              //variant: product.variant, //could not find to reference to this Attribute
              price: planObj?.totalPremium, // e.g. '99.99'
              position: position, // e.g. 1
              network: network, // e.g. 'HMO', 'PPO', 'EPO'
              exchangeType: isOnExchange ? "ON" : "OFF", //ON or OFF
              segment: consumerSegment(productSegmentAgeCalucate), //e.g. 'under65', 'over65'
              metalType: planObj?.metalLevel, // e.g. 'gold', 'silver'
              line: segment, // e.g. 'health', 'dental', 'life'
              type: planObj?.planCategory, // e.g. 'Blue Option', 'BlueSelect'
              enrollmentYear: dateFormat.slice(-4),
              saleType: fromPrebill ? "Renewal" : "New Sale" // e.g. 'new sale', 'renewal'
              //applyChannel: product.applyChannel,    // could not find to reference to this Attribute
            },
          ],
        },
      },
    },
  });
};

// Trigger this event once we land on Plan Details
export const planDetailGTM = (selectedPlanInfo, productLine, productSegmentAgeCalucate, isOnExchange, effectiveDate, isPrebill) => {
  console.log("Fire planDetailGTM...");
  const network = selectedPlanInfo?.planCategory.split(" ").splice(-1)?.[0];
  const dateFormat = getDateStr(effectiveDate, "mm/dd/yyyy");
  TagManager.dataLayer({
    dataLayer: {
      event: "productDetail",
      ecommerce: {
        detail: {
          actionField: {list: "CONSUMER"},
          products: [
            {
              id: selectedPlanInfo?.planId, // either "name" or "id" is required
              name: selectedPlanInfo?.planName,
              category: selectedPlanInfo?.planCategory, // e.g. 'BlueSelect', 'Medicare Advantage'
              // variant: productCategory.variant, // Could not find the reference for this attr 
              exchangeType: isOnExchange ? "ON" : "OFF",
              price: selectedPlanInfo?.totalPremium,
              network: network,
              segment: consumerSegment(productSegmentAgeCalucate), //e.g. 'under65', 'over65'
              metalType: selectedPlanInfo?.metalLevel, // e.g. 'gold', 'silver'
              line: productLine, // e.g. 'health', 'dental', 'life'
              type: selectedPlanInfo?.planCategory, // e.g. 'Blue Option', 'BlueSelect'
              enrollmentYear: dateFormat.slice(-4),
              saleType: isPrebill ? "New Sale" : "Renewal", // e.g. 'new sale', 'renewal'
              // applyChannel: productCategory.applyChannel // could not find the reference of this attr
            },
          ],
        },
      },
    },
  });
};

// Trigger this event on the Plan Compare page
export const planCompareGTM = (listOfProductIds, numberOfProducts) => {
  console.log("Fire planCompareGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "product-comparison",
      comparedProducts: listOfProductIds, // e.g. pipe delimited list of product Ids: 1001|100|999
      numSelected: numberOfProducts // e.g. if 2 products compared = 2
    },
  });
};

// Trigger this event on clicking the Cart button in Header or My Account
export const cartGTM = () => {
  console.log("Fire cartGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "view-cart",
    },
  });
};

// Trigger this event on clicking the Save Cart button
export const saveCartGTM = () => {
  console.log("Fire saveCartGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "save-cart",
    },
  });
};

// Trigger this event on clicking the Chat button
export const startChatGTM = () => {
  console.log("Fire startChatGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "chat-click",
    },
  });
};

// Trigger this event from Landing and Plan List upon clicking "HELP ME CHOOSE A PLAN" button
export const helpMeChoosePlanGTM = () => {
  console.log("Fire helpMeChoosePlanGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "help-me-choose",
      "page-url": window.location.pathname,
    },
  });
};

// Trigger this event from the Cart upon clicking "Change Plan" button
export const changePlanButtonGTM = (selectedPlanID) => {
  console.log("Fire changePlanButtonGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "changePlan",
      productId: selectedPlanID,
    },
  });
};

// Trigger this event when user selects/deselects the compare checkbox
export const planCompareCheckBoxGTM = (planId,value) => {
  console.log("Fire planCompareCheckBoxGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: "comparison-interaction",
      action: value.length === 1 ? 'select' : 'deselect',
      name: value[0],
      id: planId
    },
  });
};

// Trigger this event when the search button is clicked for Doctor and Hospital
export const providerSearchButtonGTM = (searchInfo) => {
  console.log("Fire providerSearchButtonGTM...");
  let providerType="";
  if(searchInfo?.providerType === "01_All")
    providerType = 'Primary Care Physician (PCP)'
  else if(searchInfo?.providerType === "02_All")
    providerType= 'Specialist'
  else if (searchInfo?.providerType === "02_64")
    providerType = 'Dentist'
  else if (searchInfo?.providerType === "08_All")
    providerType = 'Dental Specialist'
  else if(searchInfo?.facilityType ==="03_115")
    providerType = 'Hospital'
  else if(searchInfo?.facilityType ==="03_12")
    providerType = 'Surgical Center'
  else if(searchInfo?.facilityType ==="03_296")
    providerType = 'Urgent Care'
  else if(searchInfo?.facilityType ==="11_151")
    providerType = 'Walk-in Clinic'
  else if(searchInfo?.facilityType ==="05_All")
    providerType = 'X-Ray / Imaging Center'

  TagManager.dataLayer({
    dataLayer: {
      event: "online-provider-directory-search",
      providerType: providerType,
      doctorGender:searchInfo?.providerGender === "M" ? "Male" : searchInfo?.providerGender === "F" ? "Female" :'',
      doctorLanguage: searchInfo?.providerLanguage,
      // staffLanguage: obj.staffLanguage, //Not able to find reference to this attr in old code
      // acceptingNewPatients: obj.acceptingNewPatients, //Not able to find reference to this attr in old code
      // programs: obj.programs ,//Not able to find reference to this attr in old code
      // officeHours: obj.officeHours //Not able to find reference to this attr in old code
    },
  });
};

// This event can trigger as follows:
// 1. "add to cart" button click from the Plan List, Plan Compare and Plan Details page.
// 2. "remove plan" button click from the Cart page.
export const modifyCartGTM = (eventName,payloadGTM) => {
  let eventSchemaKey = "";
  if (eventName=='addToCart') {
    eventSchemaKey = "add";
  } else {
    eventSchemaKey = "remove";
  }
  console.log("Fire modifyCartGTM ("+eventSchemaKey+")...");
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ecommerce: {
        currencyCode: 'USD',
        [eventSchemaKey]: {
          products: [
            payloadGTM
          ]
        }
      },
    },
  });
};

// This event will trigger for "remove from cart" button on the Cart page.
export const removeFromCartGTM = (eventName,payloadGTM) => {
  console.log("Fire removeFromCartGTM...");
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [
            payloadGTM
          ]
        }
      },
    },
  });
};

//This event is triggered upon landing on plan list page with the products in the viewport as payload
export const GTMViewPlanList = (payload) => {
  console.log("Fire GTMViewPlanList....")
  TagManager.dataLayer({
    dataLayer: {
      event: "productImpression",
      ecommerce: {
        currencyCode: "USD",
        impressions: payload
      }
    },
  });
};

//This event will be triggered upon clicking the begin application button in Cart page
export const beginApplicationGTM = (payload) => {
  console.log("Fire beginApplicationGTM....")
  // This one might not be used anymore
  TagManager.dataLayer({
    dataLayer: {
      event: "checkoutStep",
      ecommerce: {
        checkout: {
          actionField: {
            step: '1',
          },
          products: payload
        }
      }
    },
  });
  // This one should be current
  TagManager.dataLayer({
    dataLayer: {
      event: "begin_checkout",
    },
  });
};