import React, { useState } from 'react'
import { UISessionTimer } from "digital-shared-ui";
import { useSelector } from 'react-redux';
import { getTokens } from '../../actions/AuthAction';

function SessionTimer() {
    let sso_warning_timer = useSelector((state) => parseInt(state.InitDataReducer?.initData?.authentication?.sso_warning_timer) || 20 * 60 * 1000); //20 mins
    let sso_logout_timer = useSelector((state) => parseInt(state.InitDataReducer?.initData?.authentication?.sso_logout_timer) || 5 * 60 * 1000); //5 mins
    let cws_gwc_sso_url = useSelector((state) => state.InitDataReducer.initData.authentication?.sso_url);
    let cws_gwc_sso_consumer_logout_endpoint = useSelector((state) => state.InitDataReducer.initData.authentication?.consumer_logout_endpoint);
    const [toLogout, setToLogout] = useState(false);

    const handleExit = () => {
        console.log("handleExit");
        setToLogout(true);
        const targetURL = `${cws_gwc_sso_url}${cws_gwc_sso_consumer_logout_endpoint}?TARGET=${window.location.origin}/cws/sso/logout`;
        window.location.href = targetURL;
    }

    const handleContinue = () => {
        console.log("handleContinue");
        getTokens();
    }

    const sessionResource = {
        title: {
            en: "Session Timeout",
            es: "Tiempo de espera de la sesión"
        },
        message: {
            en: "For security purposes, the current session will end in 5 minutes. If you would like to continue, click Continue below.",
            es: "Por motivos de seguridad, la sesión actual finalizará en 5 minutos. Si desea continuar, haga clic en Continuar a continuación."
        },
        logoutBtnTxt: {
            en: "Exit",
            es: "Salir"
        },
        continueBtnTxt: {
            en: "Continue",
            es: "Continuar"
        },
        remainingTxt: {
            en: "Time Remaining",
            es: "Tiempo restante"
        },    
    };

    return (
        <>
            {!toLogout &&
                <UISessionTimer
                    warning={sso_warning_timer}
                    logout={sso_logout_timer}
                    onLogout={handleExit}
                    onContinue={handleContinue}
                    translationResource={sessionResource}
                />
            }
        </>
    )
}

export default SessionTimer