import * as ActionConstants from "actions/UserDataAction";
import * as Constants from "constants/Constants";

const initialState = {
    userDataFetchCompleted: false,
    [Constants.USER_DATA]: {},
    [Constants.USER_DATA_FETCHING]: false,
    [Constants.USER_DATA_FETCH_ERROR]: false,      
}

const UserDataReducer = (state = initialState, action) => {

    switch (action.type) {

        case ActionConstants.GET_USER_DATA_INIT:
            return {
                ...state,
                [Constants.USER_DATA_FETCHING]: true,
            }

        case ActionConstants.GET_USER_DATA_SUCCESS:
            let userData = action.data;
            return {
                ...state,
                userDataFetchCompleted: true,
                [Constants.USER_DATA_FETCHING]: false,
                [Constants.USER_DATA_FETCH_ERROR]: false,
                [Constants.USER_DATA]: userData
            }

        case ActionConstants.GET_USER_DATA_FAILURE:
            return {
                ...state,
                [Constants.USER_DATA_FETCHING]: false,
                [Constants.USER_DATA]: {},
                [Constants.USER_DATA_FETCH_ERROR]: true,
            }

        default:
            return state;
    }
}

export default UserDataReducer;