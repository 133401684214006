import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { addProgressInfo, updateProgressInfo } from '../../actions/PeopleAction';
import { UIModal, UIAlert } from "digital-shared-ui";
import { UIInputText } from 'ui-forms';
import { Markup } from "interweave";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from "constants/Constants";
import { isValidEmail } from '../../utils/commonUtils';
import { closeSaveProgress } from 'actions/PeopleAction';
import { getProgressPayload } from "utils/progressUtils";
import { collectProgressInfo } from "actions/ProgressCollectionAction";
import { isAllowedSaveProgressPath } from "utils/routeUtils";

const SaveProgressPopup = ({saveProgressModal, setSaveProgressModal,isParent}) => {

    const {pathname} = useLocation()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('Layout');

    const defaultGeneralInfo = { phone: '', email: '' };
    const peopleReducer = useSelector((state) => state.PeopleReducer);
    const [saveProgressSuccess, setSaveProgressSuccess] = useState(false);
    const [saveProgressInfo, setSaveProgressInfo] = useState(defaultGeneralInfo);
    const [emailError, setEmailError] = useState("");
    const saveProgressBtnRef = useRef();
    const quoteData = useSelector((state) => state.PeopleReducer);

    const isAcc = useSelector((state) => state.AccReducer?.isAcc);
    const isAqt = useSelector((state) => state.AqtReducer?.isAqt);
    const isMOT = useSelector((state) => state.AuthReducer?.isMOT);
    const isPrebill = useSelector((state)=> state.PeopleReducer.isPrebill);
    const enrGroupsData = useSelector(state => state?.OnExchangeReducer[Constants.ENR_GRPS_DATA]);
    const leadEmail = useSelector((state) => state.PeopleReducer.email)
    const isMultiEnrollmentGroupApplication = enrGroupsData?.length > 0;

    useEffect(() => {
        setSaveProgressSuccess(false);
    }, [pathname]);

    useEffect(() => {
        const handleMouseOut = (event) => {
            if (event.toElement === null) {
                if (!peopleReducer?.isClosedSaveProgress && !isAcc && !isAqt && !isMultiEnrollmentGroupApplication && !isMOT && !isPrebill && isAllowedSaveProgressPath(pathname) && isParent) {
                    setSaveProgressModal(true);
                } else {
                    setSaveProgressModal(false);
                }
            }
        };
        if (!saveProgressModal) {
            document.body.addEventListener('mouseleave', function(event){handleMouseOut(event)});
            return () => {
                document.body.removeEventListener('mouseleave', function(event){handleMouseOut(event)});
            };
        }
    }, [pathname, saveProgressModal, peopleReducer.isClosedSaveProgress]);

    useEffect(()=>{
        setSaveProgressInfo({
            ...saveProgressInfo,
            email:leadEmail
        })
    },[saveProgressModal])

    const toggleSaveProgressDialog = () => {
        resetSaveProgressSuccess();
        dispatch(closeSaveProgress(true));
        if (saveProgressModal === true) {
            setSaveProgressModal(false);
        } else {
            setSaveProgressModal(true);
        }
    };

    const resetSaveProgressSuccess = () => {
        setSaveProgressSuccess(false);
    };

    const handleSaveProgressInfo = (key, value) => {
        console.log('handleSaveProgressInfo...', key, value);
        setSaveProgressInfo({
            ...saveProgressInfo,
            [key]: value,
        });
        return value;
    }

    const handleMouseEvent = (e) => {
        saveProgressBtnRef.current.focus();
    };

    const isValidateSaveProgressInfo = () => {
        let errors = false;
        if (!isValidEmail(saveProgressInfo.email)) {
            setEmailError(t('saveProgress.saveProgressEmailError'));
            errors = true;
        } else {
            setEmailError("");
        }

        return errors;
    }

    const submitSaveProgress = () => {
        console.log('submitSaveProgress...');
        if (!isValidateSaveProgressInfo()) {
            dispatch(addProgressInfo(saveProgressInfo));
            dispatch(updateProgressInfo({ progressPath: pathname }));

            // Collect Progress Info
            let peopleData = {
                ...quoteData,
                email: saveProgressInfo.email,
                progressPath: pathname,
                progressSave: true
            };
            let progressPayload = getProgressPayload(peopleData);
            dispatch(collectProgressInfo(progressPayload));

            setSaveProgressModal(false);
            setSaveProgressSuccess(true);
        }
    }

    return (
        <>
        <UIModal
            isOpen={saveProgressModal}
            onExit={toggleSaveProgressDialog}
            aria={{ labelledby: 'modal-title', describedby: "" }}
            ariaHideApp={false}
            className="modal-container small"
        >
            <button
                aria-label="close dialog"
                title="close dialog"
                className="close"
                onClick={toggleSaveProgressDialog}
            />
            <div className="row body">
                <div className="small-12 columns">
                    <section className="save-progress-pupup">
                        <div className="row head">
                            <div className="columns large-12 medium-12 small-12">
                                <h2>{t('saveProgress.saveProgressTitle')}</h2>
                            </div>
                            <div className="columns large-12 medium-12 small-12">
                                <p>{t('saveProgress.saveProgressTitleContent')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="columns large-12 medium-12 small-12">
                                <UIInputText name='email'
                                    label={t('saveProgress.saveProgressEmail')+'*'}
                                    onValidatedChange={handleSaveProgressInfo}
                                    errorMessage={emailError}
                                    labelOutside={true}
                                    value={saveProgressInfo.email}
                                    placeholder={t('saveProgress.saveProgressEmailAddress')}
                                    required={true} />
                            </div>
                            {/* <div className="columns large-12 medium-12 small-12">
                                <UIInputNumber name='phone'
                                    label={t('saveProgress.saveProgressPhone')}
                                    onValidatedChange={handleSaveProgressInfo}
                                    labelOutside={true}
                                    value={saveProgressInfo.phone}
                                    required={false} />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="large-12 medium-12 small-12 columns">
                                <p className="smaller-1x"><Markup content={t('saveProgress.requiredfield')} /></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="large-12 medium-12 small-12 columns">
                                <p className="smaller-2x"><Markup content={t('saveProgress.saveProgressDisclaimer')} /></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-6 medium-6 large-6 columns">
                                <button className="expand secondary" onClick={toggleSaveProgressDialog}>{t('saveProgress.saveProgressCancel')}</button>
                            </div>
                            <div className="small-6 medium-6 large-6 columns">
                                <button ref={saveProgressBtnRef} onMouseOver={handleMouseEvent} className="expand" onClick={submitSaveProgress}>{t('saveProgress.saveProgressSubmit')}</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </UIModal>
        {saveProgressSuccess &&
            <div className='toast-wrapper show'>
                <UIAlert icon={true}
                    className='notification toast success'
                    closeButton={{
                        "title": "close",
                        "ariaLabel": "close message",
                        "onClose": resetSaveProgressSuccess
                    }}>
                    <h2 className='hl-tiny'>{t('saveProgress.saveProgressSuccessTitle')}</h2>
                    <p>{t('saveProgress.saveProgressSuccessContent', { email: saveProgressInfo.email })}</p>
                </UIAlert>
            </div>
        }
        </>
    );
}


export default SaveProgressPopup