export const APPLICANT = "applicant";
export const SPOUSE = "spouse";
export const DOMESTIC_PARTNER = "domestic_partner";
export const DEPENDENT_CHILD = "dependent_child";

export const GET_RATES_CONSTANTS = [
    { relationship: ["applicant", "Applicant"], constant: "PRIMARY" },
    { relationship: ["spouse", "Spouse"], constant: "SPOUSE" },
    { relationship: ["domestic_partner", "Domestic Partner"], constant: "SPOUSE" },
    { relationship: ["dependent_child", "Dependent"], constant: "DEPENDENT" }
]

export const MEG_RELATIONSHIP_MAP = {
    "Applicant": "applicant",
    "Spouse": "spouse",
    "Dependent": "dependent_child",
    "Domestic Partner": "domestic_partner"
}