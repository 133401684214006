export const SAVE_PLANS_RECOMMENDED_DATA = "SAVE_PLAN_RECOMMENDED_DATA";
export const RESET_PLAN_RECOMMENDED_DATA = "RESET_PLAN_RECOMMENDED_DATA";
export const SAVE_PLAN_RECOM_RESPONSE = "SAVE_PLAN_RECOM_RESPONSE";
export const SAVE_MEG_REDIRECT = "SAVE_MEG_REDIRECT";

export const savePlanRecommendedData = (data) => ({
    type: SAVE_PLANS_RECOMMENDED_DATA,
    data
})

export const resetPlanRecommendedData = () => ({
    type: RESET_PLAN_RECOMMENDED_DATA
})

export const savePlanRecomResponse = (data) => ({
    type: SAVE_PLAN_RECOM_RESPONSE,
    data
})

export const saveMegRedirect = (data) => ({
    type: SAVE_MEG_REDIRECT,
    data
})