import * as ActionConstants from 'actions/AuthAction';
import * as Constants from "constants/Constants";

const initialState = {
    isAuthenticated: false,
    isMOT: false,
    isMember: false,
    displayedMOT: false,
    authDataFetchCompleted: false,
    [Constants.CWS_UI_AUTH_DATA_FETCHING]: false,
    [Constants.CWS_UI_AUTH_DATA_ERROR]: false,
    redirectToMemberAuth: false,
    redirectToLoginPage: false
}

const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        
        case ActionConstants.GET_CWS_AUTH_DATA_INIT:
            return {
                ...state,
                [Constants.CWS_UI_AUTH_DATA_FETCHING]: true,
            }
        
        case ActionConstants.GET_CWS_AUTH_DATA_SUCCESS:
            let authData = action.data;
            return {
                ...state,
                authDataFetchCompleted: true,
                [Constants.CWS_UI_AUTH_DATA_FETCHING]: false,
                [Constants.CWS_UI_AUTH_DATA_ERROR]: false,
                ...authData,
            };
        
        case ActionConstants.GET_CWS_AUTH_DATA_FAILURE:
            return {
                ...state,
                [Constants.CWS_UI_AUTH_DATA_FETCHING]: false,
                [Constants.CWS_UI_AUTH_DATA_ERROR]: true,
                isAuthenticated: false
            }

        case ActionConstants.SAVE_MOT_DATA:
            return {
                ...state,
                displayedMOT: action.payload
            }

        case ActionConstants.RESET_MOT_FLAG:
            return {
                ...state,
                isMOT: false
            }

        case ActionConstants.REDIRECT_TO_MEMBER_AUTH:
            return {
                ...state,
                redirectToMemberAuth: action.payload
            }
        
        case ActionConstants.REDIRECT_TO_LOGIN:
            return {
                ...state,
                redirectToLoginPage: action.payload
            }

        case ActionConstants.RESET_AUTH_DATA:
            return initialState;

        default:
            return state;
    }
}

export default AuthReducer