import React from "react";
import { v4 as guid } from "uuid";
import {
    PRODUCT_QHP,
    PRODUCT_QUALIFIED_DENTAL,
    PRODUCT_NON_QUALIFIED_DENTAL,
    PRODUCT_USABLE_LIFE_ACCIDENT,
    PRODUCT_USABLE_LIFE_CRITICAL_ILLNESS,
    PRODUCT_USABLE_LIFE_HOSPITAL,
    RATING_INDIVIDUAL_QUALIFIED_HEALTH,
    RATING_INDIVIDUAL_QUALIFIED_DENTAL,
    RATING_INDIVIDUAL_NONQUALIFIED_DENTAL,
    RATING_ANCILLARY_ACCIDENT,
    RATING_ANCILLARY_CRITICAL,
    RATING_ANCILLARY_HOSPITAL
} from '../../../constants/Constants';

import { UITooltip } from 'digital-shared-ui';
import {
    COVERED_ACCIDENT, HOSPITAL_CONFINEMENT, EMERGENCY_TREATMENT, WELLNESS_BENEFITS, DAILY_HOSPITAL_CONFINEMENT,
    ANNUAL_HOSPITAL_ADMISSION, HOSPITAL_INTENSIVE_CARE_CONFINEMENT
} from '../../../constants/Plans_Rates_Benfits_Constants';

import { GET_RATES_CONSTANTS } from '../../../constants/ApplicantDemographicConstants'
import { isEmpty, getDateStr } from '../../../utils/commonUtils';
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { calculateAge } from "utils/shoppingUtils";


export const premiumRange = (premiumMin, premiumMax) => [
    { label: premiumMin, value: premiumMin },
    { label: premiumMax, value: premiumMax }
];

export const deductibleRange = [
    { label: "$0", value: 100 },
    { label: "$8,700", value: 500 }
];

export const proposeOptions = [
    { label: "Show Agent Proposed Plans", value: "acc_proposed" }
]

export const metal = [
    { label: "Bronze", value: "Bronze" },
    { label: "Silver", value: "Silver" },
    { label: "Gold", value: "Gold" },
    { label: "Platinum", value: "Platinum" }
]

export const network = [
    { label: "BlueCare - HMO/POS", value: "BlueCare - HMO/POS" },
    { label: "BlueCare - POS", value: "BlueCare - POS" },
    { label: "BlueOptions - PPO/EPO", value: "BlueOptions - PPO/EPO" },
    { label: "BlueOptions - PPO/EPO", value: "BlueOptions - PPO" },
    { label: "BlueSelect - EPO/PPO", value: "BlueSelect - EPO/PPO" },
    { label: "BlueSelect - EPO", value: "BlueSelect - EPO" },
    { label: "myBlue - HMO", value: "myBlue - HMO" }
];

export const planOption = [
    { label: "Pediatric Vision", value: "Pediatric Vision" },
    { label: "Pediatric Vision and Pediatric Dental", value: "Pediatric Vision and Pediatric Dental" },
    { label: "Pediatric Vision, Adult Vision", value: "Pediatric Vision, Adult Vision" },
    { label: "Pediatric Vision, Adult Vision, Adult Dental", value:"Pediatric Vision, Adult Vision, Adult Dental"},
    { label: "Adult Dental", value: "Adult Dental"},
    { label: "Health Savings Account (HSA) Compatible", value: "Health Savings Account (HSA) Compatible" },
    { label: "Easy Pricing", value: "Easy Pricing"}
]

export const planSort = (t) => {
    return [
        { label: t(`selectOne`), value: " " },
        { label: t(`sortbyYourPlanMatches`), value: "swizard" },
        { label: t(`sortbyMostPopularPlans`), value: "most-popular" },
        { label: t(`sortbyMonthlyPremium`), value: "premium" },
        { label: t(`sortbyAnnualDeductibles`), value: "deductible" },
        { label: t(`sortbyOut-of-PocketMaximums`), value: "out_of_pocket_max" },
        { label: t(`sortbyProposedPlans`), value: "proposed-plan" },
    ];
}

/**
 * Sort the applicants by the relationship order - Applicant, Spouse/Domestic Partner then Child
 * 
 * @param {array} - The applicants array
 * @returns {array} sorted applicants array.
*/
export const sortApplicants = (applicants = []) => {    
    if (applicants.length > 1) {
        let finalApplicants = [];
        finalApplicants = applicants.toSorted((a, b) => {
            const relOrder = ['PRIMARY', 'SPOUSE', 'DEPENDENT'];
            return relOrder.indexOf(a?.relationship) - relOrder.indexOf(b?.relationship);
        });

        return finalApplicants;
    }

    return applicants;
}

const determineRatingKeyForProduct = (product) => {
    switch (product.productName) {
        case PRODUCT_QHP:
            return RATING_INDIVIDUAL_QUALIFIED_HEALTH
        case PRODUCT_QUALIFIED_DENTAL:
            return RATING_INDIVIDUAL_QUALIFIED_DENTAL
        case PRODUCT_NON_QUALIFIED_DENTAL:
            return RATING_INDIVIDUAL_NONQUALIFIED_DENTAL
        case PRODUCT_USABLE_LIFE_ACCIDENT:
            return RATING_ANCILLARY_ACCIDENT
        case PRODUCT_USABLE_LIFE_CRITICAL_ILLNESS:
            return RATING_ANCILLARY_CRITICAL
        case PRODUCT_USABLE_LIFE_HOSPITAL:
            return RATING_ANCILLARY_HOSPITAL
        default:
            return "";
    }
}

const getTobaccoIndicator = (tobaccoUse, dob, age, product) => {
    if (isEmpty(tobaccoUse) || isEmpty(dob)) {
        return false;
    }

    if (isEmpty(age)) {
        age = calculateAge(dob);
    }

    // QHP Rule
    if (product.productName === PRODUCT_QHP && age >= 18 && tobaccoUse === 'Y-6') {
        return true;
    }

    // Non QHP Rule
    if (product.productName !== PRODUCT_QHP && age >= 15 && (tobaccoUse === 'Y-6' || tobaccoUse === 'Y-7-12' || tobaccoUse === 'Y-13-36' || tobaccoUse === 'Y-37+')) {
        return true;
    }

    return false;
}

const populateApplicantsForRating = (zipCode, countyCode, countyName, cwsApplicants, units, product) => {
    let applicants = [];
    cwsApplicants.forEach(cwsApplicant => {
        let applicant = {};
        let relationship = GET_RATES_CONSTANTS.find(e => e.relationship.includes(cwsApplicant.relationship));
        let applicantTobacco = cwsApplicant.tobaccoUse;
        let applicantAge = cwsApplicant.age;
        let applicantDob = cwsApplicant.DOB;

        applicant.id = cwsApplicant.id;
        applicant.dateOfBirth = applicantDob;
        applicant.gender = cwsApplicant.gender;
        applicant.isTobaccoUser = getTobaccoIndicator(applicantTobacco, applicantDob, applicantAge, product);
        applicant.zipCode = zipCode;
        applicant.fipsCountyCode = countyCode;
        applicant.countyName = countyName;
        applicant.relationship = relationship?.constant;
        applicant.units = !isEmpty(cwsApplicant.units) ? cwsApplicant.units : units;
        applicants.push(applicant);
    })
    applicants = sortApplicants(applicants);
    return applicants;
}


export const buildRatesRequest = (zipCode, countyCode, countyName, applicants, product, plansInfo, units, effDate) => {
    let plansReqObj = [];
    plansInfo.forEach(plan => {
        let planObj = {};
        let keyArr = plan.planId.split('-');
        planObj = {
            basePlanID: keyArr[0],
            planVariantID: keyArr[1],
            riderList: null
        }
        plansReqObj.push(planObj);
    });
    let request = {
        header: {
            version: "1.0",
            clientApplicationName: "MTSIST",
            userId: "MTSISTUSER",
            requestId: guid(),
            tenantId: "GWCBCBSFL"
        },
        quoteInfo: {
            effectiveDate: getDateStr(effDate, "yyyy-mm-dd"),
            type: "NEW"
        },
        productInfo: {
            ratingKey: determineRatingKeyForProduct(product),
            planList: {
                plan: plansReqObj
            },
        },
        applicantList: {
            applicant: populateApplicantsForRating(zipCode, countyCode, countyName, applicants, units, product)
        }
    }
    return request;
}



export const ancillaryDentalColumns = (t) => {

    return [
        {
            label: "",
            name: "plan",
            key: "plan",
            width: "3%",
            columnClass: "text-left"
        },
        {
            label: t(`dentalHeader.select`),
            name: "select",
            key: "select",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t(`dentalHeader.planName`),
            name: "plan_name",
            key: "plan_name",
            width: "33%",
            columnClass: "text-center"
        },
        {
            label: t(`dentalHeader.descriptionAndSummary`),
            name: "summary_benefits",
            key: "summary_benefits",
            width: "28%",
            columnClass: "text-left"
        },
        {
            label: t(`dentalHeader.planFeatures`),
            name: "plan_features",
            key: "plan_features",
            width: "20%",
            columnClass: "text-left"
        },
        {
            label: t(`dentalHeader.monthlyPremium`),
            name: "monthly_premium",
            key: "monthly_premium",
            width: "14%",
            columnClass: "large-text-right medium-text-center price"
        }

    ]
}

export const accidentColumns = (t) => {
    return [
        {
            label: "",
            name: "plan",
            key: "plan",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t('accidentPlanList.select'),
            name: "select",
            key: "select",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t('accidentPlanList.planName'),
            name: "plan_name",
            key: "plan_name",
            width: "20%",
            columnClass: "text-center"
        },
        {
            label: t('accidentPlanList.accidentHealth'),
            name: COVERED_ACCIDENT,
            key: COVERED_ACCIDENT,
            width: "25%",
            columnClass: "text-center"
        },
        {
            label: t('accidentPlanList.hospitalConfinement'),
            name: HOSPITAL_CONFINEMENT,
            key: HOSPITAL_CONFINEMENT,
            width: "7%",
            columnClass: "text-left"
        },
        {
            label: t('accidentPlanList.lifeAccident'),
            name: EMERGENCY_TREATMENT,
            key: EMERGENCY_TREATMENT,
            width: "22%",
            columnClass: "text-left"
        },
        {
            label: t('accidentPlanList.wellnessBenefit'),
            name: WELLNESS_BENEFITS,
            key: WELLNESS_BENEFITS,
            width: "9%",
            columnClass: "text-center"
        },
        {
            label: t('accidentPlanList.monthlyPremium'),
            name: "monthly_premium",
            key: "monthly_premium",
            width: "18%",
            columnClass: "large-text-right medium-text-center price"
        }
    ]
}

export const tobaccoPolicyAmount = [
    { label: "$10,000", value: "2" },
    { label: "$15,000", value: "3" },
    { label: "$20,000", value: "4" },
    { label: "$25,000", value: "5" },
    { label: "$30,000", value: "6" },
    { label: "$35,000", value: "7" },
    { label: "$40,000", value: "8" },
    { label: "$45,000", value: "9" },
    { label: "$50,000", value: "10" }
];
export const tobaccoPolicyAmountSPDP = [
    { label: "$10,000", value: "2" },
    { label: "$15,000", value: "3" }
];

export const tobaccoPolicyAmountDep = [
    { label: "$10,000", value: "2" }
];

export const tobaccoChoice = [
    { label: "Select One", value: "none" },
    { label: "Never", value: "N" },
    { label: "Within the last 6 months", value: "Y-6" },
    { label: "Between 7 and 12 months ago", value: "Y-7-12" },
    { label: "Between 13 and 36 months ago", value: "Y-13-36" },
    { label: "More than 36 months ago", value: "Y-37+" }
];

export const tobaccoChoiceWithTranslation =(t)=> {
return  [
    { label: t('getQuoteDemographic.tobaccoSelect'), value: " " },
    { label: t('getQuoteDemographic.tobaccoNever'), value: "N" },
    { label: t('getQuoteDemographic.tobaccoLast6Months'), value: "Y-6" },
    { label: t("getQuoteDemographic.tobacco7And12Months"), value: "Y-7-12" },
    { label: t('getQuoteDemographic.tobacco13And36Months'), value: "Y-13-36" },
    { label: t('getQuoteDemographic.tobacco36AndMore'), value: "Y-37+" }
]
}

export const criticalIllnessStep1Columns =(t)=>{
    return [
    {
        label: "",
        name: "plan",
        key: "plan",
        width: "2%",
        columnClass: "text-left"
    },
    {
        label: t("ciPlanList.relationship"),
        name: "relationship",
        key: "relationship",
        width: "33%",
        columnClass: "text-center"
    },
    {
        label: <UITooltip
            content={<div>
                <Markup content={t('ciPlanList.tobaccoToolTip')}/>
            </div>}
            position="TOP"
        >
            <label className='columns small-12 medium-12'><b>{t("ciPlanList.tobaccoUse")}</b> <span className="padding-right-1x" />
                <span
                    className="icon icon-question-circle"
                    aria-hidden="true"
                ></span></label>
        </UITooltip >,
        name: "tobacco_use",
        key: "tobacco_use",
        width: "33%",
        columnClass: "text-center"
    },
    {
        label: t("ciPlanList.selectPolicyAmount"),
        name: "select_policy_amount",
        key: "select_policy_amount",
        width: "33%",
        columnClass: "large-text-right medium-text-center price"
    }
]
}

export const criticalIllnessStep2Columns = (t) => {
    return [
        {
            label: "",
            name: "plan",
            key: "plan",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t("ciPlanList.select"),
            name: "select",
            key: "select",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t("ciPlanList.planName"),
            name: "plan_name",
            key: "plan_name",
            width: "80%",
            columnClass: "text-left"
        },
        {
            label: t("ciPlanList.monthlyPremium"),
            name: "monthly_premium",
            key: "monthly_premium",
            width: "18%",
            columnClass: "large-text-right medium-text-center price"
        }
    ]
}

export const hospitalColumns = (t) => {
    return [
        {
            label: "",
            name: "plan",
            key: "plan",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t('hospitalPlanList.select'),
            name: "select",
            key: "select",
            width: "2%",
            columnClass: "text-left"
        },
        {
            label: t('hospitalPlanList.planName'),
            name: "plan_name",
            key: "plan_name",
            width: "20%",
            columnClass: "text-center"
        },
        {
            label: t('hospitalPlanList.hospitalConfinement'),
            name: DAILY_HOSPITAL_CONFINEMENT,
            key: DAILY_HOSPITAL_CONFINEMENT,
            width: "20%",
            columnClass: "text-center"
        },
        {
            label: t('hospitalPlanList.annualHospitalAdmission'),
            name: ANNUAL_HOSPITAL_ADMISSION,
            key: ANNUAL_HOSPITAL_ADMISSION,
            width: "20%",
            columnClass: "text-left"
        },
        {
            label: t('hospitalPlanList.intensiveCareConfinement'),
            name: HOSPITAL_INTENSIVE_CARE_CONFINEMENT,
            key: HOSPITAL_INTENSIVE_CARE_CONFINEMENT,
            width: "20%",
            columnClass: "text-left"
        },
        {
            label: t('hospitalPlanList.monthlyPremium'),
            name: "monthly_premium",
            key: "monthly_premium",
            width: "18%",
            columnClass: "large-text-right medium-text-center price"
        }
    ]
}

export const benefitTabDetails = (key, t) => {

    const tabDetailsMapping = {
        'CWS Non Qualified Dental Plans Main Page': t(`planDetails.overView`),
        'CWS Qualified Dental Plans Detail Page Plan Overview': t(`planDetails.overView`),
        'CWS Qualified Dental Plans Detail Page': t(`planDetails.Benefits`),
        'Pediatric Benefits': t(`planDetails.pediatricBenefits`),
        'Adult Benefits': t(`planDetails.adultBenefits`),
        'Overview': t(`planDetails.overView`),
        'Office Services & Pharmacy': t(`planDetails.officeServices`),
        'Hospital / Other Benefits': t(`planDetails.hospitalOther`),
        'Annual Deductible': t(`planDetails.annualDeductibleTab`),
        'Annual Maximum Out of Pocket': t(`planDetails.outOfPocketTab`),
        'Primary Care Visit to Treat an Injury or Illness': t(`planDetails.primayCareTab`)
    }

    return tabDetailsMapping[key] ?? key;
}

//Do the translation benefit tab names in English and Spanish
export const translateBenefitTabNames = async (e,t) => {
    e.benefits.forEach(benefit => {
        if (benefit.benefitName !== undefined) {
            benefit.benefitName = benefitTabDetails(benefit.benefitName, t);
        }
    })
}

export const renderUsableSummaryLink = (planRatesData) => {
    const { t } = useTranslation('Plans');

    let summaryLink = "";
    let productName = "";
    productName = planRatesData.USAbleProduct.productName;
    switch (productName) {
        case PRODUCT_USABLE_LIFE_ACCIDENT:
            summaryLink = t('accidentPlanList.summaryLink');
            break;
        case PRODUCT_USABLE_LIFE_CRITICAL_ILLNESS:
            summaryLink = t('ciPlanList.summaryLink');
            break;
        case PRODUCT_USABLE_LIFE_HOSPITAL:
            summaryLink = t('hospitalPlanList.summaryLink');
            break;
        default:
            break;
    }
    return (
        <p>
            <a href={summaryLink} target="_blank">
                {t(`planDetails.summaryBenefits`)}
            </a>
        </p>
    );
}

export const renderEhbMessage = (t) => {
   
    return (
        <div className="row collapse ehb-panel" style={{ backgroundColor: '#FFFDF2', padding: '.25rem 1rem' }}>
            <div className="columns large-12 medium-12">
                <div className="row collapse">
                    <span
                        className="icon icon-exclamation-triangle caution"
                        aria-hidden="true"
                    ></span><span>{t('ehbMessage')}</span>
                </div>
            </div>
        </div>
    );
}

