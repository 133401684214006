import * as Actions from '../actions/OnExchangeAction';
import * as Constants from "constants/Constants";

const initialState = {
    [Constants.ENR_GRPS_DATA]: [],
    [Constants.ENR_GRPS_SELECTED_PLAN_DATA]: {},
    [Constants.TOTAL_SUBSIDY_SELECTED]: 0,
    [Constants.EXCHANGE_APPLICATION]: {},
    [Constants.KEEP_SHOPPING_OTHER_PRODUCT]: false,
    enrollmentResponse: {},
    enrollmentType: "",
    isElgibilityDataLoaded: false,
    isEetchElgibilityError: false,
    primaryApplicantValidation: {},
    enrollmentGroupValidation: {},
    selectedApplyPlan: {},
    nonHealthApplicant: null,
    onExDisplayStarContent:false,
    onExStarPlanId:''
}

const OnExchangeReducer = (state = initialState, action) => {

    switch (action.type) {
        case Actions.SAVE_ELIGIBILITY_DATA:
            return {
                ...state,
                ...action.payload,
                isElgibilityDataLoaded: true,
                isEetchElgibilityError: false
            }

        case Actions.SET_FETCH_ELIGIBILITY_ERROR:
            return {
                ...state,
                isElgibilityDataLoaded: false,
                isEetchElgibilityError: true
            }

        case Actions.SAVE_STUB_ELIGIBILITY_DATA:
            return {
                ...state,
                ...action.payload,
                isElgibilityDataLoaded: true
            }

        case Actions.SAVE_APPLICANT_GRP_NUMS:
            return {
                ...state,
                [Constants.APPLICANT_GRP_NUMS]: action.data
            }

        case Actions.SAVE_PRIMARY_APPLCANT_SELECTION:
            let primarySelection = action.data || {};
            return {
                ...state,
                [Constants.PRIMARY_APPLICANT_SELECTION_DATA]: primarySelection
            }

        case Actions.SAVE_TOBACCO_SELECTION:
            let tobaccoSelection = action.data || {};
            return {
                ...state,
                [Constants.TOBACCO_SELECTION_DATA]: tobaccoSelection,
            }
        case Actions.SAVE_SHOULD_DISPLAY_STAR_ICON:
            return {
                ...state,
                onExDisplayStarContent: action.payload
            }
        case Actions.SAVE_ONEX_STAR_PLAN_ID:
            return {
                ...state,
                onExStarPlanId: action.payload
            }
        case Actions.SAVE_ENR_GRPS_DATA:
            return {
                ...state,
                [Constants.ENR_GRPS_DATA]: action.data
            }

        case Actions.VALIDATE_GRP_SELECTION_INIT:
            return {
                ...state,
                enrollmentGroupValidation: {
                    [Constants.FETCHING]: true,
                }
            }

        case Actions.VALIDATE_GRP_SELECTION_SUCCESS:
            const megValidationData = action?.data || {};
            return {
                ...state,
                enrollmentGroupValidation: {
                    [Constants.FETCHING]: false,
                    [Constants.DATA]: megValidationData,
                    [Constants.ERROR]: false
                }
            }

        case Actions.VALIDATE_GRP_SELECTION_FAILURE:
            return {
                ...state,
                enrollmentGroupValidation: {
                    [Constants.FETCHING]: false,
                    [Constants.ERROR]: true
                }
            }

        case Actions.RESET_GRP_SELECTION_VALIDATION:
            return {
                ...state,
                enrollmentGroupValidation: {}
            }

        case Actions.VALIDATE_PRIMARY_APPLICANT_SELECTION_INIT:
            return {
                ...state,
                primaryApplicantValidation: {
                    [Constants.FETCHING]: true,
                }
            }

        case Actions.VALIDATE_PRIMARY_APPLICANT_SELECTION_SUCCESS:
            const primaryApplicantValidationData = action?.data || {};
            return {
                ...state,
                primaryApplicantValidation: {
                    [Constants.FETCHING]: false,
                    [Constants.DATA]: primaryApplicantValidationData,
                    [Constants.ERROR]: false
                }
            }

        case Actions.VALIDATE_PRIMARY_APPLICANT_SELECTION_FAILURE:
            return {
                ...state,
                primaryApplicantValidation: {
                    [Constants.FETCHING]: false,
                    [Constants.ERROR]: true
                }
            }

        case Actions.RESET_PRIMARY_APPLICANT_SELECTION_VALIDATION:
            return {
                ...state,
                primaryApplicantValidation: {}
            }

        case Actions.SAVE_ENR_GRP_SELECTED_PLAN:

            const currentData = state?.[Constants.ENR_GRPS_SELECTED_PLAN_DATA] || {};
            let updatedData = { ...currentData, ...action.data };

            return {
                ...state,
                [Constants.ENR_GRPS_SELECTED_PLAN_DATA]: updatedData
            }

        case Actions.SAVE_TOTAL_SUBSIDY_SELECTED:
            return {
                ...state,
                [Constants.TOTAL_SUBSIDY_SELECTED]: action.data
            }

        case Actions.SAVE_KEEP_SHOPPING_OTHER_PRODUCT:
            return {
                ...state,
                [Constants.KEEP_SHOPPING_OTHER_PRODUCT]: action.data
            }

        case Actions.SAVE_TOTAL_SUBSIDY_AVAILABLE:
            return {
                ...state,
                [Constants.TOTAL_SUBSIDY_AVAILABLE]: action.data
            }

        case Actions.SAVE_EST_SUBSIDY_CSR_LEVEL:
            return {
                ...state,
                [Constants.EST_SUBSIDY_CSR_LEVEL]: action.data
            }

        case Actions.RESET_ENR_GRP_SELECTED_PLAN:
            const dummyPlanData = {}
            state.enrGrpsData.forEach((grp, index) => {
                dummyPlanData[index + 1] = { planSelectionStatus: "incomplete" }
            })
            return {
                ...state,
                [Constants.ENR_GRPS_SELECTED_PLAN_DATA]: dummyPlanData
            }

        case Actions.RESET_PLAN_SELECTION_STATUS:
            const planData = {}
            for (const [key, value] of Object.entries(state.enrGrpsSelectedPlanData)) {
                value.planSelectionStatus = "incomplete"
                planData[key] = value;
            }
            return {
                ...state,
                [Constants.ENR_GRPS_SELECTED_PLAN_DATA]: planData
            }

        case Actions.MASTER_RESET_ONEX_REDUCER:
            return initialState;

        case Actions.SET_ENROLLMENT_TYPE:
            return {
                ...state,
                enrollmentType: action.data
            }
        
        case Actions.SAVE_ENROLLMENT_DATA:
            return {
                ...state,
                enrollmentResponse: action.payload
            }

        case Actions.SAVE_SELECTED_APPLY_PLAN:
            return {
                ...state,
                selectedApplyPlan: action.payload
            }
        
        case Actions.SAVE_NON_HEALTH_APPLICANT:
            return {
                ...state,
                nonHealthApplicant: action.data
            }            

        default:
            return state;
    }
}

export default OnExchangeReducer