import * as Actions from 'actions/PlansRecommendedActions';

const initialState = {
    planRecomResponse: "",
    megRedirect: {},
    planRecomData: {}
}

const PlansRecommendedReducer = (state = initialState, action) => {

    switch (action.type) {
        case Actions.SAVE_PLANS_RECOMMENDED_DATA:
            let data = action.data || {};
            return {
                ...state,
                planRecomData: data
            };       

        case Actions.SAVE_PLAN_RECOM_RESPONSE:
            let response = action.data || "";
            return {
                ...state,
                planRecomResponse: response
            };

        case Actions.SAVE_MEG_REDIRECT:
            return {
                ...state,
                megRedirect: action.data
            };

        case Actions.RESET_PLAN_RECOMMENDED_DATA:
            return initialState;

        default:
            return state;
    }
}

export default PlansRecommendedReducer;