import axios from 'axios';
import * as Env from "../constants/Environment";

export const RECORD_ICM_EVENT_SUCCESS = "RECORD_ICM_EVENT_SUCCESS";

export const RECORD_ICM_EVENT_FAILURE = "RECORD_ICM_EVENT_FAILURE";

export const icmRecordEvent = (payload) => {
    return async (dispatch) => {
      try {
        let data = {};
        const url = `/api/services/event/icm`;
        if (Env.ISPRODUCTION) {
          let response = {};
          response = await axios.post(url, payload);
          data = response.data;
        } 
        dispatch(recordIcmEventSuccess(data));
      } catch (error) {
        console.log("icm event tracking error:", error);
        dispatch(recordIcmEventFailure(error));
      }
    };
  };
  
export const recordIcmEventSuccess = (data) => {
    return {
        type: RECORD_ICM_EVENT_SUCCESS,
        data,
    };
};

export const recordIcmEventFailure = (data) => ({
    type: RECORD_ICM_EVENT_FAILURE,
    data,
});





