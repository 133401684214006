import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {DEFAULT_LOCALE } from '../../i18n';
import { UIPopBorder } from "digital-shared-ui";
import imageOne from '../../images/Landing/one.jpg';
import imageTwo from '../../images/Landing/two.jpg';
import imageThree from '../../images/Landing/three.jpg';
import imageFour from '../../images/Landing/four.jpg';
import { Markup } from "interweave";


function LandingBody() {
    const { t, i18n } = useTranslation('Landing');

    const renderContent = () => {
        return (
            <Fragment>

                <section className="banner bg-parent-muted-light">
                    <div className="row">
                        <div className="columns small-12">
                            <p className="legal">
                                <strong>{t('heroSection.disclaimerTitle')}</strong>
                                <Markup content={t('heroSection.disclaimerDescription')} />
                            </p>
                        </div>
                    </div>
                    <div className="content">
                        
                        <div className="row head">
                            <div className="small-12 columns text-center">
                                <h2 id="why-choose" className="hl-xlarge">{t('content.title')}</h2>
                            </div>
                        </div>
<section className='banner '>
                        <div className="row medium-top-2x small-top-4x">
                            <div className="columns small-12 medium-6 large-6 text-center large-left-2x">
                                <div className="columns coin text coin-primary" aria-label="Coin Text with 1 character example">
  <span>1</span>
</div>
                                <h2 className="hl-xlarge">{t('firstSection.title')}</h2>
                                <p>{ t('firstSection.firstParagraph') }</p>
                                <p>{ t('firstSection.secondParagraph') }</p>
                            </div>
                            <div className="columns small-6 medium-6 large-6 large-push-1 hide-for-small-only">
                                <UIPopBorder
                                    src={imageOne.src}
                                    alt={"Family on beach"}
                                    className={"core5"}
                                />
                            </div>
                        </div>
</section>
<section className='banner '>
                        <div className="row medium-top-2x small-top-4x">
                            <div className="columns small-6 medium-6 large-6 hide-for-small-only large-push-1">
                                <UIPopBorder
                                    src={imageTwo.src}
                                    alt={"People with agent"}
                                    className={"core5"}
                                />
                            </div>
                            <div className="columns small-12 medium-6 large-6 text-center large-push-1">
                            <div className="columns coin text coin-primary" aria-label="Coin Text with 1 character example">
  <span>2</span>
  </div>
                            <h2 className="hl-xlarge">{t('secondSection.title')}</h2>
                                <p>{ t('secondSection.firstParagraph') }</p>
                                <p>{ t('secondSection.secondParagraph') }</p>
                            </div>
                        </div>
</section>
                        <section className='banner '>
                        <div className="row medium-top-2x small-top-4x">
                            <div className="columns small-12 medium-6 large-6 text-center large-left-2x">
                            <div className="columns coin text coin-primary" aria-label="Coin Text with 1 character example">
  <span>3</span>
  </div>
                                <h2 className="hl-xlarge">{t('thirdSection.title')}</h2>
                                <p>{ t('thirdSection.firstParagraph') }</p>
                                <p>{ t('thirdSection.secondParagraph') }</p>
                            </div>
                            <div className="columns small-6 medium-6 large-6 large-push-1 hide-for-small-only">
                                <UIPopBorder
                                    src={imageThree.src}
                                    alt={"Nurse with patient"}
                                    className={"core5"}
                                />
                            </div>
                        </div>
</section>
                        <section className='banner '>
                        <div className="row medium-top-2x small-top-4x">
                            <div className="columns small-6 medium-6 large-6 hide-for-small-only large-push-1">
                                    <UIPopBorder
                                        src={imageFour.src}
                                        alt={"Agent on phone"}
                                        className={"core5"}
                                    />
                            </div>
                            <div className="columns small-12 medium-6 large-6 text-center large-push-1">
                            <div className="columns coin text coin-primary" aria-label="Coin Text with 1 character example">
  <span>4</span>
  </div>
                            <h3 className="hl-xlarge">{t('fourthSection.title')}</h3>
                                <p>{ t('fourthSection.firstParagraph') }</p>
                                <p>{ t('fourthSection.secondParagraph') }</p>
                            </div>
                        </div>
</section>
                        </div>
                </section>
            </Fragment>
        );
    }

    return (

        renderContent()

    );
}

export default LandingBody;
