import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const DEFAULT_LOCALE = "en";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    backend: {     
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    lng: DEFAULT_LOCALE, 
    fallbackLng: DEFAULT_LOCALE,
    ns: ['Layout'],
    interpolation: {
      escapeValue: false 
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;