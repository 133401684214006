import { SAVE_VIEW_DATA, RESET_VIEWS_BENEFITS_REDUCER } from "../actions/ViewsBenfitsAction"
import * as Actions from "actions/ViewsBenfitsAction";
import * as Constants from "constants/Constants";

const initialState = {
    isviewsLoaded: false,
    views: [],
    [Constants.GET_GROUPS_AND_BENEFITS_FETCHING]: false,
    [Constants.GET_GROUPS_AND_BENEFITS_DATA]: {},
    [Constants.GET_GROUPS_AND_BENEFITS_ERROR]: false,
    [Constants.GET_BENEFIT_VALUES_FETCHING]: false,
    [Constants.GET_BENEFIT_VALUES_DATA]: [],
    [Constants.GET_BENEFIT_VALUES_ERROR]: false
}

const ViewsBenfitsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SAVE_VIEW_DATA:
            const stateViewArr = state.views;
            action.payload.forEach(view => {
                let viewIdIndex = stateViewArr.findIndex(e => view.viewId == e.viewId && view.productCode == e.productCode)
                if (viewIdIndex == -1) {
                    state.views.push(view);
                }
            });
            return {
                ...state,
                views: [...state.views],
                isviewsLoaded: true
            }

        case RESET_VIEWS_BENEFITS_REDUCER:
            return {
                ...state,
                isviewsLoaded: false,
                views: []
            }
        
        case Actions.GET_GROUPS_AND_BENEFITS_INIT:
            return {
                ...state,
                [Constants.GET_GROUPS_AND_BENEFITS_FETCHING]: true,
            }

        case Actions.GET_GROUPS_AND_BENEFITS_SUCCESS:
            const response = action?.data || {};
            let benefitIds = [];
            let benefitGroups = [];
            let benefitTabViews = [];
            response.planBenefitViews.forEach(view => {
                if (view)
                    benefitTabViews.push(view);
                    view.benefitGroupViews.sort((benefitGroupView1, benefitGroupView2) => (benefitGroupView1.ordinal >= benefitGroupView2.ordinal) ? 1 : -1);
                    view.benefitGroupViews.forEach(benefitGroupView => {
                        benefitGroups.push(benefitGroupView);
                        benefitGroupView.benefitViews.sort((benefitView1, benefitView2) => (benefitView1.ordinal >= benefitView2.ordinal) ? 1 : -1);
                        benefitGroupView.benefitViews.forEach((benefitView) => {
                            benefitIds.push(benefitView.benefitId);
                        })
                    })
            })
            return {
                ...state,
                [Constants.GET_GROUPS_AND_BENEFITS_FETCHING]: false,
                [Constants.GET_GROUPS_AND_BENEFITS_DATA]: { benefitIds, benefitGroups, benefitTabViews },
                [Constants.GET_GROUPS_AND_BENEFITS_ERROR]: false
            }

        case Actions.GET_GROUPS_AND_BENEFITS_FAILURE:
            return {
                ...state,
                [Constants.GET_GROUPS_AND_BENEFITS_FETCHING]: false,
                [Constants.GET_GROUPS_AND_BENEFITS_DATA]: {},
                [Constants.GET_GROUPS_AND_BENEFITS_ERROR]: true
            }

        case Actions.GET_BENEFIT_VALUES_INIT:
            return {
                ...state,
                [Constants.GET_BENEFIT_VALUES_FETCHING]: true,
            }

        case Actions.GET_BENEFIT_VALUES_SUCCESS:
            const benefitsData = action?.data || {};
            let benefitValues = benefitsData.planBenefits || [];
            return {
                ...state,
                [Constants.GET_BENEFIT_VALUES_FETCHING]: false,
                [Constants.GET_BENEFIT_VALUES_DATA]: benefitValues,
                [Constants.GET_BENEFIT_VALUES_ERROR]: false
            }

        case Actions.GET_BENEFIT_VALUES_FAILURE:
            return {
                ...state,
                [Constants.GET_BENEFIT_VALUES_FETCHING]: false,
                [Constants.GET_BENEFIT_VALUES_DATA]: [],
                [Constants.GET_BENEFIT_VALUES_ERROR]: true
            }
        
        case Actions.RESET_BENEFITS_AND_BENEFITVALUES_DATA:
            return {
                ...state,
                [Constants.GET_GROUPS_AND_BENEFITS_FETCHING]: false,
                [Constants.GET_GROUPS_AND_BENEFITS_DATA]: {},
                [Constants.GET_GROUPS_AND_BENEFITS_ERROR]: false,
                [Constants.GET_BENEFIT_VALUES_FETCHING]: false,
                [Constants.GET_BENEFIT_VALUES_DATA]: [],
                [Constants.GET_BENEFIT_VALUES_ERROR]: false
            }

        default:
            return state;
    }
}
export default ViewsBenfitsReducer;