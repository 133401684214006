import * as Actions from '../actions/CartAction';
import * as Constants from "constants/Constants";

const initialState = {
    [Constants.ONEX_CART_ITEMS]: []
}

const CartReducer = (state = initialState, action) => {

    switch (action.type) {
        case Actions.SAVE_ONEX_CART_ITEMS:
            return {
                ...state,
                [Constants.ONEX_CART_ITEMS]: action.data
            }
        case Actions.RESET_ONEX_CART_ITEMS:
            return {
                ...state,
                [Constants.ONEX_CART_ITEMS]: []
            };
        default:
            return state;
    }
}

export default CartReducer;