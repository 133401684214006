import axios from 'axios';

import * as Env from '../constants/Environment';

export const axiosInstance = axios.create({
    baseURL: Env.API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
        // Autowires XSRF-TOKEN cookie value to header X-XSRF-TOKEN
    }
})

// Interceptor to retry and refresh tokens on 403 error
axiosInstance.interceptors.response.use((response) => { return response }, async function (error) {
    const originalRequest = error.config;
    originalRequest.__retryCount = originalRequest.__retryCount || 0;
    if (originalRequest.__retryCount >= Env.API_RETRIES) {
        console.log('Exceeded API_RETRIES.');
        return Promise.reject(error);
    }
    originalRequest.__retryCount += 1;
    var backoff = new Promise(function(resolve) {
        setTimeout(function() {
            resolve();
        }, originalRequest.retryDelay || 1);
    });
    if (error.response.status === 403) {
        axiosInstance.get('getTokens');
        return axiosInstance(originalRequest);
    }
    return backoff.then(function() {
        return axiosInstance(originalRequest);
    });
});

export const useAxiosHandler = () => axiosInstance;