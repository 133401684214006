import React from 'react';
import { useTranslation } from "react-i18next";
import GWCAuthForm from './GWCAuthForm';

function Login() {

    const { t } = useTranslation(['Layout']);

    return (
        <>
            <div className="row padding-3x">
                <div className="columns large-12 medium-12 small-12">
                    <div className="cover" aria-live="polite">
                        <div className="loader"></div>
                    </div>
                </div>
                <div className="columns large-12 medium-12 small-12">
                    <h4 className="top-2x larger-3x text-center">{t('redirectToLoginMsg')}</h4>
                </div>
            </div>
            <GWCAuthForm />
        </>
    )
}

export default Login;