import React from 'react';
import { useTranslation } from "react-i18next";
import * as Env from 'constants/Environment';
import { useSelector, useDispatch } from 'react-redux';
import { Markup } from "interweave";
import { isEmpty, getLocale } from "./commonUtils";

export const getProgressPayload = (peopleData = {}, providerData = {}) => {
    let payload = {};
    
    // People Reducer Payload
    if (!isEmpty(peopleData)) {
        payload.PeopleReducer = getPeoplePayload(peopleData);
    }
    
    // Provider Search Reducer Payload
    if (!isEmpty(providerData)) {
        payload.ProviderSearchReducer = getProviderPayload(providerData);
    }

    console.log("Progress Payload:", payload);

    return payload;
}

export const getPeoplePayload = (data = {}) => {

    let payload = {
        "zipcode": data?.zipcode ?? "",
        "fipscode":  data?.fipscode ?? "",
        "county": data?.county ?? "",
        "progressPath": data?.progressPath ?? "",
        "progressTimeStamp": new Date().toISOString(),
        "progressToken": data?.progressToken ?? Env.USER_TOKEN,
        "progressSave": data?.progressSave ?? false,
        "progressCallBack": data?.progressCallBack ?? false,
        "progressResume": data?.progressResume ?? false,
        "progressLanguage": getLocale(),
        ...(data?.quoteHouseholdSize > 0 && {quoteHouseholdSize: data?.quoteHouseholdSize}),
        ...(data?.isOnExchange === true && {isOnExchange: data?.isOnExchange}),
        ...(!isEmpty(data?.applicants) && {applicants: data?.applicants}),
        ...(!isEmpty(data?.aptcAmount) && {aptcAmount: data?.aptcAmount}),
        ...(!isEmpty(data?.selectedPlan) && {selectedPlan: data?.selectedPlan}),
        ...(!isEmpty(data?.houseHoldIncome) && {houseHoldIncome: data?.houseHoldIncome}),
        ...(!isEmpty(data?.ciUnits) && {ciUnits: data?.ciUnits}),
        ...(!isEmpty(data?.firstName) && {firstName: data?.firstName}),
        ...(!isEmpty(data?.lastName) && {lastName: data?.lastName}),
        ...(!isEmpty(data?.email) && {email: data?.email}),
        ...(!isEmpty(data?.phone) && {phone: data?.phone}),
        ...(!isEmpty(data?.consentFB) && {consentFB: data?.consentFB}),
        ...(!isEmpty(data?.consentHOI) && {consentHOI: data?.consentHOI}),
        ...(!isEmpty(data?.consentFCL) && {consentFCL: data?.consentFCL}),
        ...(!isEmpty(data?.consentUSAble) && {consentUSAble: data?.consentUSAble}),
        ...(!isEmpty(data?.consentText) && {consentText: data?.consentText}),
        ...(!isEmpty(data?.progressProduct) && {progressProduct: data?.progressProduct})
    };

    console.log("People Progress Payload: ", data, payload);

    return payload;
}

export const getProviderPayload = (data = {}) => {

    let payload = {};

    console.log("Provider Progress Payload: ", data, payload);

    return payload;
}
