import React from "react";

import ShoppingContextProvider from "providers/ShoppingContextProvider";

export const ContextProvider = ({ children }) => {
    return (
        <ShoppingContextProvider>
            {children}
        </ShoppingContextProvider>
    );
}