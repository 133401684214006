import { useSelector } from 'react-redux';
import { getAlertsContent } from "utils/alertUtils";

function LandingAlerts() {
    const landingAlertList = useSelector((state) => state.InitDataReducer.initData.dateBasedMessages?.landingAlerts) || [];
    return (
        getAlertsContent(landingAlertList)
    );
}

export default LandingAlerts;