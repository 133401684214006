export const FORCE_PRODUCTION = false;
export const ISPRODUCTION = !(FORCE_PRODUCTION) ? ((process.env.NODE_ENV === "production") ? true : false) : FORCE_PRODUCTION;

export const APPLICATION_NAME = 'CWS';

export const API_BASE_URL = '/api/';
export const CWS_UI_INIT_DATA_ENDPOINT = 'config/getConfig?tenantId=GWCBCBSFL&language=en-US&segment=u65&application=cws';
export const API_RETRIES = 3;

export const USER_TOKEN_NAME = 'USER-TOKEN';
export const USER_TOKEN_VALUE = document.cookie.split(';').map(function (x) { return x.trim().split(/(=)/); }).reduce(function (a, b) { a[b[0]] = a[b[0]] ? a[b[0]] + ', ' + b.slice(2).join('') : b.slice(2).join(''); return a; }, {})[USER_TOKEN_NAME];
export const USER_TOKEN_DEFAULT_VALUE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
export const USER_TOKEN = (USER_TOKEN_VALUE) ? USER_TOKEN_VALUE : USER_TOKEN_DEFAULT_VALUE;
