import { useAxiosHandler } from "hooks/useAxiosHandler";

import * as Env from "../constants/Environment";

import mockFetchEligibilityResponse from "../../data/FetchEligibility.json";
import mockValidateGroupSelection from "../../data/validateGroupSelection.json";
import mockValidatePrimaryApplicantSelection from "../../data/validatePrimaryApplicant.json";

export const SAVE_ELIGIBILITY_DATA = "SAVE_ELIGIBILITY_DATA";
export const SET_FETCH_ELIGIBILITY_ERROR = "SET_FETCH_ELIGIBILITY_ERROR";
export const SAVE_STUB_ELIGIBILITY_DATA = "SAVE_STUB_ELIGIBILITY_DATA";
export const SAVE_APPLICANT_GRP_NUMS = "SAVE_APPLICANT_GRP_NUMS";
export const SAVE_PRIMARY_APPLCANT_SELECTION = "SAVE_PRIMARY_APPLCANT_SELECTION";
export const SAVE_TOBACCO_SELECTION = "SAVE_TOBACCO_SELECTION";
export const SAVE_ENR_GRPS_DATA = "SAVE_ENR_GRPS_DATA";
export const SAVE_SHOULD_DISPLAY_STAR_ICON = 'SAVE_SHOULD_DISPLAY_STAR_ICON';
export const VALIDATE_GRP_SELECTION_INIT = "VALIDATE_GRP_SELECTION_INIT";
export const VALIDATE_GRP_SELECTION_SUCCESS = "VALIDATE_GRP_SELECTION_SUCCESS";
export const VALIDATE_GRP_SELECTION_FAILURE = "VALIDATE_GRP_SELECTION_FAILURE";
export const VALIDATE_PRIMARY_APPLICANT_SELECTION_INIT = "VALIDATE_PRIMARY_APPLICANT_SELECTION_INIT";
export const VALIDATE_PRIMARY_APPLICANT_SELECTION_SUCCESS = "VALIDATE_PRIMARY_APPLICANT_SELECTION_SUCCESS";
export const VALIDATE_PRIMARY_APPLICANT_SELECTION_FAILURE = "VALIDATE_PRIMARY_APPLICANT_SELECTION_FAILURE";
export const SAVE_TOTAL_SUBSIDY_SELECTED = "SAVE_TOTAL_SUBSIDY_SELECTED";
export const SAVE_KEEP_SHOPPING_OTHER_PRODUCT = "SAVE_KEEP_SHOPPING_OTHER_PRODUCT";
export const SAVE_TOTAL_SUBSIDY_AVAILABLE = "SAVE_TOTAL_SUBSIDY_AVAILABLE";
export const RESET_GRP_SELECTION_VALIDATION = "RESET_GRP_SELECTION_VALIDATION";
export const RESET_PRIMARY_APPLICANT_SELECTION_VALIDATION = "RESET_PRIMARY_APPLICANT_SELECTION_VALIDATION";
export const SAVE_ENR_GRP_SELECTED_PLAN = "SAVE_ENR_GRP_SELECTED_PLAN";
export const RESET_ENR_GRP_SELECTED_PLAN = "RESET_ENR_GRP_SELECTED_PLAN";
export const RESET_PLAN_SELECTION_STATUS = "RESET_PLAN_SELECTION_STATUS";
export const MASTER_RESET_ONEX_REDUCER = "MASTER_RESET_ONEX_REDUCER";
export const SAVE_EST_SUBSIDY_CSR_LEVEL = "SAVE_EST_SUBSIDY_CSR_LEVEL";
export const SET_ENROLLMENT_TYPE = "SET_ENROLLMENT_TYPE";
export const SAVE_ENROLLMENT_DATA = "SAVE_ENROLLMENT_DATA";
export const SAVE_SELECTED_APPLY_PLAN = "SAVE_SELECTED_APPLY_PLAN";
export const SAVE_NON_HEALTH_APPLICANT = "SAVE_NON_HEALTH_APPLICANT";
export const SAVE_ONEX_STAR_PLAN_ID = "SAVE_ONEX_STAR_PLAN_ID";

const axios = useAxiosHandler();

export const fetchEligibility = (fetchEligibilityRequest) => {
  return async (dispatch) => {
    try {
      let data = {};
      const url = `services/sales/application/eligibility/fetch`;
      if (Env.ISPRODUCTION) {
        let response = {};
        response = await axios.post(url, fetchEligibilityRequest);
        data = response.data;
      } else {
        data = mockFetchEligibilityResponse;
      }
      sortApplicants(data);
      dispatch(saveEligibilityData(data));
    } catch (error) {
      console.log("fetch Eligibility error:", error);
      dispatch(setFetchEligibilityError(error));
    }
  };
};

export const fetchStubEligibility = (data = {}) => {
  return async (dispatch) => {
    try {
      sortApplicants(data);
      dispatch(saveStubEligibilityData(data));
    } catch (error) {
      console.log("fetch Stub Eligibility error:", error);
    }
  };
};

const sortApplicants = (res = {}) => {
  const applicantList = res?.exchangeApplication?.applicantList || [];
  // Sort the applicant by id
  if (applicantList.length > 1) {
    applicantList.sort((a, b) => a?.id?.localeCompare(b?.id));
  }

  return res;
}

export const validateGroupSelection = (requestData) => {
  return async dispatch => {
    try {

      dispatch({ type: VALIDATE_GRP_SELECTION_INIT });

      const url = `services/sales/application/enrollmentgroups/validate`;
      
      let data = {};

      if (Env.ISPRODUCTION) {
        let response = {};
        response = await axios.post(url, requestData);
        data = response.data;
      } else {
        data = mockValidateGroupSelection;
      }

      dispatch(validateGrpSelectionSuccess(data));

    } catch (err) {
      console.log("Error in validateGroupSelection:", err);
      dispatch(validateGrpSelectionFailure(err));
    }
  }
}

export const validatePrimaryApplicantSelection = (requestData) => {
  return async dispatch => {
    try {

      dispatch({ type: VALIDATE_PRIMARY_APPLICANT_SELECTION_INIT });

      const url = `services/sales/application/enrollmentgroups/primaryapplicant/validate`;
      
      let data = {};

      if (Env.ISPRODUCTION) {
        let response = {};
        response = await axios.post(url, requestData);
        data = response.data;
      } else {
        data = mockValidatePrimaryApplicantSelection;
      }

      dispatch(validatePrimaryApplicantSelectionSuccess(data));

    } catch (err) {
      console.log("Error in validatePrimaryApplicantSelection:", err);
      dispatch(validatePrimaryApplicantSelectionFailure(err));
    }
  }
}

export const saveEstimateSubsidyCsrLevel = (data) => ({
  type: SAVE_EST_SUBSIDY_CSR_LEVEL,
  data
})

export const saveApplicantGroupNums = (data) => ({
  type: SAVE_APPLICANT_GRP_NUMS,
  data
})

export const savePrimaryApplicantSelection = (data) => ({
  type: SAVE_PRIMARY_APPLCANT_SELECTION,
  data
})

export const saveTobaccoSelection = (data) => ({
  type: SAVE_TOBACCO_SELECTION,
  data
})

export const saveTotalSubsidySelected = (data) => ({
  type: SAVE_TOTAL_SUBSIDY_SELECTED,
  data
})

export const saveKeepShoppingOtherProduct = (data) => ({
  type: SAVE_KEEP_SHOPPING_OTHER_PRODUCT,
  data
})

export const saveTotalSubsidyAvailable = (data) => ({
  type: SAVE_TOTAL_SUBSIDY_AVAILABLE,
  data
})

export const saveEnrollmentGroupsData = (data) => ({
  type: SAVE_ENR_GRPS_DATA,
  data
})

export const saveEnrGrpSelectedPlan = (data) => ({
  type: SAVE_ENR_GRP_SELECTED_PLAN,
  data
})

export const resetPlanSelectionStatus = () => ({
  type: RESET_PLAN_SELECTION_STATUS
})

export const resetGrpSelectionValidation = () => ({
  type: RESET_GRP_SELECTION_VALIDATION
})

export const masterResetOnExReducer = () => ({
  type: MASTER_RESET_ONEX_REDUCER
})

export const resetPrimaryApplicantSelectionValidation = () => ({
  type: RESET_PRIMARY_APPLICANT_SELECTION_VALIDATION
})

export const resetEnrGrpSelectedPlan = () => ({
  type: RESET_ENR_GRP_SELECTED_PLAN
})

const saveEligibilityData = (data) => ({
  type: SAVE_ELIGIBILITY_DATA,
  payload: data,
});

const setFetchEligibilityError = (error) => ({
  type: SET_FETCH_ELIGIBILITY_ERROR,
  error
});

const saveStubEligibilityData = (data) => ({
  type: SAVE_STUB_ELIGIBILITY_DATA,
  payload: data,
});

export const saveShouldDisplayStarIcon = (data) =>({
  type: SAVE_SHOULD_DISPLAY_STAR_ICON,
  payload: data,
})

export const saveOnExStarPlanId = (data) => ({
  type: SAVE_ONEX_STAR_PLAN_ID,
  payload: data,
})


const validateGrpSelectionSuccess = (data) => {
  return {
    type: VALIDATE_GRP_SELECTION_SUCCESS,
    data
  }
}

const validateGrpSelectionFailure = (error) => {
  return {
    type: VALIDATE_GRP_SELECTION_FAILURE,
    error
  }
}

const validatePrimaryApplicantSelectionSuccess = (data) => {
  return {
    type: VALIDATE_PRIMARY_APPLICANT_SELECTION_SUCCESS,
    data
  }
}

const validatePrimaryApplicantSelectionFailure = (error) => {
  return {
    type: VALIDATE_PRIMARY_APPLICANT_SELECTION_FAILURE,
    error
  }
}

export const setEnrollmentType = (data) => ({
  type: SET_ENROLLMENT_TYPE,
  data
})

export const saveEnrollmentData = (data) => ({
  type: SAVE_ENROLLMENT_DATA,
  payload: data,
})

export const saveSelectedApplyPlan = (data) => ({
  type: SAVE_SELECTED_APPLY_PLAN,
  payload: data,
})

export const saveNonHealthApplicant = (data) => ({
  type: SAVE_NON_HEALTH_APPLICANT,
  data
})

