import { useAxiosHandler } from "hooks/useAxiosHandler";

const axios = useAxiosHandler();

export const SAVE_PLAN_DETAILS_DATA = "SAVE_PLAN_DETAILS_DATA";
export const RESET_PLAN_DETAILS_DATA = "RESET_PLAN_DETAILS_DATA";

export const savePlanDetailsData = (data) => ({
    type: SAVE_PLAN_DETAILS_DATA,
    data
})

export const resetPlanDetailsData = () => ({
    type: RESET_PLAN_DETAILS_DATA
})