export const SAVE_AQT_DATA = "SAVE_AQT_DATA";
export const RESET_AQT_DATA = "RESET_AQT_DATA";

export const saveAqtData = (data) => ({
    type: SAVE_AQT_DATA,
    payload: data
})

export const resetAqtData = () => ({
    type: RESET_AQT_DATA
})
