export const SAVE_RAW_DEMOGRAPHICS_DATA = "SAVE_RAW_DEMOGRAPHICS_DATA";
export const SAVE_ZIPCODE = "SAVE_ZIPCODE";
export const SAVE_FROM_MENU = "SAVE_FROM_MENU";
export const RESET_FROM_MENU = 'RESET_FROM_MENU';
export const SAVE_FIPSCODE = "SAVE_FIPSCODE";
export const SAVE_COUNTY = "SAVE_COUNTY";
export const SAVE_RATING_AREA_CODE = " SAVE_RATING_AREA_CODE";
export const SAVE_HOUSEHOLD_SIZE = "SAVE_HOUSEHOLD_SIZE";
export const SAVE_APPLICANTS = "SAVE_APPLICANTS";
export const SAVE_SUBSIDY_DEMOGRAPHIC_DATA = 'SAVE_SUBSIDY_DEMOGRAPHIC_DATA';
export const SAVE_HELPME_CHOOSE_PLAN = 'SAVE_HELPME_CHOOSE_PLAN';
export const RESET_PEOPLE_REDUCER = 'RESET_PEOPLE_REDUCER';
export const SAVE_SELECTED_PLAN = 'SAVE_SELECTED_PLAN';
export const REMOVE_SELECTED_PLAN = 'REMOVE_SELECTED_PLAN';
export const SAVE_CAMPAIGN_RCV = "SAVE_CAMPAIGN_RCV";
export const SAVE_APTC_AMOUNT = "SAVE_APTC_AMOUNT";
export const SAVE_SUBSIDY_APPLICANTS_INFO = 'SAVE_SUBSIDY_APPLICANTS_INFO';
export const SAVE_HOUSEHOLD_INCOME = 'SAVE_HOUSEHOLD_INCOME';
export const RESET_SUBSIDY_DATA = 'RESET_SUBSIDY_DATA';
export const SAVE_PREBILL = "SAVE_PREBILL";
export const SAVE_ONEXCHANGE = "SAVE_ONEXCHANGE"
export const SAVE_BEGIN_APPLICATION_DATA = "SAVE_BEGIN_APPLICATION_DATA";
export const RESET_BEGIN_APPLICATION_DATA = "RESET_BEGIN_APPLICATION_DATA";
export const RESET_CART_DATA = "RESET_CART_DATA";
export const SAVE_CI_UNITS = "SAVE_CI_UNITS";
export const SAVE_FILTERS = "SAVE_FILTERS";
export const RESET_SAVE_FILTERS = "RESET_SAVE_FILTERS";
export const ADD_PROGRESS_INFO = "ADD_PROGRESS_INFO";
export const UPDATE_PROGRESS_INFO = "UPDATE_PROGRESS_INFO";
export const SAVE_PEOPLE_REDUCER_DATA = "SAVE_PEOPLE_REDUCER_DATA";
export const CLOSE_PROGRESS_CALLBACK = "CLOSE_PROGRESS_CALLBACK";
export const CLOSE_SAVE_PROGRESS = "CLOSE_SAVE_PROGRESS";

export const saveRawDemographicsData = (data) => ({
    type: SAVE_RAW_DEMOGRAPHICS_DATA,
    payload: data
})

export const saveZipcode = (data) => ({
    type: SAVE_ZIPCODE,
    payload: data
})

export const saveFromMenu = (data)=>({
    type: SAVE_FROM_MENU,
    payload: data
})

export const resetFromMenu = (data) => ({
    type: RESET_FROM_MENU,
    payload:data
})

export const saveFipsCode = (data) => ({
    type: SAVE_FIPSCODE,
    payload: data
})

export const saveCounty = (data) => ({
    type: SAVE_COUNTY,
    payload: data
})

export const saveRatingAreaCode = (data) => ({
    type: SAVE_RATING_AREA_CODE,
    payload: data
})

export const saveHouseholdSize = (data) => ({
    type: SAVE_HOUSEHOLD_SIZE,
    payload: data
})

export const saveApplicants = (data) => ({
    type: SAVE_APPLICANTS,
    payload: data
})

export const saveHelpmeChoosePlan = (data) => ({
    type: SAVE_HELPME_CHOOSE_PLAN,
    payload: data
})

export const saveSubsidyDemographicData = (data) => ({
    type: SAVE_SUBSIDY_DEMOGRAPHIC_DATA,
    data
})

export const saveSelectedPlan = (data) => ({
    type: SAVE_SELECTED_PLAN,
    payload: data
})

export const removeSelectedPlan = (index) => ({
    type: REMOVE_SELECTED_PLAN,
    payload: index
})

export const resetCartData = () => ({
    type: RESET_CART_DATA,
})

export const saveBeginApplicationData = (data) => ({
    type: SAVE_BEGIN_APPLICATION_DATA,
    payload: data
})

export const resetBeginApplicationData = () => ({
    type: RESET_BEGIN_APPLICATION_DATA,
})

export const saveCampaignRcv = (data) => {
    return ({
        type: SAVE_CAMPAIGN_RCV,
        payload: data
    });
}

export const saveAptcAmount = (data) => ({
    type: SAVE_APTC_AMOUNT,
    payload: data
})

export const resetPeopleReducer = () => ({
    type: RESET_PEOPLE_REDUCER,
})

export const saveSubsidyApplicantsInfo = (data) => ({
    type: SAVE_SUBSIDY_APPLICANTS_INFO,
    data
})

export const saveHouseholdIncome = (data) => ({
    type: SAVE_HOUSEHOLD_INCOME,
    payload: data
})

export const resetSubsidyData = () => ({
    type: RESET_SUBSIDY_DATA
})

export const savePrebill = (data) => ({
    type: SAVE_PREBILL,
    data
})

export const saveOnExchange = (data) => ({
    type: SAVE_ONEXCHANGE,
    data
})

export const saveCiUnits = (data) => ({
    type: SAVE_CI_UNITS,
    data
})

export const saveFilters = (data) => ({
    type: SAVE_FILTERS,
    data
})

export const resetSaveFilters = () => ({
    type: RESET_SAVE_FILTERS
})

export const addProgressInfo = (data) => ({
    type: ADD_PROGRESS_INFO,
    payload: data
})

export const updateProgressInfo = (data) => ({
    type: UPDATE_PROGRESS_INFO,
    payload: data
})

export const savePeopleReducerData = (data) => ({
    type: SAVE_PEOPLE_REDUCER_DATA,
    payload: data
})

export const closeProgressCallback = (data) => ({
    type: CLOSE_PROGRESS_CALLBACK,
    payload: data
})

export const closeSaveProgress = (data) => ({
    type: CLOSE_SAVE_PROGRESS,
    payload: data
})