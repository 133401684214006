import * as ActionConstants from "actions/InitDataAction";
import * as Constants from "constants/Constants";

const initialState = {
    [Constants.CWS_UI_INIT_DATA]: {},
    [Constants.CWS_UI_INIT_DATA_FETCHING]: false,
    [Constants.CWS_UI_INIT_DATA_ERROR]: false,  
    marketPlaceReturn: false,          
}

const InitDataReducer = (state = initialState, action) => {

    switch (action.type) {

        case ActionConstants.GET_CWS_INIT_DATA_INIT:
            return {
                ...state,
                [Constants.CWS_UI_INIT_DATA_FETCHING]: true,
            }

        case ActionConstants.GET_CWS_INIT_DATA_SUCCESS:
            let initData = action.data;
            return {
                ...state,
                [Constants.CWS_UI_INIT_DATA_FETCHING]: false,
                [Constants.CWS_UI_INIT_DATA_ERROR]: false,
                [Constants.CWS_UI_INIT_DATA]: initData
            }

        case ActionConstants.GET_CWS_INIT_DATA_FAILURE:
            return {
                ...state,
                [Constants.CWS_UI_INIT_DATA_FETCHING]: false,
                [Constants.CWS_UI_INIT_DATA]: {},
                [Constants.CWS_UI_INIT_DATA_ERROR]: true,
            }

        case ActionConstants.MARKET_PLACE_RETURN:
            return {
                ...state,
                marketPlaceReturn: true,
            }
        case ActionConstants.NON_MARKET_PLACE_RETURN:
            return {
                ...state,
                marketPlaceReturn: false,
            }
        default:
            return state;
    }
}

export default InitDataReducer