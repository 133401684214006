import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LandingZipCode from "./LandingZipCode";
import { resetPlanRatesReducer } from "../../actions/PlanRatesAction";
import { resetViewBenefitsReducer } from "../../actions/ViewsBenfitsAction";
import { getReceived } from "../../actions/OnReceiveAction";
import banner from "../../images/Landing/banner.jpg?sizes[]=320,sizes[]=640,sizes[]=960,sizes[]=1200";
import { useShoppingContext } from 'contexts/ShoppingContext';

function LandingHero() {
  const { t, i18n } = useTranslation('Landing');
  const products = useSelector((state) => state.InitDataReducer.initData.products);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { offExEffectiveDate, onExEffectiveDate } = useShoppingContext();

  useEffect(() => {
    dispatch(resetPlanRatesReducer());
    dispatch(resetViewBenefitsReducer());
    //receive flows: campaign, members (redirect)
    dispatch(getReceived(navigate, products, offExEffectiveDate));
  }, []);

  const renderContent = () => {
    return (
      <Fragment>
        <div className="banner hero quaternary">
          <div className="row">
            <div className="columns small-12 medium-6 large-6">
              <div className="content">
                <h1 className="hl-xlarge">
                  {t('heroSection.header')}
                </h1>
                <LandingZipCode />
              </div>
            </div>
            <div className="columns small-12 medium-6 large-6 hide-for-small-only">
              <picture>
                <img
                  src={banner.src}
                  srcSet={banner.srcSet}
                //loading="lazy" Dont Lazy load initial page images
                />
              </picture>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return renderContent();
}

export default LandingHero;
