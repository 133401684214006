import * as ActionConstants from "actions/MwsQhpAction";

const initialState = {
  receive: {},
  views: [],
  viewsBenfits: [],
  benfitsInfo: []
};

const MwsQhpReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.SAVE_MWS_QHP_RCV:
      return {
        ...state,
        receive: action.payload
      };
    case ActionConstants.SAVE_MWS_QHP_VIEWS:
      return {
        ...state,
        views: action.payload
      };
    case ActionConstants.SAVE_MWS_QHP_BENEFITS:
      return {
        ...state,
        viewsBenfits: action.payload
      };
    case ActionConstants.SAVE_MWS_QHP_BENEFITS_INFO:
      return {
        ...state,
        benfitsInfo: action.payload
      };
    case ActionConstants.RESET_MWS_QHP_REDUCER:
      return {
          ...state,
          receive: initialState.receive,
          views: initialState.views,
          viewsBenfits: initialState.viewsBenfits,
          benfitsInfo: initialState.benfitsInfo
      }
    default:
      return state;
  }
};

export default MwsQhpReducer;
