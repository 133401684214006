import { formatDate, isEmpty } from "../utils/commonUtils";
import { DEPENDENT_CHILD, APPLICANT, SPOUSE, DOMESTIC_PARTNER } from '../constants/ApplicantDemographicConstants';
import { calculateAge } from "utils/shoppingUtils";

export const GROUP_SELECTION_PAGE = 0;
export const PRIMARY_SELECTION_PAGE = 1;
export const ADJUST_TAX_CREDIT_PAGE = 2;
export const DEPENDENT_RELATIONSHIPS = ["dependent_child"];

export const getTobaccoChoices = (t) => {
    return ([
        { label: t('tobaccoChoices.select'), value: "" },
        { label: t('tobaccoChoices.N'), value: "N" },
        { label: t('tobaccoChoices.Y-6'), value: "Y-6" },
        { label: t('tobaccoChoices.Y-7-12'), value: "Y-7-12" },
        { label: t('tobaccoChoices.Y-13-36'), value: "Y-13-36" },
        { label: t('tobaccoChoices.Y-37'), value: "Y-37+" }
    ]);
};

export const calucateAge = (date) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }
    return age;
}

export const sameGroupSelection = (groupsSelection, applicantsGroups) => {
    const gsKeys = (groupsSelection || []).map((gs) => `${gs.id}-${gs.groupNum}`);
    const agKeys = [];
    (applicantsGroups || []).forEach((g) => {
        (g || []).forEach((a) => {
            agKeys.push(`${a.id}-${a.groupNum}`);
        })
    })
    return gsKeys.sort().join('|') === agKeys.sort().join('|');
};

export const buildApplicantsGroups = (groupsSelection, applicants, maxTobaccoAge) => {
    const objGroupsIds = {};
    (groupsSelection || []).forEach((gs) => {
        if (objGroupsIds[gs.groupNum]) {
            objGroupsIds[gs.groupNum].push(gs.id);
        } else {
            objGroupsIds[gs.groupNum] = [gs.id];
        }
    })
    const groupNums = Object.keys(objGroupsIds);
    let enrichApplicantsGroups = [];
    (groupNums || []).forEach((groupNum) => {
        const group = [];
        const applicantsIds = objGroupsIds[groupNum];
        const primary = applicantsIds.length == 1 ? "Y" : "N";
        (applicantsIds || []).forEach((id) => {
            const applicant = (applicants || []).find((a) => a.id == id);
            if (calucateAge(applicant.dob) >= maxTobaccoAge) {
                group.push({ ...applicant, groupNum, showTobaccoQuestion: true, primary, error_tobaccoUse: "" });
            } else {
                group.push({ ...applicant, groupNum, showTobaccoQuestion: false, primary });
            }
        });
        enrichApplicantsGroups.push(group);
    });
    return enrichApplicantsGroups;
};

export const buildApplicantList = (applicants) => {
    return (applicants || []).map((a) => {
        const addrs = a.addresses;
        const addr = addrs?.length > 0 ? addrs.find(ad => ad.type == "HOME_ADDRESS") : [];
        let formatedDOB = formatDate(a.dob);
        return {
            id: a.id,
            dob: formatedDOB || "",
            age: calculateAge(formatedDOB),
            firstName: a.firstName || "",
            lastName: a.lastName || "",
            gender: a.gender || "",
            zipCode: addr?.zip || "",
            county: addr?.county || "",
            relationship: a.relationList?.additionalProp1 || "",
            allowedMetals: a.eligibility?.allowedPlanMetalLevels || [],
            isQHPEligible: a.eligibility?.qhpEligible || false,
            isCSREligible: a.eligibility?.csrEligible || false,
            csrLevel: a.eligibility?.csrLevel,
            isAPTCEligible: a.eligibility?.aptcEligible || false,
            isCurrentPlanOnly: a.eligibility?.crntPlnOnlyInd || false,
            isNewMember: a.eligibility?.newMbrInd || false,
            monthlyAPTCAmount: a.eligibility?.monthlyAPTCAmount || 0
        };
    });
};

export const isDE = (applicants) => {
    let domesticPartnerIndex = (applicants || []).findIndex((applicant) => applicant.relationship === DOMESTIC_PARTNER);

    if (domesticPartnerIndex > -1) {
        return true;
    }        
    return false;
};

export const calculatedOnExEffectiveDate = (coverageYear, onExEffectiveDate, insurancePolicyDtlsInd = false, sysDate, oepSepTest) => {
    let today = new Date();
    if (oepSepTest) {
        today = new Date(sysDate);
    }
    let currentYear = today.getFullYear();
    let nextYear = currentYear + 1;
    let checkFromDate = `12/01/${currentYear}`; // MM/DD/YYYY Format
    let checkToDate = `12/15/${currentYear}`; // MM/DD/YYYY Format
    let earliestEffectiveDate = `01/01/${nextYear}`; // MM/DD/YYYY Format  
    let isBetweenDateRange = (today >= new Date(checkFromDate) && today <= new Date(checkToDate));
    
    // Update System Effective Date to Current Date
    // Rules:
    // 1. Has existing coverage
    // 2. Received coverage year is same as current year
    // 3. System effective date is 1st Jan of Next Year
    // 4. Current date between 12/1 to 12/15
    if (insurancePolicyDtlsInd == true && coverageYear == currentYear && formatDate(onExEffectiveDate) == earliestEffectiveDate && isBetweenDateRange) {
        return formatDate(today);
    }

    return formatDate(onExEffectiveDate);
}

export const isApplicationEligible = (coverageYear, onExEffectiveDate, insurancePolicyDtlsInd = false, sysDate, oepSepTest) => {
    let today = new Date();
    if (oepSepTest) {
        today = new Date(sysDate);
    }
    let currentYear = today.getFullYear();
    let nextYear = currentYear + 1;
    let checkFromDate = `12/01/${currentYear}`; // MM/DD/YYYY Format
    let checkToDate = `12/15/${currentYear}`; // MM/DD/YYYY Format
    let earliestEffectiveDate = `01/01/${nextYear}`; // MM/DD/YYYY Format  
    let isBetweenDateRange = (today >= new Date(checkFromDate) && today <= new Date(checkToDate));
    
    // Application Not eligible if -
    // 1. Has no existing coverage
    // 2. Received coverage year is same as current year
    // 3. System effective date is 1st Jan of Next Year
    // 4. Current date between 12/1 to 12/15
    if (insurancePolicyDtlsInd == false && coverageYear == currentYear && formatDate(onExEffectiveDate) == earliestEffectiveDate && isBetweenDateRange) {
        return false;
    }

    return true;
}

export const getMegEffectiveDate = (applicants) => {
    let effectiveDate = "";
    let firstEligibleApplicant = (applicants || []).find((applicant) => applicant.eligibility?.qhpEligible) || {};

    if (!isEmpty(firstEligibleApplicant)) {
        let enrollmentPeriodData = firstEligibleApplicant?.enrollmentPeriod ?? {};
        if (!isEmpty(enrollmentPeriodData)) {
            // SEP Effective Date
            if (enrollmentPeriodData?.sepIndicator === true && enrollmentPeriodData?.iepIndicator === false) {
                effectiveDate = enrollmentPeriodData?.sepEarliestEffectiveDate ?? "";
            }
            // AEP Effective Date
            if (enrollmentPeriodData?.iepIndicator === true) {
                effectiveDate = enrollmentPeriodData?.iepEarliestEffectiveDate ?? "";
            }
        }
    } 

    return effectiveDate;
};
