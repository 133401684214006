import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import { ShoppingContext } from "contexts/ShoppingContext";
import * as PlanUtils from "utils/planUtils";
import * as Constants from "constants/Constants";
import * as OnExchangeUtils from 'utils/onExchangeUtils';


function ShoppingContextProvider({ children }) {

    const location = useLocation();

    const sysDate = useSelector((state) => state.InitDataReducer?.initData?.sysDate);

    const browserDate = new Date();

    const oepSepTest = useSelector((state) => state.InitDataReducer?.initData?.oepSepTest);

    const products = useSelector((state) => state.InitDataReducer?.initData?.products) || [];
    
    const exchAppData = useSelector(state => state.OnExchangeReducer[Constants.EXCHANGE_APPLICATION] || {});    
    const enrGroupsData = useSelector(state => state?.OnExchangeReducer[Constants.ENR_GRPS_DATA] || []);
    const isMEGApplication = enrGroupsData?.length > 0 ? true : false;

    const offExProduct = products.find(p => p.productClassId === Constants.PRCT_CLASS_ID_QHP_OFFEX && p.productClassName === Constants.PRODUCT_CLASS_HEALTH) || {};
    const onExProduct = products.find(p => p.productClassId === Constants.PRCT_CLASS_ID_QHP_ONEX && p.productClassName === Constants.PRODUCT_CLASS_MARKETPLACE) || {};
    const dentalProduct = products.find(p => p.productClassId === Constants.PRCT_CLASS_ID_SAQD && p.productClassName === Constants.PRODUCT_CLASS_QUALIFIED_DENTAL) || {};

    const offExOepStartDate = new Date(offExProduct.oepStartDate);
    const offExOepEndDate = new Date(offExProduct.oepEndDate);
    const onExOepStartDate = new Date(onExProduct.oepStartDate);
    const onExOepEndDate = new Date(onExProduct.oepEndDate);

    // OEP & SEP Flags
    const isOffExOep = PlanUtils.isOEP(offExProduct.oepStartDate, offExProduct.oepEndDate, sysDate, oepSepTest);
    const isOffExSep = PlanUtils.isSEP(offExProduct.oepStartDate, offExProduct.oepEndDate, sysDate, oepSepTest);
    const isOnExOep = PlanUtils.isOEP(onExProduct.oepStartDate, onExProduct.oepEndDate, sysDate, oepSepTest);
    const isDentalOep = PlanUtils.isOEP(dentalProduct.oepStartDate, dentalProduct.oepEndDate, sysDate, oepSepTest);

    const standardEffectiveDate = PlanUtils.getEffectiveDate(sysDate, oepSepTest);

    //Current Date
    const currentDate = PlanUtils.getCurrentDate(sysDate, oepSepTest);

    // Effective Dates
    const offExEffectiveDate = isOffExOep ? (offExProduct?.effectiveDate ? new Date(offExProduct.effectiveDate) : currentDate) : standardEffectiveDate;
    let onExEffectiveDate = isOnExOep ? (onExProduct?.effectiveDate ? new Date(onExProduct.effectiveDate) : currentDate) : standardEffectiveDate;
    const dentalEffectiveDate = isDentalOep ? (dentalProduct?.effectiveDate ? new Date(dentalProduct.effectiveDate) : currentDate) : standardEffectiveDate;
    const usableEffectiveDate = standardEffectiveDate;

    // For MEG Flow, read the ON Exchange effective date from Fetch Eligibility response.
    if (isMEGApplication) {
        const applicantList = exchAppData?.applicantList ?? [];
        const megEffectiveDate = OnExchangeUtils.getMegEffectiveDate(applicantList);
        if (megEffectiveDate) {
            onExEffectiveDate = new Date(megEffectiveDate);
        }
    }

    // OFF-EX Alert Flags
    const offExOepStartIn45Days = PlanUtils.isOEPStartIn45Days(offExProduct.oepStartDate, sysDate, oepSepTest);
    const offExOepStartInOneDay = PlanUtils.isOEPStartInOneDay(offExProduct.oepStartDate, sysDate, oepSepTest);
    const offExOepEndIn15Days = PlanUtils.isOEPEndIn15Days(offExProduct.oepEndDate, sysDate, oepSepTest);
    const offExOepEndInOneDay = PlanUtils.isOEPEndInOneDay(offExProduct.oepEndDate, sysDate, oepSepTest);

    // ON-EX Alert Flags
    const onExOepStartIn45Days = PlanUtils.isOEPStartIn45Days(onExProduct.oepStartDate, sysDate, oepSepTest);
    const onExOepStartInOneDay = PlanUtils.isOEPStartInOneDay(onExProduct.oepStartDate, sysDate, oepSepTest);
    const onExOepEndIn15Days = PlanUtils.isOEPEndIn15Days(onExProduct.oepEndDate, sysDate, oepSepTest);
    const onExOepEndInOneDay = PlanUtils.isOEPEndInOneDay(onExProduct.oepEndDate, sysDate, oepSepTest);

    const effectiveDates = {
        offExEffectiveDate, onExEffectiveDate, dentalEffectiveDate, usableEffectiveDate,
        offExOepStartDate, offExOepEndDate, onExOepStartDate, onExOepEndDate, standardEffectiveDate
    }

    const oepFlags = {
        isOffExOep, isOnExOep, isDentalOep,
        isOffExSep
    }

    const oepAlertFlags = {
        offExOepStartIn45Days, offExOepStartInOneDay, offExOepEndIn15Days, offExOepEndInOneDay,
        onExOepStartIn45Days, onExOepStartInOneDay, onExOepEndIn15Days, onExOepEndInOneDay
    }

    // This object will be supplied through ShoppingContextProvider
    const shoppingContext = {
        ...effectiveDates,  // type - date
        ...oepFlags,        // type - boolean
        ...oepAlertFlags    // type - boolean
    };

    // Console output for troubleshooting
    console.log('ShoppingContextProvider:', {oepSepTest, sysDate, browserDate, effectiveDates, oepFlags, oepAlertFlags} );

    return (
        <ShoppingContext.Provider value={shoppingContext}>
            {children}
        </ShoppingContext.Provider>
    );
}

export default ShoppingContextProvider;