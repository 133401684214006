import React from 'react';
import * as Constants from "constants/Constants";
import { useSelector } from 'react-redux';
import { getLocale } from "utils/commonUtils";
import { ALERTS, UIAlert } from "digital-shared-ui";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import format from "string-template";
import * as Paths from "constants/Routes";
import { useShoppingContext } from "contexts/ShoppingContext";

export const isDateInBetween = (start, end) => {
    const sysDate = useSelector((state) => state.InitDataReducer?.initData?.sysDate);
    const oepSepTest = useSelector((state) => state.InitDataReducer?.initData?.oepSepTest);
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }

    let startDate = new Date(start);
    let endDate = new Date(end);

    if ((currentDate >= startDate) && (currentDate <= endDate)) {
        return true;
    }
    return false;
}

export const getAlertsContent = (alertsList) => {
    const nxtCvrgYear = useSelector((state) => state.InitDataReducer.initData.currentCoverageYear) + 1;

    const {
        offExOepStartDate, onExOepStartDate, onExOepEndDate
    } = useShoppingContext();

    const offExOepStartDateStr = `${offExOepStartDate.toLocaleString('default', { month: 'long' })} ${offExOepStartDate.getDate()}`;
    const onExOepStartDateStr = `${onExOepStartDate.toLocaleString('default', { month: 'long' })} ${onExOepStartDate.getDate()}`;
    const onExOepEndDateStr = `${onExOepEndDate.toLocaleString('default', { month: 'long' })} ${onExOepEndDate.getDate()}`;

    const { t } = useTranslation('GetQuote');
    const phoneNum = t('phoneNum');
    const chatUrl = t('chatUrl');
    const fBlueLocationsUrl = t('fBlueLocationsUrl');
    const findAnAgentUrl = t('findAnAgentUrl');
    
    return alertsList.map(a => {

        const { startDate, endDate, text_en = [], text_es = [], description = "" } = a;

        let isValidDate = isDateInBetween(startDate, endDate);

        let locale = getLocale();
        let alertList = locale === Constants.ESP ? text_es : text_en;

        if (isValidDate) {
            return (
                alertList.map(msg => {

                    let formatedMsg = format(msg, {
                        phoneNum, chatUrl, nxtCvrgYear, fBlueLocationsUrl, findAnAgentUrl,
                        offExOepStartDateStr, onExOepStartDateStr, onExOepEndDateStr
                    })

                    return (
                        <>
                            <UIAlert
                                alertType={ALERTS.ERROR}
                                ariaLabel="service error alert"
                                className={"alert"}
                                fullWidth={true}
                                closeButton={{
                                    title: "close error message",
                                    ariaLabel: "close error message"
                                }}
                            >
                                <p description={description}><Markup content={formatedMsg} /></p>
                            </UIAlert>
                        </>
                    );
                })
            )
        }
    })
}

export const getQuoteAlertsContent = (alertsList) => {
    const nxtCvrgYear = useSelector((state) => state.InitDataReducer.initData.currentCoverageYear) + 1;

    const {
        offExOepStartDate, onExOepStartDate, onExOepEndDate
    } = useShoppingContext();

    const offExOepStartDateStr = `${offExOepStartDate.toLocaleString('default', { month: 'long' })} ${offExOepStartDate.getDate()}`;
    const onExOepStartDateStr = `${onExOepStartDate.toLocaleString('default', { month: 'long' })} ${onExOepStartDate.getDate()}`;
    const onExOepEndDateStr = `${onExOepEndDate.toLocaleString('default', { month: 'long' })} ${onExOepEndDate.getDate()}`;

    const { t } = useTranslation('GetQuote');
    const phoneNum = t('phoneNum');
    const chatUrl = Paths.HELP;
    const chatUrlSpanish = chatUrl + "?language=es"

    const fBlueLocationsUrl = t('fBlueLocationsUrl');
    const findAnAgentUrl = t('findAnAgentUrl');
    
    return alertsList.map(a => {

        const { startDate, endDate, text_en = [], text_es = [], description = "" } = a;

        let isValidDate = isDateInBetween(startDate, endDate);

        let locale = getLocale();
        let alertList = locale === Constants.ESP ? text_es : text_en;

        if (isValidDate) {
            return (
                alertList.map(msg => {

                    let formatedMsg = format(msg, {
                        phoneNum, chatUrl, chatUrlSpanish, nxtCvrgYear, fBlueLocationsUrl, findAnAgentUrl,
                        offExOepStartDateStr, onExOepStartDateStr, onExOepEndDateStr
                    })

                    return <p description={description}><Markup content={formatedMsg} /></p>
                })
            )
        }
    })
}
