import * as Actions from 'actions/PlanDetailsActions';
import * as Constants from "constants/Constants";

const initialState = {
    [Constants.PRODUCT_SEGMENT]: "",
    [Constants.IS_ON_EXCHANGE]: false,
    [Constants.IS_SUBSIDY_FLOW]: false,
    [Constants.IS_MEG_APP]: false,
    [Constants.IS_NEXT_GRP_PRESENT]: false,
    [Constants.SELECTED_ENR_GRP_NUM]: "1",
    [Constants.SELECTED_PLAN_DATA]: {}
}

const PlanDetailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case Actions.SAVE_PLAN_DETAILS_DATA:
            let data = action.data || {};
            return {
                ...state,
                ...data
            };

        case Actions.RESET_PLAN_DETAILS_DATA:
            return initialState;

        default:
            return state;
    }
}

export default PlanDetailsReducer;