import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
//import sessionStorage from 'redux-persist/lib/storage/session'
import localStorage from 'redux-persist/lib/storage';

import rootReducer from './reducers/RootReducer';
import * as Env from './constants/Environment';

/**
 * Using REDUX-PERSIST V6
 * 
 * API docs { @see https://github.com/rt2zz/redux-persist/blob/master/docs/api.md#type-persistconfig }
 */
let persistConfig = {};

persistConfig.key               = Env.APPLICATION_NAME;
persistConfig.storage           = localStorage;

if (Env.ISPRODUCTION) {
    persistConfig.transforms    = [
        encryptTransform({
            secretKey: Env.USER_TOKEN,
            onError: function (error) {
                console.log('An error occurred during the sessionStorage encryption process.', error);
                // TODO: Remove this?
                console.log('Decrypt localStorage failed with USER_TOKEN as: ', Env.USER_TOKEN);
            },
        }),
    ];
    // TODO: Remove this?
    console.log('Encrypted localStorage with USER_TOKEN as: ', Env.USER_TOKEN);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk, promise)));
export const persistor = persistStore(store);