import { useAxiosHandler } from "hooks/useAxiosHandler";
import { saveCampaignRcv, savePrebill } from "../actions/PeopleAction";
import * as Env from "../constants/Environment";
import { GET_QUOTE, MWS_QHP_PREVIEW } from "../../src/constants/Routes";
import { isEmpty, formatDate, isLanguageChanged, onLanguageChange } from "../utils/commonUtils";
import { saveMwsQhpRcv, resetMwsQhpReducer } from "./MwsQhpAction";
import mockViewData from "../../data/views.json";
import { MWS_PLANS_MAIN, PRODUCT_CLASS_HEALTH, PRODUCT_CLASS_MARKETPLACE, PRODUCT_QHP } from "../constants/Constants";
import { APPLICANT, DEPENDENT_CHILD, DOMESTIC_PARTNER, SPOUSE } from "../constants/ApplicantDemographicConstants";
import { getDateStr } from 'utils/commonUtils';
import { calculateAge } from "utils/shoppingUtils";
import recevicedData from "../../data/prebillData.json";

const axios = useAxiosHandler();

const getGender = {
  "F": 'female',
  "M": 'male'
}

const getTobaccoUse = (smokerIndicator) => {
  if (smokerIndicator === "false") {
    return "N";
  } else {
    return smokerIndicator;
  }
}

const getRelationship = (relationshipCode) => {
  console.log("relationshipCode:", relationshipCode);
  switch (relationshipCode) {
    case '18':
      return APPLICANT; //SELF
      break;
    case '01':
      return SPOUSE;
      break;
    case '19':
      return DEPENDENT_CHILD;
      break;
    case '53':
      return DOMESTIC_PARTNER;
      break;

    default:
      return 'other'; //dependent_error
      break;
  }
}

const getProduct = (products, onExchangeIndicator) => {
  const productClassName = onExchangeIndicator === "true" ? PRODUCT_CLASS_MARKETPLACE : PRODUCT_CLASS_HEALTH;
  return products.find(
    (product) =>
      product.productName === PRODUCT_QHP &&
      product.productClassName === productClassName
  );
};

export const getReceived = (navigate, products, effDate) => {
  return async (dispatch) => {
    try {
      let receive = await getRcv();
      // unComment below line for mockData of prebill
     // let receive = recevicedData;
      //receive flows: campaign, members
      if (isCampaign(receive)) {
        dispatch(saveCampaignRcv(receive));
        if (isLanguageChanged(receive.language)) onLanguageChange();
        console.log('Navigate to GET QUOTE...');
        navigate(`${GET_QUOTE}`, { replace: true });
      } else if (isMember(receive)) {
        console.log("isMember", receive);
        dispatch(resetMwsQhpReducer());
        dispatch(saveMwsQhpRcv(receive)); 
        let finalApplicantsList = [];
        let applicantObj = {};
        const memberContract = receive.memberContract || {};
        const dependents = memberContract.dependents || [];
        const resedentialAddress = (memberContract.addresses || []).find((a) => a.addressTypeCode === "RES");

        applicantObj.relationship = getRelationship(memberContract.relationshipCode);
        applicantObj.DOB = formatDate(memberContract.dob, "YYYY-MM-DD");
        applicantObj.age = calculateAge(memberContract.dob);
        applicantObj.gender = getGender[memberContract.gender];
        applicantObj.aptcAmount = "";
        applicantObj.tobaccoUse = getTobaccoUse(memberContract.smokerIndicator);
        applicantObj.id = memberContract.userId;
        applicantObj.firstName = memberContract.firstName ?? "";
        applicantObj.middleName = memberContract.middleName ?? "";
        applicantObj.lastName = memberContract.lastName ?? "";
        finalApplicantsList.push(applicantObj);
        
        dependents.forEach((dependent, index) => {
          let dependentObj = {};
          dependentObj.relationship = getRelationship(dependent.relationShipCode);
          dependentObj.DOB = formatDate(dependent.dob, "YYYY-MM-DD");
          dependentObj.age = calculateAge(dependent.dob);
          dependentObj.gender = getGender[dependent.gender];
          dependentObj.aptcAmount = "";
          dependentObj.tobaccoUse = getTobaccoUse(dependent.smokerIndicator);
          dependentObj.id = `applicant-${index}`;
          dependentObj.firstName = dependent.firstName ?? "";
          dependentObj.middleName = dependent.middleName ?? "";
          dependentObj.lastName = dependent.lastName ?? "";
          finalApplicantsList.push(dependentObj);
        });
        const countyName = await getCountyName(resedentialAddress?.zipCodeFirstFive);
        const prebill = {
          zipcode: resedentialAddress.zipCodeFirstFive,
          fipscode: resedentialAddress.countyCode,
          county: countyName,
          quoteHouseholdSize: (dependents.length + 1),
          applicants: finalApplicantsList
        };
        dispatch(savePrebill(prebill));

        if (isLanguageChanged(receive.languageCode)) onLanguageChange();
        navigate(`${MWS_QHP_PREVIEW}`, { replace: true });    
      }
    } catch (error) {
      console.log("getReceived error:", error);
    }
  };
};

const getCountyName = async (zipCode) => {
  let countyName = "";
  const url = `products/plans/servicezipcodes?search=` + zipCode;
  const response = await axios.get(url);
  let zipCodeArr = response.data.serviceZipCodes;
  
  if (zipCodeArr.length > 0) {
    countyName = zipCodeArr[0].countyName;
  }
  
  return countyName;
};

const isCampaign = (data) => {
  const r = data || {};
  return !isEmpty(r.keycode) && !isEmpty(r.zip);
};

const isMember = (data) => {
  const r = data || {};
  return (
    !isEmpty(r.languageCode) &&
    !isEmpty(r.memberContract) &&
    !isEmpty(r.memberPlans)
  );
};

export const getRcv = async () => {
  let data = {};
  const url = `getRcv`;
  if (Env.ISPRODUCTION) {
    let response = {};
    response = await axios.get(url);
    data = response.data;
  } else {
    //sessionStorage.setItem("payload", JSON.stringify({...}));
    data = JSON.parse(sessionStorage.getItem("payload"));
    sessionStorage.setItem("payload", null); //prevent infinit loop
  }
  
  return data;
};

export const getViews = async (productCode, effDate) => {
  let responseGetViews = [];
  if (Env.ISPRODUCTION) {
    let params = { 
      clientId: 'CWS',
      productCode,
      effectiveDate: getDateStr(effDate, "mm/dd/yyyy") 
    }
    let apiResp = await axios.get("/products/views",{ params });
    responseGetViews = apiResp.data.views;
  } else {
    responseGetViews = mockViewData.views.filter(
      (view) => view.productCode == productCode
    );
  }
  return responseGetViews;
};
