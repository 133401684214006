import React, { Fragment } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import * as Paths from "constants/Routes";
import { useShoppingContext } from 'contexts/ShoppingContext';
import * as Constants from "constants/Constants";
import { saveRedirectToMemberAuth } from "actions/AuthAction";


function LandingHelp() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(['Landing','FormNumbers']);

    const { isOnExOep, isOffExOep } = useShoppingContext();

    const isMOT = useSelector((state) => state.AuthReducer.isMOT);
    const isAuthenticated = useSelector((state) => state.AuthReducer.isAuthenticated);
    const isAuthDataFetchCompleted = useSelector((state) => state.AuthReducer.authDataFetchCompleted);
    const findAPlanUrl = useSelector((state) => state.InitDataReducer.initData.urls?.findAPlanUrl || {});
    const memberAccountPrebillUrl = useSelector((state) => state.InitDataReducer.initData.urls?.memberAccountPrebillUrl || "https://gwprofile.bcbsfl.com/profile/interstitial/?reason=PREBILL");

    const redirectMemberProfile = () => {
        let navPath = ""
        if (isAuthenticated) {
            navPath = isMOT ? Paths.MEMBER_PROFILE : Paths.HOME;
        } else {
            navPath = Paths.MEMBER_PROFILE;
        }
        dispatch(saveRedirectToMemberAuth(true));
        navigate(navPath, { replace: true });
    }

    const redirectMemberPrebill = () => {
        // US673343 navigate to memberprebillurl
        window.location.href = memberAccountPrebillUrl + '&locale=' + i18n.language;
    }

    const renderContent = () => {
        const classStr = (isOffExOep || isOnExOep) ?
            "small-12 medium-3 large-3 columns text-center small-top-3x medium-top-3x"
            :
            "small-12 medium-4 large-4 columns text-center small-top-3x medium-top-3x"
        return (
            <Fragment>
                <section className="banner">

                    <div className="row head bottom-3x top-3x">
                        <div className="small-12 columns text-center">
                            <span aria-hidden="true" className="icon-wave icon vertical-middle text-center icon-size-8x"></span>
                            <h2 className="hl-xlarge">{t('Landing:helpSection.title')}</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className={classStr}>
                            <div className="coin core1 secondary">
                                <span className="icon icon-edit" aria-hidden="true"></span>
                            </div>
                            <h3 className="hl-small">{t('Landing:helpSection.firstColumnContent')}</h3>
                            <div className="foot">
                                <button
                                    className="primary" aria-label=""
                                    onClick={() => navigate((isAuthDataFetchCompleted && isAuthenticated) ? Paths.APPLICATIONS : Paths.OVERVIEW)}>
                                    <span>
                                    {(isAuthDataFetchCompleted && isAuthenticated) ?
                                         t('helpSection.MyApplications') : t('helpSection.firstColumnButtonText')
                                    }
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className={classStr}>
                            <div className="coin core1 secondary">
                                <span className="icon icon-search" aria-hidden="true"></span>
                            </div>
                            <h3 className="hl-small">{t('Landing:helpSection.secondColumnContent')}</h3>
                            <div className="foot">
                                <button className="primary" aria-label="" onClick={() => navigate(Paths.OTHER_PRODUCTS)}><span>{t('helpSection.secondColumnButtonText')}</span></button>
                            </div>
                        </div>

                        {(isOnExOep || isOffExOep) &&
                            <div className={classStr}>
                                <div className="coin core1 secondary">
                                    <span className="icon icon-user" aria-hidden="true"></span>
                                </div>
                                <h3 className="hl-small">{t('Landing:helpSection.fourthColumnContent')}</h3>
                                <div className="foot">
                                    <button className="primary top-2x" aria-label="" onClick={() => redirectMemberPrebill()}>
                                        <span>{t('Landing:helpSection.fourthColumnButtonText')}</span>
                                    </button>
                                </div>
                            </div>
                        }

                        <div className={classStr}>
                            <div className="coin core1 secondary">
                                <span className="icon icon-medicare" aria-hidden="true"></span>
                            </div>
                            <h3 className="hl-small">{t('helpSection.thirdColumnContent')}</h3>
                            <div className="foot">
                                <button
                                    className="primary" aria-label=""
                                    // onClick={() => window.location.href = i18n.language === "es" ? Constants.CWS_MEDICARE_FND_PLAN_ES : Constants.CWS_MEDICARE_FND_PLAN_EN}>
                                    onClick={() => window.location.href = findAPlanUrl}>
                                    <span>{t('Landing:helpSection.thirdColumnButtonText')}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="formNumberSection top-3x">
                        <div className="row">
                            <p className="form-number">{t('FormNumbers:landingFormNumber')}</p>
                        </div>
                    </div>

                </section>
            </Fragment>
        )
    }

    return (

        renderContent()

    );
}

export default LandingHelp;