import React from 'react';
import { ALERTS, UIAlert } from "digital-shared-ui";
import { useTranslation } from "react-i18next";

function LandingLogoutAlert() {
    const { t } = useTranslation('Landing');
    return (
        <>
            <UIAlert
                alertType={ALERTS.ERROR}
                ariaLabel="service error alert"
                className={"alert"}
                fullWidth={true}
                icon={true}
                closeButton={{
                    title: "close error message",
                    ariaLabel: "close error message"
                }}
            >
                <span className="left-1x vertical-top">
                    {t('alert.logout')}
                </span>
            </UIAlert>
        </>
    );
}

export default LandingLogoutAlert;