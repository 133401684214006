import { useAxiosHandler } from 'hooks/useAxiosHandler';
import * as Env from '../constants/Environment';
import { buildRatesRequest } from '../components/Plans/Helpers/CommonHelper';
import mockPlanData from '../../data/plans.json';
import mockRatesData from '../../data/rates.json';
import { PRODUCT_SEGMENT_HEALTH, PRODUCT_SEGMENT_DENTAL, PRODUCT_SEGMENT_USABLE } from '../constants/Constants'
import { getDateStr, isEmpty } from 'utils/commonUtils';

const axios = useAxiosHandler();

export const SAVE_QHP_PLAN_DATA = "SAVE_QHP_PLAN_DATA";
export const SAVE_DENTAL_PLAN_DATA = "SAVE_DENTAL_PLAN_DATA";
export const SAVE_USABLE_PLAN_DATA = "SAVE_USABLE_PLAN_DATA";
export const SAVE_RATE_DATA = "SAVE_RATE_DATA";
export const RESET_PLANS_RATES_REDUCER = 'RESET_PLANS_RATES_REDUCER';
export const SAVE_RIGHTSIZER_REDIRECT = 'SAVE_RIGHTSIZER_REDIRECT';
export const SET_RELOAD_PLAN_DATA = 'SET_RELOAD_PLAN_DATA';

const saveQHPPlanData = (data) => ({
    type: SAVE_QHP_PLAN_DATA,
    payload: data
})

const saveDentalPlanData = (data) => ({
    type: SAVE_DENTAL_PLAN_DATA,
    payload: data
})

const saveUsAblePlanData = (data) => ({
    type: SAVE_USABLE_PLAN_DATA,
    payload: data
})

export const resetPlanRatesReducer = () => ({
    type: RESET_PLANS_RATES_REDUCER,
})

export const saveRightsizerRedirect = (data) => ({
    type: SAVE_RIGHTSIZER_REDIRECT,
    payload: data
})

export const populateDentalProductsInfo = async (zipCode, countyCode, countyName, applicants, productSegment, products, estTaxCredit, isOnExchange, effDate) => {
    const default_units = 2;
    let productPlanInfoArr = [];
    let csrLevel = '';
    for (const product of products) {
        let productObj = product;
        let planInfoArr = [];
        if (product.effDate) {
            effDate = product.effDate;
        }
        const plans = await getPlans(countyCode, product, isOnExchange, productSegment, effDate, csrLevel);
        if (plans.length != 0) {
            for (const plan of plans) {
                let planInfoObj = {
                    planId: plan.planId,
                    planName: plan.planName,
                    planDescription: plan.planDescription,
                    networkCode: plan.networkCode,
                    planCategory: plan.planCategory,
                    productCode: plan.productCode,
                    startDate: new Date(plan.startDate),
                    endDate: new Date(plan.endDate),
                    locale: plan.locale,
                    metalLevel: plan.metalLevel,
                    pediatricDentalAvailable: plan.pediatricDentalAvailable,
                    pediatricVisionAvailable: plan.pediatricVisionAvailable,
                    adultDentalAvailable: plan.adultDentalAvailable,
                    visionAvailable: plan.visionAvailable,
                    hsaSupported: plan.hsaSupported,
                    globalRating: plan.globalRating,
                    clinicalExpRating: plan.clinicalExpRating,
                    enrollmentExpRating: plan.enrollmentExpRating,
                    planEffRating: plan.planEffRating,
                    sbcUrl: plan.sbcUrl,
                    miniSbcUrl: plan.miniSbcUrl,
                    ehbPercent: plan.ehbPercent,
                    planCode: plan.planCode
                }
                planInfoArr.push(planInfoObj)
            }
            if (applicants.length != 0) {
                const ratesArr = await getRates(zipCode, countyCode, countyName, applicants, product, planInfoArr, default_units, effDate);
                if (ratesArr && ratesArr.length != 0) {
                    for (const rate of ratesArr) {
                        let planId = "";
                        if (rate.planVariantID === null) {
                            planId = rate.basePlanID
                        } else {
                            planId = rate.basePlanID + "-" + rate.planVariantID;
                        }
                        var planInfoObjIndex = planInfoArr.findIndex(planInfo => planInfo.planId === planId);
                        if (planInfoObjIndex != -1) {
                            const totalPremium = parseFloat(rate.premiumInfo.totalPremium).toFixed(2);

                            if (isOnExchange) {
                                if (estTaxCredit >= totalPremium) {
                                    planInfoArr[planInfoObjIndex].estTaxCredit = totalPremium;
                                    planInfoArr[planInfoObjIndex].estCostAfterTaxCredit = 0.00;
                                } else {
                                    planInfoArr[planInfoObjIndex].estTaxCredit = estTaxCredit;
                                    planInfoArr[planInfoObjIndex].estCostAfterTaxCredit = totalPremium - estTaxCredit;
                                }
                            }
                            planInfoArr[planInfoObjIndex].totalPremium = totalPremium;
                        }
                    }
                    if (planInfoArr.length != 0) {
                        productObj.planInfoObjs = planInfoArr;
                    }
                }
            }
            if (productObj.planInfoObjs && productObj.planInfoObjs.length != 0) {
                productPlanInfoArr.push(productObj);
            }
        } else {
            product.planInfoObjs = [];
            productPlanInfoArr.push(product);
        }
    }

    return dispatch => {

        if (productPlanInfoArr.length != 0) {
            dispatch(saveDentalPlanData(productPlanInfoArr));
        }
    }

}

export const populateProductsInfo = (zipCode, countyCode, countyName, applicants, productSegment, products, estTaxCredit, isOnExchange, effDate, csrLevel) => {
    console.log("populateProductsInfo:", { zipCode, countyCode, countyName, applicants, productSegment, products, estTaxCredit, isOnExchange, effDate, csrLevel })
    const default_units = 2;
    return async dispatch => {
        let productPlanInfoArr = [];
        for (const product of products) {
            let productObj = product;
            let planInfoArr = [];
            const plans = await getPlans(countyCode, product, isOnExchange, productSegment, effDate, csrLevel);
            if (plans.length != 0) {
                for (const plan of plans) {
                    let planInfoObj = {
                        planId: plan.planId,
                        planName: plan.planName,
                        planDescription: plan.planDescription,
                        networkCode: plan.networkCode,
                        planCategory: plan.planCategory,
                        productCode: plan.productCode,
                        startDate: new Date(plan.startDate),
                        endDate: new Date(plan.endDate),
                        locale: plan.locale,
                        metalLevel: plan.metalLevel,
                        pediatricDentalAvailable: plan.pediatricDentalAvailable,
                        pediatricVisionAvailable: plan.pediatricVisionAvailable,
                        adultDentalAvailable: plan.adultDentalAvailable,
                        visionAvailable: plan.visionAvailable,
                        hsaSupported: plan.hsaSupported,
                        globalRating: plan.globalRating,
                        clinicalExpRating: plan.clinicalExpRating,
                        enrollmentExpRating: plan.enrollmentExpRating,
                        planEffRating: plan.planEffRating,
                        sbcUrl: plan.sbcUrl,
                        miniSbcUrl: plan.miniSbcUrl,
                        designType: plan.designType,
                        planCode: plan.planCode,
                        weight: plan.weight,
                        ehbPercent: plan.ehbPercent
                    }
                    planInfoArr.push(planInfoObj)
                }
                if (applicants.length != 0) {
                    const ratesArr = await getRates(zipCode, countyCode, countyName, applicants, product, planInfoArr, default_units, effDate);
                    if (ratesArr != 0) {
                        for (const rate of ratesArr) {
                            let applicantIndividualPremium = [];
                            let planId = "";
                            if (rate.planVariantID === null) {
                                planId = rate.basePlanID
                            } else {
                                planId = rate.basePlanID + "-" + rate.planVariantID;
                            }
                            var planInfoObjIndex = planInfoArr.findIndex(planInfo => planInfo.planId === planId);
                            if (planInfoObjIndex != -1) {
                                const totalPremium = parseFloat(rate.premiumInfo.totalPremium).toFixed(2);
                                let applicationRates = rate.premiumInfo.premiumBreakdown.applicantRate;
                                applicationRates.forEach((applicationRate) => {
                                    applicantIndividualPremium.push(applicationRate.premium)
                                })
                                
                                if (isOnExchange) {     
                                    const estTaxCreditBasedOnEhb = (totalPremium * Number(planInfoArr[planInfoObjIndex].ehbPercent)) / 100.0;                              
                                    if ( planInfoArr[planInfoObjIndex].ehbPercent !== null && !isNaN(Number(planInfoArr[planInfoObjIndex].ehbPercent))  && Number(planInfoArr[planInfoObjIndex].ehbPercent) < 100  && estTaxCreditBasedOnEhb < estTaxCredit) {
                                        planInfoArr[planInfoObjIndex].estTaxCredit = estTaxCreditBasedOnEhb;
                                    } else {
                                        planInfoArr[planInfoObjIndex].estTaxCredit = estTaxCredit == "" ? 0 : estTaxCredit;
                                    }
                                    planInfoArr[planInfoObjIndex].estCostAfterTaxCredit = (totalPremium - planInfoArr[planInfoObjIndex].estTaxCredit) <= 0 ? 0 : (totalPremium - planInfoArr[planInfoObjIndex].estTaxCredit);
                                }
                                planInfoArr[planInfoObjIndex].applicantWisePremium = applicantIndividualPremium;
                                planInfoArr[planInfoObjIndex].totalPremium = totalPremium;
                            }
                        }
                        if (planInfoArr.length != 0) {
                            productObj.planInfoObjs = planInfoArr;
                        }
                    }
                }
                if (productObj.planInfoObjs && productObj.planInfoObjs.length != 0) {
                    productPlanInfoArr.push(productObj);
                }
            } else {
                productPlanInfoArr.push(product);
            }
        }
        if (productPlanInfoArr.length != 0) {
            switch (productSegment) {
                case PRODUCT_SEGMENT_HEALTH:
                    dispatch(saveQHPPlanData(productPlanInfoArr.pop()));
                    break;
                case PRODUCT_SEGMENT_DENTAL:
                    dispatch(saveDentalPlanData(productPlanInfoArr));
                    break;
                case PRODUCT_SEGMENT_USABLE:
                    dispatch(saveUsAblePlanData(productPlanInfoArr.pop()));
                    break;
                default:
                    break;
            }
        }

    }
}

const getPlans = async (countyCode, product, isOnExchange, productSegment, effDate, csrLevel) => {
    console.log("getPlans:", { countyCode, product, isOnExchange, productSegment, effDate, csrLevel });
    let response = []
    if (Env.ISPRODUCTION) {
        let apiResp = {}
        if (productSegment === PRODUCT_SEGMENT_USABLE) {
            apiResp = await axios.get('/products/' + product.productCode + '/plans', {
                params: {
                    locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
                    effectiveDate: getDateStr(effDate, "mm/dd/yyyy")
                }
            });
        } else {
            apiResp = await axios.get('/products/' + product.productCode + '/plans', {
                params: {
                    countyCode,
                    isOnExchange,
                    locale: (localStorage['i18nextLng'] === "en") ? "ENG" : "ESP",
                    effectiveDate: getDateStr(effDate, "mm/dd/yyyy"),
                    csrLevel
                }
            });
        }
        response = apiResp.data.plans;
    } else {
        response = mockPlanData.plans.filter(plan => plan.productCode == product.productCode);
    }
    console.log("getPlans: response=", response);
    return response;
}

export const getRates = async (zipCode, countyCode, countyName, applicants, product, plansInfo, units, effDate) => {
    let request = buildRatesRequest(zipCode, countyCode, countyName, applicants, product, plansInfo, units, effDate);
    let response = [];
    if (Env.ISPRODUCTION) {
        const url = `services/getRates`;
        let apiResp = await axios.post(url, request, { timeout: 2000 });
        response = apiResp?.data?.ratedProduct?.ratedPlanList?.ratedPlan;
    } else {
        for (const planInfo of plansInfo) {
            let keyArr = planInfo.planId.split('-');
            let rate = mockRatesData?.ratedPlanList?.ratedPlan.find(plan => plan.basePlanID === keyArr[0]);
            response.push(rate);
        }
    }
    return response;
}

export const setReloadPlanData = (data) => ({
    type: SET_RELOAD_PLAN_DATA,
    payload: data
})
