export const SAVE_MWS_QHP_RCV = "SAVE_MWS_QHP_RCV";
export const SAVE_MWS_QHP_VIEWS = "SAVE_MWS_QHP_VIEWS";
export const SAVE_MWS_QHP_BENEFITS = "SAVE_MWS_QHP_BENEFITS";
export const SAVE_MWS_QHP_BENEFITS_INFO = "SAVE_MWS_QHP_BENEFITS_INFO";
export const RESET_MWS_QHP_REDUCER = "RESET_MWS_QHP_REDUCER";

export const saveMwsQhpRcv = (data) => {
  return {
    type: SAVE_MWS_QHP_RCV,
    payload: data,
  };
};

export const saveMwsQhpViews = (data) => {
  return {
    type: SAVE_MWS_QHP_VIEWS,
    payload: data,
  };
};

export const saveMwsQhpBenefits = (data) => {
  return {
    type: SAVE_MWS_QHP_BENEFITS,
    payload: data,
  };
};

export const saveMwsQhpBenefitsInfo = (data) => {
  return {
    type: SAVE_MWS_QHP_BENEFITS_INFO,
    payload: data,
  };
};

export const resetMwsQhpReducer = () => ({
  type: RESET_MWS_QHP_REDUCER,
})
