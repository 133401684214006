import React, { Fragment, useState, useEffect, useRef } from "react";
import { useAxiosHandler } from 'hooks/useAxiosHandler';
import * as Env from 'constants/Environment';
import zipCodeMock from '../../../data/zipcode.json'
import { UIComboBox2, UIInputText, UIInputNumber, UICheckboxGroup } from 'ui-forms';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveZipcode, saveFipsCode, saveCounty, saveHelpmeChoosePlan, resetPeopleReducer, addProgressInfo, updateProgressInfo } from '../../actions/PeopleAction';
import { GET_QUOTE, MEMBER_PROFILE, HOME } from '../../constants/Routes';
import { PRODUCT_SEGMENT_HEALTH } from '../../constants/Constants';
import { useTranslation } from "react-i18next";
import { helpMeChoosePlanGTM } from '../../utils/analyticsUtils';
import { Markup } from "interweave";
import { collectProgressInfo } from "../../actions/ProgressCollectionAction";
import { getProgressPayload } from "../../utils/progressUtils";
import { isValueObject } from "immutable";
import { isValidEmail, isValidName } from 'utils/commonUtils';

function LandingZipCode() {
    const { t, i18n } = useTranslation(['Landing','GetQuote']);
    const navigate = useNavigate();
    const axios = useAxiosHandler();
    const dispatch = useDispatch();
    const isMOT = useSelector((state) => state.AuthReducer?.isMOT);
    const isAqt = useSelector((state) => state.AqtReducer?.isAqt);
    const peopleReducer = useSelector((state) => state.PeopleReducer);
    const isProgressResume = useSelector((state) => state.PeopleReducer?.progressResume);
    const [errorMsgZip, setErrorMsgZip] = useState("");
    const [emailError,setEmailError] = useState("");
    const [firstNameError,setFirstNameError] = useState("");
    const [lastNameError,setLastNameError] = useState("");
    const [showFirstLastNameForm, setShowFirstLastNameForm] = useState(false);
    const submitContinueBtnRef = useRef();
    const submitHelpMeChooseBtnRef = useRef();
    const [choices, setChoices] = useState([]);
    let zipCodeObj = { "zipcode": "", "county": "", "fipcode": "", "ratingAreaCode": "", "helpmeChoosePlan": "" };
    const [zipCodeInfo, setZipCodeInfo] = useState(zipCodeObj);

    const defaultGeneralInfo = {firstName: '', lastName: '', phone: '', email: '', consentFB: null, consentFCL: null, consentText: null };
    const [generalInfo, setGeneralInfo] = useState(defaultGeneralInfo);

    useEffect(() => {
        if (showFirstLastNameForm) {
            document.getElementById('firstName').focus();
        }
    }, [showFirstLastNameForm]);

    const handleGeneralInfo = (key, value) => {
        setGeneralInfo({
            ...generalInfo,
            [key]: value,
        });
        if (key == 'email' && value !== "") {
            setShowFirstLastNameForm(true);
        }
        if (key == 'email' && value == "") {
            setShowFirstLastNameForm(false);
        }
        return value;
    }

    const isValidInfo = () => {
        let isValid = true;
        setErrorMsgZip("");
        setEmailError("");
        setFirstNameError("");
        setLastNameError("");
        if (!zipCodeInfo.zipcode) {
            setErrorMsgZip(t('Landing:heroSection.zipCodeError'));
            isValid = false;
        }
        if (generalInfo.email !== "" && !isValidEmail(generalInfo.email)) {
            setEmailError(t('Landing:heroSection.emailAddressError'));
            isValid = false;
        }
        if (generalInfo.email !== "" && !isValidName(generalInfo.firstName)) {
            setFirstNameError(t('Landing:heroSection.firstNameFormatError'));
            isValid = false;
        }
        if (generalInfo.email !== "" && !isValidName(generalInfo.lastName)) {
            setLastNameError(t('Landing:heroSection.lastNameFormatError'));
            isValid = false;
        }
        if (generalInfo.email !== "" && generalInfo.firstName == "") {
            setFirstNameError(t('Landing:heroSection.firstNameError'));
            isValid = false;
        }
        if (generalInfo.email !== "" && generalInfo.lastName == "") {
            setLastNameError(t('Landing:heroSection.lastNameError'));
            isValid = false;
        }
        return isValid;
    }

    const handleContinueMouseEvent = (e) => {
        submitContinueBtnRef.current.focus();
    }

    const handleHelpMeChooseMouseEvent = (e) => {
        submitHelpMeChooseBtnRef.current.focus();
    }

    const handleSubmit = () => {
        if(isValidInfo()) {
            dispatch(resetPeopleReducer());
            zipCodeInfo.helpmeChoosePlan = false;
            dispatch(saveZipcode(zipCodeInfo.zipcode));
            dispatch(saveFipsCode(zipCodeInfo.fipcode));
            dispatch(saveCounty(zipCodeInfo.county));
            dispatch(saveHelpmeChoosePlan(zipCodeInfo.helpmeChoosePlan));
            // Save Progress Info
            if (!isProgressResume) {
                dispatch(addProgressInfo(generalInfo));
            }
            dispatch(updateProgressInfo({progressPath: HOME}));
            // Collect Progress Info
            let peopleData = getPeopleData();           
            let progressPayload = getProgressPayload(peopleData);           
            dispatch(collectProgressInfo(progressPayload));

            navigate(isMOT ? MEMBER_PROFILE : GET_QUOTE, { state: { productSegment: PRODUCT_SEGMENT_HEALTH } });
        }
    }

    const handleAction = (key, value) => {
        let selectedProduct = "";
        if (key === "dental") {
            selectedProduct = DENTAL;
            navigate(GET_QUOTE, { state: selectedProduct });
        } else if (key === "Health") {
            selectedProduct = HEALTH;
            navigate(GET_QUOTE, { state: selectedProduct });
        }
    }

    const handleHelpmeChooseSubmit = () => {
        if(isValidInfo()) {
            helpMeChoosePlanGTM();
            zipCodeInfo.helpmeChoosePlan = true
            dispatch(saveZipcode(zipCodeInfo.zipcode));
            dispatch(saveFipsCode(zipCodeInfo.fipcode));
            dispatch(saveCounty(zipCodeInfo.county));
            dispatch(saveHelpmeChoosePlan(zipCodeInfo.helpmeChoosePlan));
            // Save Progress Info
            if (!isProgressResume) {
                dispatch(addProgressInfo(generalInfo));
            }
            dispatch(updateProgressInfo({progressPath: HOME}));
            // Collect Progress Info
            let peopleData = getPeopleData();
            let progressPayload = getProgressPayload(peopleData);
            dispatch(collectProgressInfo(progressPayload));

            navigate(isMOT ? MEMBER_PROFILE : GET_QUOTE);
        }
    }

    const getPeopleData = () => {
        let consentFB = (generalInfo?.consentFB && generalInfo?.consentFB[0]) ? generalInfo?.consentFB[0] : null;
        let consentFCL = (generalInfo?.consentFCL && generalInfo?.consentFCL[0]) ? generalInfo?.consentFCL[0] : null;
        let consentText = (generalInfo?.consentText && generalInfo?.consentText[0]) ? generalInfo?.consentText[0] : null;

        let data = {
            ...peopleReducer,
            zipcode: zipCodeInfo.zipcode,
            fipscode: zipCodeInfo.fipcode,
            county: zipCodeInfo.county,
            quoteHouseholdSize: 0,
            applicants: [],
            houseHoldIncome: "",
            aptcAmount: "",
            isOnExchange: false,
            selectedPlan: [],
            ...(!isProgressResume && {firstName: generalInfo.firstName}),
            ...(!isProgressResume && {lastName: generalInfo.lastName}),
            ...(!isProgressResume && {email: generalInfo.email}),
            ...(!isProgressResume && {phone: generalInfo.phone}),
            ...(!isProgressResume && {consentFB: consentFB}),
            ...(!isProgressResume && {consentFCL: consentFCL}),
            ...(!isProgressResume && {consentText: consentText}),
            progressPath: HOME,
            progressProduct: ""
        };

        return data;
    };

    const getApiSuggestions = async (value) => {
        const url = `products/plans/servicezipcodes?search=` + value;
        const response = await axios.get(url);
        let zipCodeArr = response.data.serviceZipCodes;
        return zipCodeArr
    };

    const getZipcodeChoices = (value) => {
        if (Env.ISPRODUCTION) {
            getApiSuggestions(value)
                .then(zipcodes => {
                    let zipCodeChoices = [];
                    zipcodes.forEach((zipcode) => {
                        let zipCounty = zipcode.zipCode + ' - ' + zipcode.countyName;
                        zipCodeChoices.push(zipCounty)
                    });
                    setChoices(zipCodeChoices);
                }).catch(err => {
                    console.log("error getting zipcode from service: " + err);
                    setChoices([]);
                });
        } else {
            let zipCodeChoices = [];
            zipCodeMock.serviceZipCodes.forEach((zipcode) => {
                let zipCounty = zipcode.zipCode + ' - ' + zipcode.countyName;
                zipCodeChoices.push(zipCounty)
            });
            setChoices(zipCodeChoices);
        }
    }

    const saveZipcodeInfo = (zipcode, county) => {
        if (Env.ISPRODUCTION) {
            getApiSuggestions(zipcode)
                .then(zipcodes => {
                    populateZipCodeInfo(zipcode, county, zipcodes);
                }).catch(err => {
                    console.log("error getting zipcode from service: " + err);
                    setChoices([]);
                });
        } else {
            populateZipCodeInfo(zipcode, county, zipCodeMock.serviceZipCodes);
        }
    }

    const populateZipCodeInfo = (zipcode, county, data) => {
        for (let i = 0; i <= data.length; i++) {
            let zipOjb = data[i];
            if (zipOjb.zipCode == zipcode && zipOjb.countyName == county) {
                zipCodeObj.zipcode = zipOjb.zipCode;
                zipCodeObj.county = zipOjb.countyName;
                zipCodeObj.fipcode = zipOjb.countyCode;
                zipCodeObj.ratingAreaCode = zipOjb.ratingAreaCode;
                setZipCodeInfo(zipCodeObj);
                break;
            }
        }
    }

    const updateZipCodeInfo = (key, value) => {
        if (value === "" || value === null || value.length < 3) {
            setChoices([]);
            setZipCodeInfo(zipCodeObj);
            return;
        }
        if (value.indexOf(' - ') > -1) {
            let valArr = value.split(' - ');
            let zip = valArr[0]
            let county = valArr[1];
            if (zip != zipCodeObj.zipcode && county != zipCodeObj.county) {
                saveZipcodeInfo(zip, county);
            }
        } else {
            getZipcodeChoices(value);
        }
    }

    return (
        <Fragment>
            <div className="zipcode-form">
                <div className="row">
                    <div className='columns small-12 medium-12 large-12'>
                        <UIComboBox2 
                            name='zipcode'
                            autoComplete='postal-code'
                            title={t('heroSection.zipCode')}
                            label={t('heroSection.zipCode')}
                            labelClassName={""}
                            labelOutside={false}
                            choices={choices}
                            onValidatedChange={updateZipCodeInfo}
                            reportEveryChange={true}
                            errorMessage={errorMsgZip}
                        />
                    </div>
                </div>
                {!isProgressResume && !isAqt &&
                    <>
                        <div className="row">
                            <div className="columns small-12 medium-12 large-12">
                                <UIInputText 
                                    name='email'
                                    autoComplete='email'
                                    label={t('heroSection.emailAddress')}
                                    onValidatedChange={handleGeneralInfo}
                                    labelOutside={false}
                                    labelClassName={""}
                                    required={false}
                                    minLength={5}
                                    maxLength={200}
                                    errorMessage={emailError} />
                            </div>
                        </div>
                        {showFirstLastNameForm &&
                        <div className="row">
                            <div className="columns small-12 medium-12 large-6">
                                <UIInputText 
                                    name='firstName'
                                    autoComplete='given-name'
                                    label={t('heroSection.firstName')}
                                    onValidatedChange={handleGeneralInfo}
                                    labelOutside={false}
                                    labelClassName={""}
                                    required={false}
                                    maxLength={50}
                                    errorMessage={firstNameError} />

                            </div>
                            <div className="columns small-12 medium-12 large-6">
                                <UIInputText 
                                    name='lastName'
                                    autoComplete='family-name'
                                    label={t('heroSection.lastName')}
                                    onValidatedChange={handleGeneralInfo}
                                    labelOutside={false}
                                    labelClassName={""}
                                    required={false}
                                    maxLength={50}
                                    errorMessage={lastNameError} />
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="columns small-12 medium-12 large-12">
                                <p className="smaller-2x"><Markup content={t('heroSection.consentDisclaimer')} /></p>
                            </div>
                        </div>
                    </>
                }
                <div className="row small-text-center">
                    <div className="small-12 columns">
                        <button ref={submitContinueBtnRef} onMouseOver={handleContinueMouseEvent} onClick={handleSubmit} className="primary inline-block">{t('heroSection.continueButton')}</button>
                        <span className="or-text left-1x right-1x">{t('heroSection.orSeperator')}</span>
                        <button ref={submitHelpMeChooseBtnRef} onMouseOver={handleHelpMeChooseMouseEvent} onClick={handleHelpmeChooseSubmit} className="secondary inline-block">{t('heroSection.helpButton')}</button>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default LandingZipCode;
