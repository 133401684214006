export const ADD_PROVIDER = "ADD_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const ADD_DENTAL_PROVIDER = "ADD_DENTAL_PROVIDER";
export const DELETE_DENTAL_PROVIDER = "DELETE_DENTAL_PROVIDER";
export const RESET_PROVIDER_SEARCH_REDUCER = 'RESET_PROVIDER_SEARCH_REDUCER';

export const SAVE_PREV_PAGE_DATA = "SAVE_PREV_PAGE_DATA";
export const DELETE_PREV_PAGE_DATA = "DELETE_PREV_PAGE_DATA";
export const SAVE_PROVIDER_SEARCH_REDUCER_DATA = "SAVE_PROVIDER_SEARCH_REDUCER_DATA";

export const addProvider = (data) => ({
    type: ADD_PROVIDER,
    payload: data
})

export const deleteProvider = (index) => ({
    type: DELETE_PROVIDER,
    payload: index
})

export const addDentalProvider = (data) => ({
    type: ADD_DENTAL_PROVIDER,
    payload: data
})

export const deleteDentalProvider = (index) => ({
    type: DELETE_DENTAL_PROVIDER,
    payload: index
})

export const resetProviderSearchReducer = () => ({
    type: RESET_PROVIDER_SEARCH_REDUCER
})

export const saveProviderSearchPrevPageData = (data) => ({
    type: SAVE_PREV_PAGE_DATA,
    data
})

export const deleteProviderSearchPrevPageData = () => ({
    type: DELETE_PREV_PAGE_DATA,
})

export const saveProviderSearchReducerData = (data) => ({
    type: SAVE_PROVIDER_SEARCH_REDUCER_DATA,
    payload: data
})