import { APPLICANT, SPOUSE, DOMESTIC_PARTNER, DEPENDENT_CHILD } from "constants/ApplicantDemographicConstants";
import { isEmpty } from "utils/commonUtils";

export const getCurrentDate = (sysDate, oepSepTest) => {
    
    let currentDate = new Date();
    
    const IS_NON_PROD = Array.from(new Set([
        "localhost",
        "testa",
        "stga"
    ])).map(env => location.href.includes(env)).find(env => env);

    if(!IS_NON_PROD) {
        return currentDate;
    }
    
    if (oepSepTest && sysDate && !isEmpty(sysDate.trim())) {
        currentDate = new Date(sysDate);
    }

    return currentDate;
}

export const isSEP = (startEnd, endEnd, sysDate, oepSepTest) => {
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if ((currentDate >= new Date(endEnd) || currentDate <= new Date(startEnd))) {
        return true;
    }
    return false;
}

export const isOEP = (startEnd, endEnd, sysDate, oepSepTest) => {
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if ((currentDate <= new Date(endEnd) && currentDate >= new Date(startEnd))) {
        return true;
    }
    return false;
}

export const isOEPStartIn45Days = (startDate, sysDate, oepSepTest) => {
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if (calculateNumOfDays(currentDate, startDate) <= 45) {
        return true;
    }
    return false;
}

export const isOEPStartInOneDay = (startDate, sysDate, oepSepTest) => {
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if (calculateNumOfDays(currentDate, startDate) == 1) {
        return true;
    }
    return false;
}

export const isOEPEndIn15Days = (endDate, sysDate, oepSepTest) => {
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if (calculateNumOfDays(currentDate, endDate) <= 15) {
        return true;
    }
    return false;
}

export const isOEPEndInOneDay = (endDate, sysDate, oepSepTest) => {
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if (calculateNumOfDays(currentDate, endDate) == 1) {
        return true;
    }
    return false;
}

export const getEffectiveDate = (sysDate, oepSepTest) => {
    let currentDate = new Date();
    let effDate = '';
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    var day = currentDate.getDate();
    var month = currentDate.getMonth() + 1;
    if (day <= 15) {
        effDate = new Date(currentDate.getFullYear(), month, 1);
    } else {
        effDate = new Date(currentDate.getFullYear(), month + 1, 1);
    }
    return effDate;
}

export const isPrebill = (startEnd, endEnd, sysDate, oepSepTest) => {
    console.log("isPrebill", startEnd, endEnd, sysDate, oepSepTest);
    let currentDate = new Date();
    if (oepSepTest) {
        currentDate = new Date(sysDate);
    }
    if ((currentDate <= new Date(endEnd) && currentDate >= new Date(startEnd))) {
        return true;
    }
    return false;
}

/**
 * This method is used to determine number of days between given dates, return type is Number
 * 
 * @param {Date} date1 
 * @param {Date} date2
 * @returns {Number}
 */
 export const calculateNumOfDays = (date1, date2) => {

    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);

}

export const getUSAblePlanEligibility = (applicants = []) => {
    
    let isUSAblePlanEligible = true;
    let isUSAblePlanApplicantEligible = true;
    let isUSAblePlanDepChildEligible = true;

    applicants.forEach((applicant) => {
        if (applicant.relationship === APPLICANT || applicant.relationship === SPOUSE || applicant.relationship === DOMESTIC_PARTNER) {
            if (applicant.age < 19 || applicant.age > 64) {
                isUSAblePlanEligible = false;
                isUSAblePlanApplicantEligible = false;
            }
        } else if (applicant.relationship === DEPENDENT_CHILD) {
            if (applicant.age >= 23) {
                isUSAblePlanEligible = false;
                isUSAblePlanDepChildEligible = false;
            }
        }
    })

    return [isUSAblePlanEligible, isUSAblePlanApplicantEligible, isUSAblePlanDepChildEligible];
}