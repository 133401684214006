import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Login from "./Login";
import * as Constants from "constants/Constants";

const ProtectedRoutes = () => {

  const isAuthenticated = useSelector((state) => state.AuthReducer.isAuthenticated);
  const isAuthDataFetchCompleted = useSelector((state) => state.AuthReducer.authDataFetchCompleted);

  const renderLoader = () => {
    return (
      <div className="row padding-3x">
        <div className="columns large-12 medium-12 small-12">
          <div className="cover" aria-live="polite">
            <div className="loader"></div>
          </div>
        </div>
        <div className="columns large-12 medium-12 small-12">
          <h4 className="top-2x larger-3x text-center">Loading...</h4>
        </div>
      </div>
    )
  }

  return (
    <>
      {(isAuthDataFetchCompleted && isAuthenticated) ? <Outlet /> : <Login />}
      {!isAuthDataFetchCompleted && renderLoader()}
    </>
  )
}

export default ProtectedRoutes;