export const SAVE_ACC_DATA = "SAVE_ACC_DATA";
export const RESET_ACC_DATA = "RESET_ACC_DATA";
export const SET_DISABLE_PRODUCTS = "SET_DISABLE_PRODUCTS";
export const SET_SELLABLE_PRODUCTS = "SET_SELLABLE_PRODUCTS";
export const SET_RECOMMENDED_PLANS = "SET_RECOMMENDED_PLANS";

export const saveAccData = (data) => ({
    type: SAVE_ACC_DATA,
    payload: data
})

export const setDisableProducts = (data) => ({
    type: SET_DISABLE_PRODUCTS,
    payload: data
})

export const setSellableProducts = (data) => ({
    type: SET_SELLABLE_PRODUCTS,
    payload: data
})

export const setRecommendedPlans = (data) => ({
    type: SET_RECOMMENDED_PLANS,
    payload: data
})

export const resetAccData = () => ({
    type: RESET_ACC_DATA
})


