import axios from 'axios';
import * as Env from "../constants/Environment";

export const GET_USER_DATA_INIT = 'GET_USER_DATA_INIT';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';
export const SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS';
export const SAVE_USER_DATA_FAILURE = 'SAVE_USER_DATA_FAILURE';

export const saveUserDataEvent = (consumerApplyIssSaveUrl, payload) => {
    return async (dispatch) => {
      try {
        let data = {};
        if (Env.ISPRODUCTION) {
          let response = {};
          response = await axios.post(consumerApplyIssSaveUrl, payload, {withCredentials: true});
          data = response.data;
        }
        dispatch(saveUserDataEventSuccess(data));
      } catch (error) {
        console.log("saveUserDataEventFailure error:", error);
        dispatch(saveUserDataEventFailure(error));
      }
    };
  };
  
export const saveUserDataEventSuccess = (data) => {
    return {
        type: SAVE_USER_DATA_SUCCESS,
        data,
    };
};

export const saveUserDataEventFailure = (data) => ({
    type: SAVE_USER_DATA_FAILURE,
    data,
});