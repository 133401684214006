import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';

import { UIModal, ALERTS, UIAlert, LAYOUTS, UISelectField } from "digital-shared-ui";

import * as Paths from "constants/Routes";
import * as Constants from "constants/Constants";
import { saveNonHealthApplicant } from "../../actions/OnExchangeAction";
import { formatDate } from "utils/commonUtils";
import { calculateAge } from "utils/shoppingUtils";
import { saveApplicants, saveHouseholdSize } from "actions/PeopleAction";
import { MEG_RELATIONSHIP_MAP } from "constants/ApplicantDemographicConstants";


function NonHealthApplicantModal({ nhApplicantModalOpen, toggleNHApplicantModal, nhApplicantRedirectUrl }) {
    
    const { t } = useTranslation('Cart');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const products = useSelector((state) => state.InitDataReducer.initData.products);
    const enrGroupsData = useSelector(state => state.OnExchangeReducer[Constants.ENR_GRPS_DATA]);
    const nonHealthApplicant = useSelector((state) => state.OnExchangeReducer.nonHealthApplicant);
    const onExCartData = useSelector((state) => state.CartReducer[Constants.ONEX_CART_ITEMS]) || [];
    const enrGroupsMembersList = enrGroupsData.map(grp => grp.consumers);

    const [hideContinueButton, setHideContinueButton] = useState(true);
    const [relationshipError, setRelationshipError] = useState('');
    const [allApplicantName, setAllApplicantName] = useState([]);
    const [selectedApplicantId, setSelectedApplicantId] = useState(nonHealthApplicant);

    useEffect(() => {
        if (nhApplicantModalOpen) {
            getApplicants();
        } else {
            setHideContinueButton(true)
            setRelationshipError('')
        }        
    }, [nhApplicantModalOpen])

    const updateSingleEnrGrpDataToPeopleReducer = () => {
        let updatedApplicants = [];

        for (const cnsr of enrGroupsMembersList.flat()) {

            let updatedApplicant = {};
            let formatedDOB = formatDate(cnsr.dob, "YYYY-MM-DD");

            updatedApplicant.id = cnsr.id;
            updatedApplicant.DOB = formatedDOB;
            updatedApplicant.relationship = cnsr.id === selectedApplicantId ? "applicant" : MEG_RELATIONSHIP_MAP[cnsr.singleGrpRelationship];

            if (cnsr.gender.toUpperCase() === "F")
                updatedApplicant.gender = "FEMALE"
            else if (cnsr.gender.toUpperCase() === "M")
                updatedApplicant.gender = "MALE"

            updatedApplicant.zipCode = cnsr.zipCode;
            updatedApplicant.countyName = cnsr.county;
            updatedApplicant.tobaccoUse = cnsr.tobaccoUse;
            updatedApplicant.aptcAmount = cnsr.monthlyAPTCAmount;
            updatedApplicant.groupNum = cnsr.groupNum;
            updatedApplicant.age = calculateAge(formatedDOB);

            updatedApplicants.push(updatedApplicant);
        }

        dispatch(saveApplicants(updatedApplicants));
        dispatch(saveHouseholdSize(updatedApplicants?.length));
    }

    const selectNonHealthApplicant = () => {
        updateSingleEnrGrpDataToPeopleReducer();
        dispatch(saveNonHealthApplicant(selectedApplicantId));
        navigate(nhApplicantRedirectUrl);
    }
    
    const sortEnrGrps = (a, b) => {
        return parseInt(a.enrollmentGroupId) - parseInt(b.enrollmentGroupId);
    }

    const getApplicants = () => {
        let applicantName = [];
        //if (isOnExchange && isMultiEnrollmentGroupApplication) {
            applicantName.push({label: 'Select One', value: 'Select One'})
            enrGroupsData.length > 0 && enrGroupsData.sort(sortEnrGrps).forEach(enrGrp => {
                enrGrp['consumers'].filter(obj => {
                    applicantName.push({label: obj.firstName +' '+ obj.lastName, value: obj.id + '-' + obj?.singleGrpRelationship})
                })
            })
        //}
        setAllApplicantName(applicantName)
    }

    const primaryApplicantCheck = (key, value) => {
        let error_relationship = ''
        setHideContinueButton(true)
        if (value === 'Select One') {
            error_relationship = 'Please select a primary applicant to continue'
        } else {
            let valArr = value.split('-');
            let relationship = valArr[1]
            if (!relationship || (relationship === 'Dependent')) {
                error_relationship = 'You cannot add a dependent as a primary applicant. Select a different person as the primary applicant and then proceed to the prospects page.'
            } else {
                error_relationship = ''
                setSelectedApplicantId(valArr[0]);
            }
        }
        if (error_relationship === '') {
            setHideContinueButton(false)
        }
        setRelationshipError(error_relationship)
    }   

    return (
        <>        
            <UIModal
                isOpen={nhApplicantModalOpen}
                onExit={toggleNHApplicantModal}
                aria={{ labelledby: 'cancel-keep-shopping-modal', describedby: "cancel-keep-shopping-modal" }}
                className="modal-container"
            >
                <button
                    aria-label="close dialog"
                    title="close dialog"
                    className="close"
                    onClick={toggleNHApplicantModal}
                />
                <div className="row head collapse">
                    {false ?
                        <div className="small-12 columns">
                            <UIAlert
                                alertType={ALERTS.WARNING}
                                ariaLabel="invalid primary applicant error"
                                icon={true} className={"alert warning top-1x"}
                            >
                                <span>{t('invalidPrimaryApplicantErr')}</span>
                            </UIAlert>
                        </div>
                        :
                        null
                    }
                    <div className="small-12 columns">
                        <h3 id="modal-title" className="hl-large">
                            {t('nonHealthPlanTitle')}
                        </h3>
                    </div>
                </div>
                <div className="row body">
                    <div className="row left-0x">
                        <p>{t('selectNonHealthPlanInfo')}</p>
                    </div>
                    <div className='row'>
                        <UISelectField
                            name='non-health-primary-applicant-selection'
                            layout={LAYOUTS.FLOAT6}
                            onValidatedChange={primaryApplicantCheck}
                            choices={allApplicantName}
                            errorMessage={relationshipError}
                        />
                    </div>
                </div>
                <div className="row footer">
                    <div className="columns small-12 large-6 medium-6 text-left">
                        {relationshipError === '' && !hideContinueButton &&
                            <button onClick={selectNonHealthApplicant}>
                                {t('continue')}
                            </button>
                        }
                    </div>
                    <div className="columns small-12 large-6 medium-6 text-right">
                        <button onClick={toggleNHApplicantModal}>
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            </UIModal>
        </>
    );

}
export default NonHealthApplicantModal;
