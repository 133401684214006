import * as Paths from "constants/Routes";
import { useAxiosHandler } from "hooks/useAxiosHandler";

const axios = useAxiosHandler();

export const watchdogRouteLogger = (location, zipcode, userId, cipId, ffmAppId, sseAppId) => {
    console.log('Fire watchdogRouteLogger...');
    let payload = {
        clientTimestamp: new Date().toISOString(),
        url: location.pathname,
        zip: zipcode,
        userId: userId,
        cipId: cipId,
        ffmAppId: ffmAppId,
        sseAppId: sseAppId
    }
    axios.post('watchdog', payload);
}

export const updateHeadBasedOnPathToRoutes = (location) => {
    console.log('Fire updateHeadBasedOnPathToRoutes...');
    let documentDescription = document.querySelector("meta[name='description']");
    switch (true) {
        case location.pathname === Paths.HOME: {
            document.title = "Shop Individual & Family Health Insurance Plans | Florida Blue";
            documentDescription.content = "Shop for health insurance plans for you and your family. Compare comprehensive details on different options, ensuring you make the best choice for your health.";
            break;
        }
        case location.pathname.includes(Paths.GET_QUOTE): {
            document.title = "Get Health Insurance Quote | Florida Blue";
            documentDescription.content = "Get a health insurance quote in minutes! Compare plans and find the best coverage for your needs.";
            break;
        }
        case location.pathname === Paths.CART: {
            document.title = "Shopping Cart | Florida Blue";
            documentDescription.content = "Complete your health insurance purchase online. Review your plan details, update your cart, and checkout securely.";
            break;
        }
        case location.pathname === Paths.HELP: {
            document.title = "Customer Support | Florida Blue";
            documentDescription.content = "Need assistance with your health insurance? Our customer support team is here to help. Find answers, identify plans, and get support online or by phone.";
            break;
        }
        case location.pathname === Paths.HEALTH_CARE_101: {
            document.title = "Health Care 101 | Florida Blue";
            documentDescription.content = "Health care doesn't have to be confusing. Our Health Care 101 guide breaks down the basics of health insurance in an easy-to-understand format.";
            break;
        }
        case location.pathname.includes(Paths.SUBSIDY): {
            document.title = "Estimate Your Tax Credit | Florida Blue";
            documentDescription.content = "Discover how much you can save on health insurance with a tax credit. Use our estimator to get an instant quote and explore your options.";
            break;
        }
        case location.pathname === Paths.PLANS_ON: {
            document.title = "View Health Insurance Plans - ON Exchange | Florida Blue";
            documentDescription.content = "View and compare health insurance plans on the exchange. Our online marketplace makes it easy to find and enroll in a plan that's right for you.";
            break;
        }
        case location.pathname === Paths.PLANS: {
            document.title = "View and Compare Health Insurance Plans | Florida Blue";
            documentDescription.content = "View and compare health insurance plans side-by-side. View plan details, prices, and benefits to find the best fit for you and your family.";
            break;
        }
        case location.pathname === Paths.PLAN_DETAILS: {
            document.title = "Health Insurance Plan Details | Florida Blue";
            documentDescription.content = "Get detailed information about our health insurance plans. View plan benefits, coverage, and pricing to make an informed decision.";
            break;
        }
        case location.pathname === Paths.PLANS_COMPARE: {
            document.title = "Compare Health Insurance Plans | Florida Blue";
            documentDescription.content = "Compare health insurance plans and find the one that's right for you. Our online tool makes it easy to view and compare plan details, prices, and benefits.";
            break;
        }
        case location.pathname === Paths.SDENTAL: {
            document.title = "Find Dental Insurance Plans | Florida Blue";
            documentDescription.content = "Find the right dental insurance plan for you and your family. Our plans cover a range of services, from preventive care to major procedures.";
            break;
        }
        case location.pathname === Paths.ACCIDENT: {
            document.title = "Find Accident Insurance Plans | Florida Blue";
            documentDescription.content = "Accident insurance plans that provide financial protection in case of an unexpected event. Get coverage for medical expenses, lost wages, and more.";
            break;
        }
        case location.pathname === Paths.CRITICAL_ILLNESS: {
            document.title = "Find Critical Illness Plans | Florida Blue";
            documentDescription.content = "Critical illness insurance plans that provide protection in case of a serious illness. Get coverage for cancer, heart attack, stroke, and more.";
            break;
        }
        case location.pathname === Paths.HOSPITAL: {
            document.title = "Find Hospital Plans | Florida Blue";
            documentDescription.content = "Get protected from unexpected hospital bills with our hospital plans. Our plans provide coverage for hospital stays, surgeries, and more.";
            break;
        }
        case location.pathname === Paths.INTERNATIONAL: {
            document.title = "Find International Travel Medical Plans | Florida Blue";
            documentDescription.content = "Stay safe and healthy while traveling abroad with our international travel medical plans designed to meet the unique needs of travelers.";
            break;
        }
        case location.pathname === Paths.OTHER_PRODUCTS: {
            document.title = "Find Other Products | Florida Blue";
            documentDescription.content = "Explore our range of additional insurance products. Get the coverage you need to protect your financial well-being.";
            break;
        }
        case location.pathname.includes(Paths.MULTI_ENROLLMENT_GROUPS): {
            document.title = "Premium Tax Credit Determination | Florida Blue";
            documentDescription.content = "Discover how to reduce your health insurance costs with premium tax credits. Our guide explains the benefits, eligibility, and application process.";
            break;
        }
        case location.pathname.includes("provider"): {
            document.title = "Are your providers in our network? | Florida Blue";
            documentDescription.content = "Find out if your healthcare providers are part of our network. Our network directory allows you to search for in-network doctors, hospitals, and specialists.";
            break;
        }
        case location.pathname.includes(Paths.CALL_YOU_BACK): {
            document.title = "Call You Back | Florida Blue";
            documentDescription.content = "Request a call back from our team of insurance professionals. We'll get in touch with you to discuss your insurance needs and provide a personalized quote.";
            break;
        }
        case location.pathname.includes(Paths.ACCOUNT): {
            document.title = "My Account | Florida Blue";
            documentDescription.content = "Log in to your account to access your insurance plan information in one convenient and secure location.";
            break;
        }
        default: {
            document.title = "Florida Blue";
            documentDescription.content = "";
        }
    }
}

export const getValidResumeProgressPath = (path) => {
    switch (true) {
        case path === Paths.HOME: {
            break;
        }
        case path.includes(Paths.GET_QUOTE): {
            break;
        }
        case path.includes(Paths.SUBSIDY): {
            break;
        }
        case path === Paths.PLANS: {
            break;
        }
        case path === Paths.PLANS_ON: {
            break;
        }
        case path === Paths.PLAN_DETAILS: {
            path = Paths.GET_QUOTE;
            break;
        }
        case path === Paths.PLANS_COMPARE: {
            path = Paths.GET_QUOTE;
            break;
        }
        case path === Paths.CART: {
            break;
        }
        default: {
            path = Paths.HOME;
        }
    }
    return path;
}

export const isAllowedSaveProgressPath = (path) => {
    const allowedSaveProgressPaths = [
        Paths.GET_QUOTE,
        Paths.SUBSIDY,
        Paths.PLANS,
        Paths.PLANS_ON,
        Paths.PLAN_DETAILS,
        Paths.PLANS_COMPARE,
        Paths.CART
    ]
    return allowedSaveProgressPaths.find((route) => path.includes(route))
}