import * as ActionConstants from "actions/MotAction";
import * as RecommendedPlanAction from "../actions/motRecommendAction";
import * as Constants from "constants/Constants";

const initialState = {
  memberData: {},
  memberSet: []
};

const MotReducer = (state = initialState, action) => {

  switch (action.type) {

    case ActionConstants.GET_MEMBER_DATA_INIT:
      return {
        ...state,
        [Constants.MEMBER_DATA_FETCHING]: true,
      }

    case ActionConstants.GET_MEMBER_DATA_SUCCESS:
      let memberData = action.data || {};
      return {
        ...state,
        [Constants.MEMBER_DATA_FETCHING]: false,
        [Constants.MEMBER_DATA_ERROR]: false,
        [Constants.MEMBER_DATA]: memberData
      }

    case ActionConstants.GET_MEMBER_DATA_FAILURE:
      return {
        ...state,
        [Constants.MEMBER_DATA_FETCHING]: false,
        [Constants.MEMBER_DATA]: {},
        [Constants.MEMBER_DATA_ERROR]: true,
      }

    case ActionConstants.RESET_MEMBER_DATA:
      return {
        ...state,
        [Constants.MEMBER_DATA]: []
      }

    case ActionConstants.SAVE_MEMBER_SET_DATA:
      return {
        ...state,
        [Constants.MEMBER_SET]: action?.data || [],
      };

    case ActionConstants.RESET_MEMBER_SET_DATA:
      return {
        ...state,
        [Constants.MEMBER_SET]: [],
      };
      case RecommendedPlanAction.GET_MEMBER_RECOMMENDED_PLAN_DATA_INIT:
        return {
          ...state,
          [Constants.RECOMMENDED_PLAN_FETCHING]: true,
        }
      case RecommendedPlanAction.GET_MEMBER_RECOMMENDED_PLAN_DATA_SUCCESS:
        let recommendedPlan = action.data || {};
        return {
          ...state,
          [Constants.RECOMMENDED_PLAN_FETCHING]: false,
          [Constants.RECOMMENDED_PLAN_ERROR]: false,
          [Constants.RECOMMENDED_PLAN_DATA]: recommendedPlan
        }
      case RecommendedPlanAction.SAVE_CURRENT_PLAN_NAME:
        return {
          ...state,
          [Constants.CURRENT_PLAN_NAME]: action?.data || "",
        };
    
    default:
      return state;
  }
}


export default MotReducer;
