import { RESET_ACC_DATA, SAVE_ACC_DATA, SET_DISABLE_PRODUCTS, SET_SELLABLE_PRODUCTS, SET_RECOMMENDED_PLANS } from '../actions/AccAction';
const initialState = {
    response: {},
    disableProducts: [],
    sellableProducts: [],
    isAcc: false,
    recommendedPlans: []
}

const AccReducer = (state = initialState, action) => {

    switch (action.type) {
        case SAVE_ACC_DATA:
            return {
                ...state,
                response: action.payload,
                isAcc: action.payload ? true : false
            }

        case SET_DISABLE_PRODUCTS:
            return {
                ...state,
                disableProducts: action.payload
            }

        case SET_SELLABLE_PRODUCTS:
            return {
                ...state,
                sellableProducts: action.payload
            }

        case SET_RECOMMENDED_PLANS:
            return {
                ...state,
                recommendedPlans: action.payload
            }

        case RESET_ACC_DATA:
            return initialState;

        default:
            return state;
    }
}

export default AccReducer;