import axios from 'axios';
import * as Env from "../constants/Environment";

export const RECORD_PROGRESS_COLLECTION_SUCCESS = "RECORD_PROGRESS_COLLECTION_SUCCESS";
export const RECORD_PROGRESS_COLLECTION_FAILURE = "RECORD_PROGRESS_COLLECTION_FAILURE";

export const collectProgressInfo = (payload) => {
  return async (dispatch) => {
    try {
      let data = {};
      // this will limit the amount of data collected
      if (payload.PeopleReducer?.progressSave || payload.PeopleReducer?.progressCallBack || payload.PeopleReducer?.progressResume || payload.PeopleReducer?.email || payload.PeopleReducer?.phone) {
        const url = `/api/services/consumer/data/collect`;
        if (Env.ISPRODUCTION) {
          let response = {};
          response = await axios.post(url, payload);
          data = response.data;
        }
        console.log("Progress Data Collected");
      } else {
        console.log("Progress Data NOT Collected");
      }
      dispatch(recordProgressCollectionSuccess(data));
    } catch (error) {
      console.log("Progress Collection Error: ", error);
      dispatch(recordProgressCollectionFailure(error));
    }
  };
};
  
export const recordProgressCollectionSuccess = (data) => {
    return {
        type: RECORD_PROGRESS_COLLECTION_SUCCESS,
        data,
    };
};

export const recordProgressCollectionFailure = (data) => ({
    type: RECORD_PROGRESS_COLLECTION_FAILURE,
    data,
});
