import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import { Markup } from "interweave";

import { UIAccessibilityMenu, UILanguageSwitcher, UIAccordion2, UITabSwitcher, UIMenu, UIDropDown, UIDropDownButton, UIDisclosure, UISkipLinks, ddState, UIModal, UIInputField, UIAlert, LAYOUTS } from "digital-shared-ui";
import { UIInputText, UIInputNumber } from 'ui-forms';

import mockInitData from '../../../data/initData.json';
import * as Env from 'constants/Environment';
import * as RoutePaths from "constants/Routes";
import * as Constants from "constants/Constants";
import { useShoppingContext } from 'contexts/ShoppingContext';
import CallYouBack from '../CallYouBack/CallYouBack';
import { resetPlanRatesReducer, setReloadPlanData } from '../../actions/PlanRatesAction';
import { resetViewBenefitsReducer } from '../../actions/ViewsBenfitsAction';
import { isEmpty, getDateStr, formatDate, formatPhoneNumber, parseJson, isValidEmail } from '../../utils/commonUtils';
import { updateHeadBasedOnPathToRoutes, watchdogRouteLogger, getValidResumeProgressPath, isAllowedSaveProgressPath } from "utils/routeUtils";
import { saveFromMenu, addProgressInfo, updateProgressInfo, savePeopleReducerData } from '../../actions/PeopleAction';
import NonHealthApplicantModal from '../Cart/NonHealthApplicantModal';
import { icmRecordEvent } from "../../utils/icmEventRecord";
import * as Actions from "../../actions/IcmRecordEventAction";
import { cartGTM, startChatGTM } from '../../utils/analyticsUtils';
import * as PlanUtils from "utils/planUtils";
import { saveMOTData, resetMOTFlag } from "actions/AuthAction";
import { Buffer } from 'buffer';
import { saveProviderSearchReducerData } from '../../actions/ProviderSearchAction';
import { getProgressPayload } from "utils/progressUtils";
import { collectProgressInfo } from "actions/ProgressCollectionAction";
import ProgressCallYouBack from '../Progress/ProgressCallYouBack';
import SaveProgressPopup from "../Progress/SaveProgressPopup";

function HeaderLegacy() {

    let { pathname } = useLocation();

    const [searchParams] = useSearchParams();
    const sysDate = useSelector((state) => state.InitDataReducer?.initData?.sysDate);
    const oepSepTest = useSelector((state) => state.InitDataReducer?.initData?.oepSepTest);
    const authData = useSelector((state) => state.AuthReducer);
    const isMOT = useSelector((state) => state.AuthReducer?.isMOT);
    const memberAccountPrebillUrl = useSelector((state) => state.InitDataReducer.initData.urls?.memberAccountPrebillUrl || "https://gwprofile.bcbsfl.com/profile/interstitial/?reason=PREBILL");
    const isAuthenticated = useSelector((state) => state.AuthReducer.isAuthenticated);
    const isAuthDataFetchCompleted = useSelector((state) => state.AuthReducer.authDataFetchCompleted);
    const products = useSelector((state) => state.InitDataReducer?.initData?.products);
    const planRatesObj = useSelector((state) => state.PlanRatesReducer);
    const quoteData = useSelector((state) => state.PeopleReducer);
    const isPrebill = useSelector((state)=> state.PeopleReducer.isPrebill);
    const accData = useSelector((state) => state.AccReducer);
    const aqtData = useSelector((state) => state.AqtReducer);
    const cws_gwc_sso_url = useSelector((state) => state.InitDataReducer.initData.authentication?.sso_url);
    const cws_gwc_sso_consumer_logout_endpoint = useSelector((state) => state.InitDataReducer.initData.authentication?.consumer_logout_endpoint);
    const isAcc = useSelector((state) => state.AccReducer?.isAcc);
    const isAqt = useSelector((state) => state.AqtReducer?.isAqt);
    const zipcode = quoteData?.zipcode;
    const isOnExchange = quoteData?.isOnExchange;
    const applicants = quoteData?.applicants;
    const onExReducerData = useSelector((state) => state.OnExchangeReducer);
    const enrGroupsData = useSelector(state => state?.OnExchangeReducer[Constants.ENR_GRPS_DATA]);
    const onExCartData = useSelector((state) => state?.CartReducer[Constants.ONEX_CART_ITEMS]) || [];
    const planComparePreBillData = useSelector((state) => state.PlansCompareReducer?.prebillData?.isPrebill || false);
    const isMultiEnrollmentGroupApplication = enrGroupsData?.length > 0;

    const [nhApplicantModalOpen, setNHApplicantModalOpen] = useState(false);
    const [nhApplicantRedirectUrl, setNHApplicantRedirectUrl] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(['Layout', 'Acc', 'Aqt', 'MyAccount']);
    const [isActive, setActive] = useState(true);
    const [toLogout, setToLogout] = useState(false);
    const [ddIdNav, setDdIdNav] = useState("ddMobileNav");
    const [ddStateA, setDdStateA] = useState(ddState("ddMobileNav", false));
    const bddRefNav = React.createRef();

    const bddRefMyAccount = React.createRef();

    const cwsPathAccessUrl = ['/account/applications', '/account/overview', '/cart/save/true', '/cart/save/false/ajax', '/dst/profile', '/dst/verify']
    const [homePagePath, setHomePagePath] = useState(RoutePaths.HOME)
    const payload = icmRecordEvent("Chat Initiated");
    const campaignKeycode = useSelector((state) => state.PeopleReducer.keycode || "8339");
    const accountProfileUrl = useSelector((state) => state.InitDataReducer.initData.urls?.accountProfileUrl || {});

    const { isOnExOep, isOffExOep } = useShoppingContext();

    // Five9 Chat Variables
    const chatEnabled = useSelector((state) => state.InitDataReducer.initData.chat?.chatEnabled === "true" ? true : false);
    const callbackEnabled = useSelector((state) => state.InitDataReducer.initData.chat?.callbackEnabled === "true" ? true : false);
    const fnAppId = useSelector((state) => state.InitDataReducer.initData.chat?.five9AppId || "BlueCrossBlueShield of FL - Developer Program");
    const fnScriptUrl = useSelector((state) => state.InitDataReducer.initData.chat?.five9ScriptUrl || "https://live-chat.ps.five9.com/Five9ChatPlugin.js");
    const fnEngConfigId = useSelector((state) => state.InitDataReducer.initData.chat?.five9ConfigIdEnglish || "U65_English");
    const fnEsConfigId = useSelector((state) => state.InitDataReducer.initData.chat?.five9ConfigIdSpanish || "U65_Spanish");
    const fnEngNudgeId = useSelector((state) => state.InitDataReducer.initData.chat?.five9NudgeIdEnglish || "3beaf079-d8ad-46c7-ba39-8496a8a7609c");
    const fnEsNudgeId = useSelector((state) => state.InitDataReducer.initData.chat?.five9NudgeIdSpanish || "fda7315c-74a9-4256-8cff-8b09e0717d97");
    const consumerChannel = useSelector((state) => state.InitDataReducer.initData.chat?.five9ConsumerChannel || "CWS");

    // Page to title mapping for sending to Five9
    const pathToTitleMapping = {
        [RoutePaths.HOME]: "Home",
        [RoutePaths.GET_QUOTE]: "Get a Quote",
        [RoutePaths.SDENTAL]: "Dental Landing",
        [RoutePaths.ACCIDENT]: "USAble Life Accident Plans Landing",
        [RoutePaths.CRITICAL_ILLNESS]: "USAble Life Critical Illness Plans Landing",
        [RoutePaths.HOSPITAL]: "USAble Life Hospital Plans Landing",
        [RoutePaths.INTERNATIONAL]: "International Travel Medical Plans",
        [RoutePaths.HEALTH_CARE_101]: "Health Care 101",
        [RoutePaths.OTHER_PRODUCTS]: "Other Products",
        [RoutePaths.WHAT_TO_EXPECT]: "What to Expect",
        [RoutePaths.PLANS]: "Plan List",
        [RoutePaths.PLANS_ON]: "Plan List",
        [RoutePaths.PLANS_COMPARE]: "Plan Compare",
        [RoutePaths.PLAN_DETAILS]: "Plan Details",
        [RoutePaths.CART]: "Cart",
        [RoutePaths.HELP]: "Support",
        [RoutePaths.MWS_QHP_PREVIEW]: "Prebill Preview"
    }

    // Pages to display the proactive chat
    const proActiveChatPages = [
        //RoutePaths.HOME,
        RoutePaths.GET_QUOTE,
        RoutePaths.CART,
        RoutePaths.WHAT_TO_EXPECT,
        RoutePaths.PLANS,
        RoutePaths.PLANS_ON,
        RoutePaths.PLANS_COMPARE,
        RoutePaths.PLAN_DETAILS,
        RoutePaths.MWS_QHP_PREVIEW
    ];

    // Pages to hide the chat link
    const hideHeaderChatPages = [
        RoutePaths.HELP,
        RoutePaths.CALL_YOU_BACK,
        RoutePaths.CALL_YOU_BACK_CONFIRM,
        RoutePaths.CALL_YOU_BACK_ERROR,
        RoutePaths.MWS_QHP_PREVIEW
    ];

    let hideChatLinkPrebillCompare = false;
    if ([RoutePaths.PLANS_COMPARE].includes(pathname) && planComparePreBillData) {
        hideChatLinkPrebillCompare = true;
    }

    let isHealthPlanPage = false;

    if (planRatesObj?.QHPProduct?.productName == "QHP") {
        isHealthPlanPage = true;
    }

    let lang = (i18n.language === "es") ? 'es' : 'eng';

    const [callYouBackModal, setCallYouBackModal] = useState(false);
    const [saveProgressModal, setSaveProgressModal] = useState(false);

    let displayMegApplicantModal = false;
    if ([RoutePaths.CART].includes(pathname) && enrGroupsData !== undefined && enrGroupsData.length > 0 && onExCartData !== undefined && onExCartData.length > 0) {
        displayMegApplicantModal = true;
    }

    let progressState = searchParams.get('state');

    useEffect(() => {
        // US673343 navigate to memberprebillurl one time
        // console.log('useEffect authData.isMember:', authData.isMember);
        // console.log('useEffect authData.displayedMOT:', authData.displayedMOT);
        // console.log('useEffect isOnExOep:', isOnExOep);
        // console.log('useEffect isOffExOep:', isOffExOep);
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        async function redirectToMemberPrebillUrl() {
            for (let i = 0; i < 2; i++) {
                console.log(`Waiting to redirect in ${i} seconds...`);
                await sleep(i * 1000);
            }
            console.log("Navigate user to memberAccountPrebillUrl, displayedMOT:", authData.displayedMOT);
            window.location.href = memberAccountPrebillUrl + '&locale=' + i18n.language;
        }
        if ((isOnExOep || isOffExOep) && authData.isMember && !authData.displayedMOT) {
            dispatch(saveMOTData(true));
            redirectToMemberPrebillUrl();
        }
    }, []);

    useEffect(() => {
        // Don't allow member to continue MOT flow if logged in member is not in OEP period
        if (isMOT && !isOffExOep) {
            console.log("Reset MOT Flag")
            dispatch(resetMOTFlag());
        }
    }, []);

    useEffect(() => {
        changeLanguage(searchParams.get('language'));
    }, []);

    useEffect(() => {
        if (cwsPathAccessUrl.indexOf(window.location.pathname) > -1 && mockInitData.GWCAuth) {
            document.getElementById("authform").submit();
        }
    }, []);

    useEffect(() => {
        // Add FiveNive URL
        fiveNiveChatLoadScript(fnScriptUrl);
    }, []);

    // Handle Save Progess Return
    useEffect(() => {
        if (progressState && !isEmpty(progressState)) {
            try {
                //console.log("progressState: ", progressState);
                let decodedProgressState = Buffer.from(progressState, "base64");
                //console.log("decodedProgressState: ", decodedProgressState);
                let jsonObject = parseJson(decodedProgressState);
                if (jsonObject) {
                    let peopleReducerData = jsonObject?.PeopleReducer || {};
                    let providerReducerData = jsonObject?.ProviderSearchReducer || {};
                    let progressPath = getValidResumeProgressPath(peopleReducerData?.progressPath);
                    let progressProduct = peopleReducerData?.progressProduct || "";
                    changeLanguage((peopleReducerData?.progressLanguage == Constants.ESP) ? 'es' : 'en');
                    dispatch(resetPlanRatesReducer());
                    dispatch(resetViewBenefitsReducer());
                    // Populate People Reducer
                    dispatch(savePeopleReducerData(peopleReducerData));
                    // Populate Provider Search Reducer
                    if (!isEmpty(providerReducerData)) {
                        dispatch(saveProviderSearchReducerData(providerReducerData));
                    }
                    dispatch(updateProgressInfo({ progressResume: true }));
                    navigate(progressPath, { state: { progressResume: true, progressProduct: progressProduct } });
                } else {
                    navigate(RoutePaths.HOME);
                }
            } catch (exception) {
                console.log('Progress Received Data Error Exception:', exception.stack);
                navigate(RoutePaths.HOME);
            }
        }
    }, [progressState]);

    useEffect(() => {
        // Expose some global vars for Dynatrace session integration
        exposeGlobalVars();
    }, []);

    useEffect(() => {
        updateHeadBasedOnPathToRoutes(location);
        watchdogRouteLogger(
            location,
            zipcode,
            authData?.USER_ACCT_NUMBER ?? "",
            authData?.CIPID ?? "",
            onExReducerData?.enrollmentResponse?.ffmAppId ?? "",
            onExReducerData?.enrollmentResponse?.sseAppId ?? ""
        );
    }, [location.pathname]);

    useEffect(() => {
        if (isMOT && [RoutePaths.PLANS, RoutePaths.PLANS_ON, RoutePaths.MEMBER_PROFILE].includes(pathname)) {
            setHomePagePath(pathname)
        } else {
            setHomePagePath(RoutePaths.HOME)
        }

        if (!isAcc && !isAqt) {
            const timer = setTimeout(() => {
                fiveNineChat();
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [lang, pathname, isHealthPlanPage]);

    const exposeGlobalVars = () => {
        const script = document.createElement("script");
        script.innerHTML = `
        var userId = '${authData?.USER_ACCT_NUMBER ?? ""}';
        var cipId = '${authData?.CIPID ?? ""}';
        `
        document.body.appendChild(script);
    };

    const fiveNiveChatLoadScript = (url) => {
        if (chatEnabled) {
            let head = document.getElementsByTagName('head')[0];
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = url;
            head.appendChild(script);
        }
    }

    const fiveNineChat = async () => {
        if (chatEnabled) {
            const elChatWidget = document.getElementById('five9LiveChatWidget');

            let configId = (lang === 'es') ? fnEsConfigId : fnEngConfigId;
            let nudgeId = "";
            if (proActiveChatPages.includes(pathname)) {
                if (pathname == RoutePaths.PLANS || pathname == RoutePaths.PLANS_COMPARE || pathname == RoutePaths.PLAN_DETAILS) {
                    if (isHealthPlanPage) {
                        nudgeId = (lang === 'es') ? fnEsNudgeId : fnEngNudgeId;
                    }
                } else {
                    nudgeId = (lang === 'es') ? fnEsNudgeId : fnEngNudgeId;
                }
            }

            let pageTitle = (pathToTitleMapping[pathname]) ? pathToTitleMapping[pathname] : "Other Page"
            let priorityCode = "not set";

            let fiveNineCode = `
                function callback(event) {
                    try {
                    switch (event.type) {
                        case 'businesshours':
                            let businessHours = event.businesshours.info;
                            //console.log("Business Hours:", businessHours);
                            let chatButton = document.getElementById('five9-chat-button');
                            let callyoubackButton = document.getElementById('five9-call-you-back');
                            if(businessHours.agentsLoggedIn) {
                                if (chatButton) {
                                chatButton.classList.remove('hide');
                                }
                                if (callyoubackButton) {
                                callyoubackButton.classList.add('hide');
                                }
                            } else {
                                if (callyoubackButton) {
                                callyoubackButton.classList.remove('hide');
                                }
                                if (chatButton) {
                                chatButton.classList.add('hide');
                                }
                            }
                        break;
                        default:
                            //console.log("default")
                        break;
                    }
                    } catch (exception) {
                        console.error('Exception caught in event handler: ' + exception.stack);
                    }
                }
                var fiveNineOptions = { 
                    "appId":"${fnAppId}",
                    "configId":"${configId}",
                    "nudgeId": "${nudgeId}",
                    "mobileNudgeId": "",
                    "headless": true,
                    "debug": false,
                    "attributes": [
                        {
                        "Chat.Consumer_Channel": "${consumerChannel}"
                        },
                        {
                        "Chat.Visitor_Id": "${Env.USER_TOKEN}"
                        },
                        {
                        "Chat.profile_id": "${Env.USER_TOKEN}"
                        },
                        {
                        "Chat.Key_Code": "${campaignKeycode}"
                        },
                        {
                        "Chat.Priority_Code": "${priorityCode}"
                        },
                        {
                        "Chat.Page_Name": "${pageTitle}"
                        }
                    ],
                };
                fiveNineOptions.callback = callback;
                Five9ChatPlugin(fiveNineOptions);
            `;
            if (elChatWidget) {
                // already initialized, use Five9ChatSetOption
                await Five9ChatSetConfigId(configId);
                Five9ChatSetOption("headless", true);
                Five9ChatSetOption("nudgeId", nudgeId);
                Five9ChatSetOption("attributes", Five9ChatMergeAttributes(Five9ChatGetOption('attributes'), [{ "Chat.Page_Name": pageTitle }]));
                //Five9ChatSetOption("callback", callback);
            } else {
                // not initialized, push full fiveNineOptions into Five9ChatPlugin
                const scriptCode = document.createElement('script');
                scriptCode.type = 'text/javascript';
                scriptCode.async = true;
                scriptCode.id = "five9";
                scriptCode.innerHTML = fiveNineCode;
                document.body.appendChild(scriptCode);
            }
        }
    }

    const startFiveNineChat = () => {
        // Trigger Event tracking
        startChatGTM();
        dispatch(Actions.icmRecordEvent(payload));
        document.getElementById("five9OpenChatButton").click();
    };

    const toggleDialogVisibility = () => {
        // Trigger Event tracking
        dispatch(Actions.icmRecordEvent(payload));
        if (callYouBackModal === true) {
            setCallYouBackModal(false);
        } else {
            setCallYouBackModal(true);
        }
    };

    const toggleSaveProgressDialog = () => {
        if (saveProgressModal === true) {
            setSaveProgressModal(false);
        } else {
            setSaveProgressModal(true);
        }
    };

    const CallYouBackPopup = () => {
        return (
            <UIModal
                isOpen={callYouBackModal}
                onExit={toggleDialogVisibility}
                aria={{ labelledby: 'modal-title', describedby: "" }}
                ariaHideApp={false}
                className="modal-container"
                overlayClassName="callYouBackOverlay"
            >
                <button
                    aria-label="close dialog"
                    title="close dialog"
                    className="close"
                    onClick={toggleDialogVisibility}
                />
                <div className="row body">
                    <div className="small-12 columns" id="call-you-back-pupup">
                        <CallYouBack isPopup="true" setCallYouBackModal={setCallYouBackModal} />
                    </div>
                </div>
            </UIModal>
        );
    }

    const getAccAgentContent = (agentInfo) => {

        let agentContactInfo = agentInfo.contactInfo;
        let contactType = t("accCallTitle", { ns: 'Acc' });
        let contactData = agentContactInfo.phone ? formatPhoneNumber(agentContactInfo.phone) : '';

        if (agentContactInfo.preferredContactMethod == 'E') {
            contactType = t("accEmailTitle", { ns: 'Acc' });
            contactData = agentContactInfo.email ? agentContactInfo.email : '';
        }

        return (
            <span className="acc smaller-1x">
                {agentInfo.firstName ? agentInfo.firstName : ''} {agentInfo.lastName ? agentInfo.lastName : ''}&nbsp;&nbsp;|&nbsp;&nbsp;{t("accLicenseTitle", { ns: 'Acc' })} {agentInfo.licenseNum ? agentInfo.licenseNum : ''}&nbsp;&nbsp;|&nbsp;&nbsp;{t("accHelpTitle", { ns: 'Acc' })} {contactType} <strong>{contactData}</strong>
            </span>
        );
    }

    const getAqtAgentContent = (agentInfo) => {

        let agentContactInfo = agentInfo.contactInfo;
        let contactType = t("aqt.content.call-title", { ns: 'Aqt' });
        let contactData = agentContactInfo.phone ? formatPhoneNumber(agentContactInfo.phone) : '';

        if (agentContactInfo.preferredContactMethod == 'E') {
            contactType = t("aqt.content.email-title", { ns: 'Aqt' });
            contactData = agentContactInfo.email ? agentContactInfo.email : '';
        }

        return (
            <span className="aqt smaller-1x">
                {agentInfo.firstName ? agentInfo.firstName : ''} {agentInfo.lastName ? agentInfo.lastName : ''}&nbsp;&nbsp;|&nbsp;&nbsp;{t("aqt.content.license-title", { ns: 'Aqt' })} {agentInfo.licenseNum ? agentInfo.licenseNum : ''}&nbsp;&nbsp;|&nbsp;&nbsp;{t("aqt.content.help-title", { ns: 'Aqt' })} {contactType} <strong>{contactData}</strong>
            </span>
        );
    }

    const onLanguageChange = () => {
        const switchLangTo = (localStorage['i18nextLng'] === "en") ? "es" : "en";
        changeLanguage(switchLangTo);
        if (location.pathname === "/plans" || location.pathname === "/on/plans") {
            dispatch(resetPlanRatesReducer());
        }
    };

    const toggleNHApplicantModal = () => {
        if (nhApplicantModalOpen === true) {
            setNHApplicantModalOpen(false);
        } else {
            setNHApplicantModalOpen(true);
        }
    };

    const handleAction = (event, key) => {
        event.preventDefault();

        if (key === "dental") {
            if (displayMegApplicantModal) {
                setNHApplicantRedirectUrl(RoutePaths.SDENTAL);
                toggleNHApplicantModal();
            } else {
                navigate(RoutePaths.SDENTAL);
            }
        } else if (key === "health") {
            if (zipcode !== "" && applicants.length > 0) {
                dispatch(resetPlanRatesReducer());
                dispatch(resetViewBenefitsReducer());
                dispatch(setReloadPlanData(true));
                if (isOnExchange) {
                    let isNextGroupPresent = false;
                    if (enrGroupsData.length > 1) {
                        isNextGroupPresent = true;
                    }
                    let navState = {
                        productSegment: Constants.PRODUCT_SEGMENT_HEALTH,
                        selectedProduct: products.find((product) => product.productName.toUpperCase() === Constants.PRODUCT_QHP && product.productClassName.toUpperCase() === Constants.PRODUCT_CLASS_HEALTH.toUpperCase()),
                        isOnExchange: true,
                        isMultiEnrollmentGroupApplication,
                        isNextGroupPresent
                    }
                    navigate(RoutePaths.PLANS_ON, { state: navState });
                } else {
                    let navState = {
                        productSegment: Constants.PRODUCT_SEGMENT_HEALTH,
                        selectedProduct: products.find((product) => product.productName.toUpperCase() === Constants.PRODUCT_QHP && product.productClassName.toUpperCase() === Constants.PRODUCT_CLASS_HEALTH.toUpperCase()),
                        isOnExchange: false
                    }
                    navigate(RoutePaths.PLANS, { state: navState });
                }

            } else {
                dispatch(saveFromMenu(true))
                navigate(RoutePaths.GET_QUOTE, { state: { productSegment: Constants.PRODUCT_SEGMENT_HEALTH } });
            }
        } else if (key === "accident") {
            if (displayMegApplicantModal) {
                setNHApplicantRedirectUrl(RoutePaths.ACCIDENT);
                toggleNHApplicantModal();
            } else {
                navigate(RoutePaths.ACCIDENT);
            }
        } else if (key === "ci") {
            if (displayMegApplicantModal) {
                setNHApplicantRedirectUrl(RoutePaths.CRITICAL_ILLNESS);
                toggleNHApplicantModal();
            } else {
                navigate(RoutePaths.CRITICAL_ILLNESS);
            }
        } else if (key === "hi") {
            if (displayMegApplicantModal) {
                setNHApplicantRedirectUrl(RoutePaths.HOSPITAL);
                toggleNHApplicantModal();
            } else {
                navigate(RoutePaths.HOSPITAL);
            }
        }
    }

    const handleLogin = () => {
        // US673343, TODO: cleanup
        // if (isOffExOep) {
        //     navigate(RoutePaths.MEMBER_PROFILE);
        // } else {
        //     navigate(RoutePaths.OVERVIEW);
        // }
        navigate(RoutePaths.OVERVIEW);
    }

    const handleLogout = () => {
        console.log("handleLogout");
        setToLogout(true);
        const targetURL = `${cws_gwc_sso_url}${cws_gwc_sso_consumer_logout_endpoint}?TARGET=${window.location.origin}/cws/sso/logout`;
        window.location.href = targetURL;
    }

    const getHealthUrl = () => {
        let healthPlanData = ""; // Need to read plan info from redux store
        if (healthPlanData) {
            return 'health-plan-list';
        }
        else {
            return RoutePaths.GET_QUOTE;
        }
    };

    // Drop Down Mobile Nav Menu //
    const toggleDropVisibility = (a, b, c) => {
        let newState = ddStateA.update(a, b, c);
        if (newState) {
            if (ddStateA.triggerRef()) {
                ddStateA.triggerRef().current.focus();
            }
            setDdStateA(newState);
        }
    };

    const handleCart = () => {
        cartGTM();
        navigate(RoutePaths.CART)
    };

    const accessibilityResource = {
        "label": {
            "en": "Accessibility",
            "es": "Accesibilidad"
        },
        "separator": {
            "en": "Change Color Style:",
            "es": "Cambiar el Estilo de Color:"
        },
        "highContrast": {
            "en": "High Contrast",
            "es": "High Contrast"
        },
        "normalContrast": {
            "en": "Normal Style",
            "es": "Normal Style"
        },
        "lowContrast": {
            "en": "Soft Contrast",
            "es": "Soft Contrast"
        },
        "link": {
            "en": "Read more about Web Accessibility",
            "es": "Lee más acerca de Web Accessibility"
        }
    };

    const renderDesktopNav = t => {
        return (
            <Fragment>
                <UISkipLinks links={[{ "label": t("skipHeader", { ns: 'Layout' }), "selector": "body" }]} />
                <div className="sticky-top">
                    <header className="header-legacy no-border show-for-large">
                        <div className="global-nav">
                            <div className="support legacy">
                                <div className="row">
                                    <div className="medium-12 large-7 columns">
                                        <ul>
                                            <li>
                                                {isAcc ? (
                                                    getAccAgentContent(accData.response.agentInfo)
                                                ) : isAqt ? (
                                                    getAqtAgentContent(aqtData.response.agentInfo)
                                                ) : (
                                                    <span>
                                                        {t('needhelp')} <a className="button global" href="tel:1-855-714-8894"><strong>1-855-714-8894</strong></a>
                                                    </span>
                                                )
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="medium-12 large-5 padding-right-3x columns">
                                        <ul className="right">
                                            <li>
                                                <div>
                                                    <button onClick={handleCart} className="button naked icon-shopping-cart">
                                                        <span>&nbsp;{t('cart')}</span>
                                                    </button>
                                                </div>
                                            </li>
                                            <li>
                                                <UIAccessibilityMenu
                                                    className="button"
                                                    linkUrl={t('AccessibilityLink')}
                                                    translationResource={accessibilityResource}
                                                />
                                            </li>
                                            {(!isAcc || (isAcc && accData?.response?.supportedLangs.indexOf("en,es") !== -1)) &&
                                                <li>
                                                    <UILanguageSwitcher
                                                        className="button naked"
                                                        altLang={"es"}
                                                        onLanguageChange={onLanguageChange}
                                                    />
                                                </li>
                                            }
                                            {!isAcc && !isAqt &&
                                                <li>
                                                    <Link to={RoutePaths.HELP} className="button global">
                                                        {t('contactus')}
                                                    </Link>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row body padding-bottom-1x">
                                <div className="medium-3 large-3 columns">
                                    <ul className="logo">
                                        <li>
                                            {isAcc ?
                                                <span id="FloridaBlueLogo" className="icon-logo icon-fb" aria-label="Florida Blue" />
                                                :
                                                <Link to={homePagePath} className="icon">
                                                    <span id="FloridaBlueLogo" className="icon-logo icon-fb" aria-label="Florida Blue" />
                                                </Link>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className="medium-9 large-9 columns padding-top-1x padding-left-0x">
                                    <ul className="global legacy">
                                        <li>
                                            <UIMenu
                                                label={t('products')}
                                                rightIcon="icon-chevron-down vertical-middle"
                                                menuClass="popupmenu sub-nav legacy-sub-nav columns"
                                                className="large-left-3x medium-left-4x"
                                                buttonClass="button naked"
                                            >
                                                <button className="mega primary" onClick={(e) => handleAction(e, "health")}>
                                                    <span
                                                        className="icon icon-groups icon-size-8x"
                                                        aria-hidden="true"
                                                    />
                                                    <p className="head nowrap padding-top-1x">{t('health')}</p>
                                                </button>
                                                <button className="mega primary" onClick={(e) => handleAction(e, "dental")}>
                                                    <span
                                                        className="icon icon-tooth icon-size-8x"
                                                        aria-hidden="true"
                                                    />
                                                    <p className="head nowrap padding-top-1x">{t('dental')}</p>
                                                </button>
                                                <hr className="top-2x" />
                                                <div className="relative">
                                                    <div className="small-5 columns supportive">
                                                        <h4>{t('Other Types of Plans')}</h4>
                                                        <ul>
                                                            <li><Link to={RoutePaths.ACCIDENT} id="accident" className="" onClick={(e) => handleAction(e, "accident")}>{t('accident')}</Link></li>
                                                            <li><Link to={RoutePaths.CRITICAL_ILLNESS} id="ci" className="" onClick={(e) => handleAction(e, "ci")}>{t('ctiticalIllness')}</Link></li>
                                                            <li><Link to={RoutePaths.HOSPITAL} className="" id="hi" onClick={(e) => handleAction(e, "hi")}>{t('USAble Life Hospital')}</Link></li>
                                                            <li><Link to={RoutePaths.INTERNATIONAL}>{t('internationalTravel')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </UIMenu>
                                        </li>
                                        {!isAcc &&
                                            <li className="large-left-2x">
                                                <Link
                                                    to={isMOT ? RoutePaths.MEMBER_PROFILE : RoutePaths.GET_QUOTE}
                                                    state={{
                                                        zipcode: "",
                                                        navigatedFromCartPage: pathname === RoutePaths.CART,
                                                        navigatedFromPlanListPage: pathname === RoutePaths.PLANS || pathname === RoutePaths.PLANS_ON
                                                    }}
                                                >
                                                    {(quoteData?.applicants?.length == 0) ? t('getAQuote') : t('editAQuote')}
                                                </Link>
                                            </li>
                                        }
                                        <li>
                                            <UIMenu
                                                label={t('resources')}
                                                rightIcon="icon-chevron-down vertical-middle"
                                                menuClass="popupmenu sub-nav legacy-sub-nav columns"
                                                className="large-left-2x"
                                                buttonClass="button naked"
                                            >
                                                {!isAcc && !isAqt &&
                                                    <Link to={RoutePaths.HELP}>
                                                        <button className="mega primary">
                                                            <span
                                                                className="icon icon-support icon-size-8x"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="head nowrap">{t('support')}</p>
                                                        </button>
                                                    </Link>
                                                }
                                                <a href={t('findADoctorUrl')} target="_blank">
                                                    <button className="mega primary">
                                                        <span
                                                            className="icon icon-doctor icon-size-8x"
                                                            aria-hidden="true"
                                                        />
                                                        <p className="head nowrap">{t('findADoctor')}</p>
                                                    </button>
                                                </a>
                                                <a href={`${RoutePaths.HEALTH_CARE_101}?language=${localStorage['i18nextLng']}`} target="_blank">
                                                    <button className="mega primary">
                                                        <span
                                                            className="icon icon-assessments icon-size-8x"
                                                            aria-hidden="true"
                                                        />
                                                        <p className="head">{t('healthCare101')}</p>
                                                    </button>
                                                </a>
                                                <hr className="top-2x" />
                                                <div className="relative">
                                                    <div className="small-5 columns supportive">
                                                        <h4>{t('otherHelpfulLinks')}</h4>
                                                        <ul>
                                                            <li><a href={t('frequentlyAskedQuestionsLink')} target="_blank">{t('frequentlyAskedQuestions')}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </UIMenu>
                                        </li>
                                        <div className="float-right">
                                            {!isAcc && !isAqt && !isMultiEnrollmentGroupApplication && !isMOT && !isPrebill && isAllowedSaveProgressPath(pathname) &&
                                                <li className="padding-bottom-1x">
                                                    <button className="linklike naked" onClick={() => toggleSaveProgressDialog()}>
                                                        <span className="vertical-middle" aria-hidden="true" />
                                                        {t('save')}
                                                    </button>
                                                </li>
                                            }
                                            <li className="padding-bottom-1x">
                                                <a href={t('findADoctorUrl')} target="_blank" className="button naked">
                                                    <span className="icon-doctor vertical-middle" aria-hidden="true" />
                                                    {t('findADoctor')}
                                                </a>
                                            </li>                                           
                                            <li className="padding-bottom-1x login-wrapper">
                                                {(isAuthDataFetchCompleted && isAuthenticated) ?
                                                    <>
                                                        <UIMenu
                                                            label={t('MyAccount')}
                                                            leftIcon="icon-chevron-down padding-right-1x"
                                                            menuClass="popupmenu top-0x padding-top-2x"
                                                            className=""
                                                            buttonClass="primary tiny"
                                                        >
                                                            <a href={t('MyAccount:myAccountLeftNav.firstLinkUrl')} className="top-1x">{t('MyAccount:myAccountLeftNav.firstLinkTxt')}</a>
                                                            <a href={t('MyAccount:myAccountLeftNav.secondLinkUrl')} style={{ marginRight: '20px' }}>{t('MyAccount:myAccountLeftNav.secondLinkTxt')}</a>
                                                            <a href={accountProfileUrl} >{t('MyAccount:myAccountLeftNav.thirdLinkTxt')}</a>
                                                            <button className="button secondary top-1x left-8x small" onClick={() => handleLogout()} >
                                                                <span className="icon-log-out"></span><span>{t('Log out')}</span>
                                                            </button>

                                                        </UIMenu>
                                                    </>
                                                    :
                                                    <button
                                                        className="success small icon icon-log-in"
                                                        onClick={handleLogin}>
                                                        <span>{t('logIn')}</span>
                                                    </button>
                                                }
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {callbackEnabled &&
                            <CallYouBackPopup />
                        }
                        <SaveProgressPopup saveProgressModal={saveProgressModal} setSaveProgressModal={setSaveProgressModal} isParent={true}/>
                        {callbackEnabled && !isAcc && !isAqt &&
                            <ProgressCallYouBack />
                        }
                        {displayMegApplicantModal &&
                            <NonHealthApplicantModal
                                nhApplicantModalOpen={nhApplicantModalOpen}
                                toggleNHApplicantModal={toggleNHApplicantModal}
                                nhApplicantRedirectUrl={nhApplicantRedirectUrl}
                            />
                        }
                    </header>
                </div>
            </Fragment>
        );
    }

    const renderAccFindAPlan = t => {
        const accordionContentNaked = [
            {
                header: t("header-legacy:nav:findaplan"),
                body: (
                    <Fragment>
                        <a
                            className="block padding-top-1x padding-bottom-1x"
                            href="#"
                        >
                            <span className="padding-left-3x">
                                {t("header-legacy:nav:individualsfamilies")}
                            </span>
                        </a>
                        <a
                            className="block padding-top-1x padding-bottom-1x"
                            href="#"
                        >
                            <span className="padding-left-3x">
                                {t("header-legacy:nav:medicare")}
                            </span>
                        </a>
                        <a
                            className="block padding-top-1x padding-bottom-1x"
                            href="#"
                        >
                            <span className="padding-left-3x">
                                {t("header-legacy:nav:businesses")}
                            </span>
                        </a>
                    </Fragment>
                )
            }
        ];
        return (
            <Fragment>
                <UIAccordion2 name="acc1" className="naked no-border" limitToOne={true} >
                    {accordionContentNaked}
                </UIAccordion2>
            </Fragment>
        );
    };

    const renderMainNavList = t => {
        let healthUrl = getHealthUrl();
        const navList = [
            {
                name: (
                    <span>
                        <span
                            className="icon icon-home text-center block padding-bottom-1x"
                            aria-hidden="true"
                        />
                        {t("navMain")}
                    </span>
                ),
                children: (
                    <ul>
                        <li>
                            <Link to={RoutePaths.HOME}>{t("navHome")}</Link>
                        </li>
                        {!isAcc && !isAqt &&
                            <li>
                                <Link to={RoutePaths.HELP}>{t('support')}</Link>
                            </li>
                        }                       
                        <li>
                            <UILanguageSwitcher
                                className="button naked"
                                altLang={"es"}
                                onLanguageChange={onLanguageChange}
                            />
                        </li>
                    </ul>
                )
            },
            {
                name: (
                    <span>
                        <span
                            className="icon icon-user text-center block padding-bottom-1x"
                            aria-hidden="true"
                        />
                        {t("myAccount")}
                    </span>
                ),
                children: (
                    <ul>
                        <li>
                            <Link to={RoutePaths.OVERVIEW}>{t("myAccountNav.accountOverviewTitle")}</Link>
                        </li>
                        <li>
                            <Link to={RoutePaths.APPLICATIONS}>{t("myAccountNav.myApplicationsTitle")}</Link>
                        </li>
                        <li>
                            <Link to={RoutePaths.PROFILE}>{t("myAccountNav.accountProfileTitle")}</Link>
                        </li>
                    </ul>
                )
            },
            {
                name: (
                    <span>
                        <span
                            className="icon icon-groups text-center block padding-bottom-1x"
                            aria-hidden="true"
                        />
                        {t("products")}
                    </span>
                ),
                children: (
                    <ul>
                        <li><Link to={healthUrl} id="health" className="meg-ancillary-modal">{t('health')}</Link></li>
                        <li><Link to={RoutePaths.SDENTAL} id="dental" className="meg-ancillary-modal">{t('dental')}</Link></li>
                        <li><Link to={RoutePaths.ACCIDENT} id="accident" className="meg-ancillary-modal">{t('accident')}</Link></li>
                        <li><Link to={RoutePaths.CRITICAL_ILLNESS} id="ci" className="meg-ancillary-modal">{t('ctiticalIllness')}</Link></li>
                        <li><Link to={RoutePaths.HOSPITAL} className="meg-ancillary-modal" id="hi">{t('hospital')}</Link></li>
                        <li><Link to={RoutePaths.INTERNATIONAL}>{t('internationalTravel')}</Link></li>
                    </ul>
                )
            },
            {
                name: (
                    <span>
                        <span
                            className="icon icon-resources text-center block padding-bottom-1x"
                            aria-hidden="true"
                        />
                        {t("resources")}
                    </span>
                ),
                children: (
                    <ul>
                        <li><a href={t('findADoctorUrl')} target="_blank">{t('findADoctor')}</a></li>
                        <li><a href={`${RoutePaths.HEALTH_CARE_101}?language=${localStorage['i18nextLng']}`} target="_blank">{t('healthCare101')}</a></li>
                        <li><a href="#" target="_blank">{t('frequentlyAskedQuestions')}</a></li>
                    </ul>
                )
            },
            {
                name: (
                    <Link to={RoutePaths.GET_QUOTE} state={{ zipcode: "" }} onClick={() => toggleDropVisibility('toggle', 'ddMobileNav')}>
                        <span>
                            <span
                                className="icon  icon-edit text-center block padding-bottom-1x"
                                aria-hidden="true"
                            />
                            {t("getAQuote")}
                        </span>
                    </Link>
                )
            },
            {
                name: (
                    <Link to={RoutePaths.CART} onClick={() => toggleDropVisibility('toggle', 'ddMobileNav')}>
                        <span>
                            <span
                                className="icon  icon-shopping-cart text-center block padding-bottom-1x"
                                aria-hidden="true"
                            />
                            {t("cart")}
                        </span>
                    </Link>
                )
            }
        ];
        return (
            <Fragment>
                <UITabSwitcher
                    choices={navList}
                    className="tabs vertical"
                    name="tabSwitcherMobileLegacy"
                    vertical={true}
                    vertPanelsClassName="columns small-8 mobile-nav-legacy-inner"
                    manualActivation={true}
                />
            </Fragment>
        );
    };

    const renderMobileNav = t => {
        return (
            <Fragment>
                <div className="mobile-nav-legacy-wrapper hide-for-large">
                    <div className="row padding-1x">
                        <div className="columns small-3 medium-4">
                            <UIDropDownButton
                                ddId={ddIdNav}
                                ddState={ddStateA}
                                buttonAction={toggleDropVisibility}
                                className="button naked icon icon-menu-mobile"
                                aria-label="Menu"
                                type="toggle"
                                ref={bddRefNav}
                            />
                        </div>
                        <div className="columns medium-8 medium-padding-left-7x small-9 small-padding-left-3x">
                            <Link to={RoutePaths.HOME} className="icon">
                                <span className="icon-logo icon-fb" aria-label="Florida Blue" />
                            </Link>
                        </div>
                    </div>
                    <UIDropDown
                        ddId={ddIdNav}
                        ddState={ddStateA}
                        buttonAction={toggleDropVisibility}
                        className="legacy-mobile-nav drop-down-wrapper"
                        children={renderMainNavList(t)}
                    />
                </div>
            </Fragment>
        );
    }
    const renderChatFloatingIcon = t => {
        return (
            <Fragment>
                <>
                    <style>
                        {
                            `
                                .five9ChatButton {
                                    background-color: #FAA83F;   
                                    position: fixed;
                                    bottom: 20px;
                                    right: 20px;
                                    border-radius: 10px;
                                    padding: 10px;
                                    z-index: 100;                                   
                                }																	
                                .five9ChatTxt {
                                    color: #222121;  														      
                                    border-radius: 10px;                                                                                                       
                                }
                                .five9ChatButtonTxt {                                    
                                    text-decoration: none !important;                                                                   
                                }									
                          `
                        }
                    </style>
                    <div id="five9-chat-button" className="hide five9ChatButton">
                        <button className="success linklike chat link-arrow five9ChatButtonTxt" onClick={() => startFiveNineChat()}>
                            <span className="caution vertical-middle larger-1x five9ChatTxt"><strong>{t('Live Chat!')}</strong></span>
                            <span className="icon-chat-assistant vertical-middle larger-4x" />
                        </button>
                    </div>
                    <div id="five9-call-you-back" className="five9ChatButton">
                        <button className="success linklike chat link-arrow" onClick={() => toggleDialogVisibility()}>
                            <span>{t(' ')}</span>
                            <span className="icon-chat-assistant vertical-middle larger-4x" aria-hidden="true" />
                        </button>
                    </div>
                </>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {renderDesktopNav(t)}
            {renderMobileNav(t)}
            {chatEnabled && !isAcc && !isAqt && !isMOT && !(hideHeaderChatPages.includes(pathname)) && !hideChatLinkPrebillCompare &&
                <div className="padding-bottom-1x">
                    { renderChatFloatingIcon(t) }
                </div>
            }
        </Fragment>
    );
}

export default HeaderLegacy;