import { useSelector } from 'react-redux';
import * as Env from "../constants/Environment";



export const icmRecordEvent = (eventTitle) => {
//member flow Data 
const keycode = useSelector((state) => state.PeopleReducer.keycode || "8339");
const isAuthenticatedFristName = useSelector((state) => state.AuthReducer?.FIRST_NAME || '');
const isAuthenticatedLastName = useSelector((state) => state.AuthReducer?.LAST_NAME || '');
const applicants = useSelector((state) => state.PeopleReducer.applicants);
const isAuthenticateddateOfBirth = applicants ? applicants[0]?.DOB : '';
const isAuthenticatedgender = applicants ? applicants[0]?.gender : "";
const language = localStorage['i18nextLng'] === "en" ? "EN" : "ES";
const zipCode = useSelector((state) => state.PeopleReducer?.zipcode || "");
const county = useSelector((state) => state.PeopleReducer?.county || "");

//Retrieve data from Acc Flow Data
const receive = useSelector((state) => state.MwsQhpReducer.receive || {});
const ACCFristName = receive?.memberContract?.firstName || '';
const AccLastName = receive?.memberContract?.lastName || '';
const AccDOB = receive?.memberContract?.dob || "";
const Accgender = receive?.memberContract?.gender || '';

const isAcc = useSelector((state) => state.AccReducer?.isAcc);
const firstName = isAcc ? ACCFristName : isAuthenticatedFristName;
const lastName = isAcc ? AccLastName : isAuthenticatedLastName;
const dateOfBirth = isAcc ? AccDOB : isAuthenticateddateOfBirth;
const gender = isAcc ? Accgender : isAuthenticatedgender;

//Retrieve the products
const HealthProduct = useSelector((state) => state.PlanRatesReducer?.QHPProduct || {});
const DentalProduct = useSelector((state)=> state.PlanRatesReducer?.DentalProducts || {});
const usaAbleProductName = useSelector((state) => state.PlanRatesReducer?.USAbleProduct?.productClassName || "");

//determine the PlanType depending on the product
var planType = '';
if(HealthProduct && HealthProduct.length > 0 )  planType = "Health"
else if (DentalProduct.length > 0)  planType="Dental"
else if (usaAbleProductName === "USAble Life Accident")  planType="Accident"
else if (usaAbleProductName === "USAble Life Critical Illness")  planType="Critical Illness"
else if (usaAbleProductName === "USAble Life Hospital")  planType="Hospital"
else planType=""

// construct the audoTimestamp in this format "11:34:33 18 Apr 2023"
const months = ["Jan" , "Feb" , "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
const date = new Date();
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();
const year = date.getFullYear();
const month = months[date.getMonth()];
const TodayDate = date.getDate();

    let payload = {
        "tenantId": "GWCBCBSFL",
        "profileId": Env.USER_TOKEN,
        "priorityCode": null,
        "keycode": keycode,
        "source": "CWS",
        "demographic": {
          "firstName": firstName,
          "lastName": lastName,
          "prefix": "",
          "suffix": "",
          "dob": dateOfBirth,
          "gender": gender,
          "languageSpoken": language, 
          "languageWritten": language
        },
        "contact": {
          "eMailAddress": "",
          "addressLine1": "",
          "addressLine2": "",
          "city": "",
          "state": "",
          "zipCode": zipCode,
          "zipExt": "",
          "countyName": county,
          "primaryPhoneNumber": "",
          "secondaryPhoneNumber": ""
        },
        "Eventdetails": {
          "eventName": eventTitle,
          "agencyCd": "",
          "segment": "U65",
          "appId": "",
          "planType": planType,
          "planId": "",
          "EventTimestamp": new Date().toLocaleString()
        },
        "audLoadTimestamp": `${hours}:${minutes}:${seconds} ${TodayDate} ${month} ${year}`
      } 
     return payload;

}