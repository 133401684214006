export const SAVE_ONEX_CART_ITEMS = "SAVE_ONEX_CART_ITEMS";
export const RESET_ONEX_CART_ITEMS = "RESET_ONEX_CART_ITEMS";

export const saveOnExCartItems = (data) => ({
    type: SAVE_ONEX_CART_ITEMS,
    data
})

export const resetOnExCartItems = () => ({
    type: RESET_ONEX_CART_ITEMS,
})

