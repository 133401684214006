import * as Env from '../constants/Environment';
import { useAxiosHandler } from "hooks/useAxiosHandler";
import memberDataMock from '../../data/memberData.json';

const axios = useAxiosHandler();

export const SAVE_MEMBER_SET_DATA = 'SAVE_MEMBER_SET_DATA';
export const RESET_MEMBER_SET_DATA = 'RESET_MEMBER_SET_DATA';

export const GET_MEMBER_DATA_INIT = 'GET_MEMBER_DATA_INIT';
export const GET_MEMBER_DATA_SUCCESS = 'GET_MEMBER_DATA_SUCCESS';
export const GET_MEMBER_DATA_FAILURE = 'GET_MEMBER_DATA_FAILURE';
export const RESET_MEMBER_DATA = "RESET_MEMBER_DATA";

const getMemberDataFailure = (error) => {
  return {
      type: GET_MEMBER_DATA_FAILURE,
      error
  }
}

const getMemberDataSuccess = (data) => {
  return {
      type: GET_MEMBER_DATA_SUCCESS,
      data
  }
}

export const getMemberData = (serviceUrl, cipId = "") => {
  return async dispatch => {
    try {

      dispatch({ type: GET_MEMBER_DATA_INIT });

      const url = `${serviceUrl}/${cipId}`;

      let response = {};

      if (Env.ISPRODUCTION) {
        let apiResp = await axios.get(url, { withCredentials: true });
        response = apiResp.data?.contract;
      } else {
        response = memberDataMock.contract;
      }

      dispatch(getMemberDataSuccess(response));

    } catch (err) {
      console.log("Error in getMemberData:", err);
      dispatch(getMemberDataFailure(err));
    }
  }
}

export const saveMemberSetData = (data) => {
  return {
    type: SAVE_MEMBER_SET_DATA,
    data,
  }
}

export const resetMemberSetData = () => ({
  type: RESET_MEMBER_SET_DATA,
})

