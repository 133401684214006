import React, { Suspense } from "react";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import UIInitialization from "UI/UIInitialization";
import UIScrollToTop from "UI/UIScrollToTop";

import * as Env from 'constants/Environment';
import { store, persistor } from "./store";
import AppRouter from './router'
import './i18n';
import { ContextProvider } from "providers/ContextProvider";
import SessionTimer from "./components/Session/SessionTimer";

const App = () => {

  console.log("@@@ Env.ISPRODUCTION:", Env.ISPRODUCTION);
  console.log("@@@ process.env.NODE_ENV:", process.env.NODE_ENV);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback="Loading...">
            <Router basename={`/`}>
              <UIInitialization>
                <ContextProvider>
                  <div className="App">
                    <SessionTimer />
                    <UIScrollToTop />
                    <AppRouter />
                  </div>
                </ContextProvider>
              </UIInitialization>
            </Router>
          </Suspense>
      </PersistGate>
    </Provider>
  );

};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();