import { RESET_AQT_DATA, SAVE_AQT_DATA} from '../actions/AqtAction';

const initialState = {
    response: {},
    isAqt: false,
}

const AqtReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_AQT_DATA:
            return {
                ...state,
                response: action.payload,
                isAqt: action.payload ? true : false
            }

        case RESET_AQT_DATA:
            return initialState;

        default:
            return state;
    }
}

export default AqtReducer;