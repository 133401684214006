import React, {useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { encode as base64_encode } from 'base-64';
import { saveRedirectToLogin } from "actions/AuthAction";
import { useSearchParams } from "react-router-dom";

const GWCAuthForm = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    
    let cws_gwc_sso_url = useSelector((state) => state.InitDataReducer.initData.authentication?.sso_url);
    let authn_resource = useSelector((state) => state.InitDataReducer.initData.authentication?.authn_resource);
    let sso_auth_type = useSelector((state) => state.InitDataReducer.initData.authentication?.auth_type);
    let sso_member_auth_type = useSelector((state) => state.InitDataReducer.initData.authentication?.member_auth_type);
    let cws_gwc_sso_consumer_endpoint = useSelector((state) => state.InitDataReducer.initData.authentication?.consumer_endpoint);
    let cws_gwc_sso_tenantId = useSelector((state) => state.InitDataReducer.initData.authentication?.sso_tenantId);
    let cws_gwc_sso_client = useSelector((state) => state.InitDataReducer.initData.authentication?.sso_client);
    const redirectToMemberAuth = useSelector((state) => state.AuthReducer.redirectToMemberAuth) || false;
    let redirectToLoginPage = useSelector((state) => state.AuthReducer.redirectToLoginPage) || false;
    let targetURL = `${authn_resource}?TARGET1=${window.location.pathname}`;

    let today = new Date();
    let sso_req_id = 'cws-gwc-sso' + today.getTime();
    let reqLang = searchParams.get('language') || false;
    let lang = "ENG";
    if (reqLang) {
        lang = (reqLang === "es") ? "ESP" : "ENG";
    } else {
        lang = (localStorage['i18nextLng'] === "es") ? "ESP" : "ENG";
    }
    let pageInfo = `{'languageType':'${lang}','context': 'REGISTER'}`;
    let cwsPageInfo = base64_encode(pageInfo);
    
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef(null);

    // regular hook
    useEffect(() => {
        if (redirectToLoginPage) {
            dispatch(saveRedirectToLogin(false));
            history.back();
        } else {
            dispatch(saveRedirectToLogin(true));
            formRef.current.submit();
        }
    }, []);

    // google react + bfcache as to why this is needed
    useEffect(() => {
        window.onpageshow = function(event) {
          if (event.persisted) {
            if (!redirectToLoginPage) {
                dispatch(saveRedirectToLogin(false));
                history.back();
            } else {
                dispatch(saveRedirectToLogin(true));
                formRef.current.submit();
            }
          }
        };
    }, []);

    return (
        <div className="hide">
            <form ref={formRef} action={cws_gwc_sso_url + cws_gwc_sso_consumer_endpoint} method="POST" id="authform">
                <input type="hidden" name="authn_tenantId" value={cws_gwc_sso_tenantId} />
                <input type="hidden" name="authn_resource" value={targetURL} />
                <input type="hidden" name="authn_client" value={cws_gwc_sso_client} />
                <input type="hidden" name="authn_authType" value={redirectToMemberAuth ? sso_member_auth_type : sso_auth_type} />
                <input type="hidden" name="authn_requestId" value={sso_req_id} />
                <input type="hidden" name="pageInfo" value={cwsPageInfo} />
                <input type="hidden" name="_ga" value="2.232736024.640489460.1657701501-1042736343.1655974581" />
                <input type="submit" value="Submit" />
            </form>
        </div>
    )
}

export default GWCAuthForm;
