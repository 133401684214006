import { Markup } from 'interweave';
import React from 'react';
import { useTranslation } from "react-i18next";

function UnSubscribed() {
    const { t, i18n } = useTranslation(['Layout','FormNumbers']);

    const FormNumber = () => {
        return (
            <div id="formnumber-section">
                <div className="row">
                    <div className="columns small-12">
                        <p className="form-number">
                            {t('FormNumbers:unSubscribeFormNumber')}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <section className='banner hero bg-parent-muted bottom-1x'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <div className='content top-1x'>
                            <h3 className='bottom-0x'>
                                <Markup content={t('Layout:unSubscribeContent')} />
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
            <FormNumber />
        </>
    )
}

export default UnSubscribed