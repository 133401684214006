export const SAVE_PLANS_COMPARE_DATA = "SAVE_PLANS_COMPARE_DATA";
export const REMOVE_SELECTED_PLAN_FOR_COMPARE = "REMOVE_SELECTED_PLAN_FOR_COMPARE";
export const RESET_PLANS_COMPARE_DATA = "RESET_PLANS_COMPARE_DATA";

export const savePlansCompareData = (data) => ({
    type: SAVE_PLANS_COMPARE_DATA,
    data
})

export const removeSelectedPlan = (data) => ({
    type: REMOVE_SELECTED_PLAN_FOR_COMPARE,
    data
})

export const resetPlansCompareData = () => ({
    type: RESET_PLANS_COMPARE_DATA
})